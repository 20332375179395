<template>
	<section class="c-faq">
		<header class="c-faq__header s-common__headerCenter">
			<h2>FAQS</h2>
		</header>
		<div class="c-faq__content">
			<div class="c-faq__accordion">
				<div class="row">
					<div
						class="c-faq__accordion--left c-faq__accordion--column"
					>
						<Accordion
							id="accordion-left"
							:content="accordion"
							:multiple="true"
						/>
					</div>
					<div
						class="c-faq__accordion--right c-faq__accordion--column"
					>
						<Accordion
							id="accordion-right"
							:content="accordion2"
							:multiple="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion';
export default {
	name: 'AmbassadorFaq',
	components: {
		Accordion,
	},
	data: function() {
		return {
			accordion: [
				{
					id: 1,
					active: false,
					title: 'What is LevelNext® L.A.N.?',
					details: `
                    <p>LevelNext® L.A.N - Local Ambassador Network - The official campus connection between LevelNext® and college esports programs</p>`,
				},
				{
					id: 2,
					active: false,
					title: 'What does a LevelNext® Ambassador do?',
					details: `
                    <p>Ambassadors will serve as the direct connection between LevelNext® and their university as the liaison of information, promotion of events, and connection to campus life. Specific examples include:
						<ul>
							<li>Recruit participants for LevelNext® events</li>
							<li>Distribute LevelNext® merchandise while promoting LevelNext®</li>
							<li>Join calls with LevelNext® representatives</li>
							<li>Provide insights into the college esports community</li>
							<li>Act as the liaison of the esports program to LevelNext®</li>
							<li>Participate in LevelNext®’s Discord and promote LevelNext® to students wherever possible</li>
							<li>Be active in engaging with and sharing LevelNext® social media posts</li>
						</ul>
					</p>`,
				},
			],
			accordion2: [
				{
					id: 1,
					active: false,
					title: 'Who is eligible to apply?',
					details: `
                    <p>All current full-time students enrolled at 4 year institutions with a 2.00 GPA or higher and are members of their official campus esports programs are eligible.</p>`,
				},
				{
					id: 2,
					active: false,
					title:
						'How long is a student a part of the LevelNext® L.A.N.?',
					details: `
                    <p>Ambassadors are members of LevelNext® L.A.N. throughout the 2021/2022 school year, ending June 30, 2022, at which point a new application must be submitted to re-apply.</p>`,
				},
			],
			is_active: false,
		};
	},
};
</script>

<style lang="scss">
.c-faq {
	background: #000;
	padding: 120px 0 175px 0;
	border-top: 1px solid #222120;
	&__accordion {
		&--column {
			flex: 1 1 50%;
		}
		&--left {
			padding: 0 24px 0 30px;
		}
		&--right {
			padding: 0 30px 0 24px;
		}
		@media screen and (max-width: 1024px) {
			margin-top: 24px;
			&--column {
				flex: 1 1 100%;
			}
			&--left,
			&--right {
				padding: 0 24px;
			}
		}
	}
	.c-accordion {
		padding: 0;
		margin: 78px 0 0 0;
		@media screen and (max-width: 1024px) {
			margin: 0;
		}
		dd {
			margin-left: 0;
		}
		&__item {
			margin-bottom: 24px;
			&.is-active {
				.c-accordion__item-trigger-icon {
					transform: rotate(45deg);
					transition: 0.25s;
				}
			}
			&-enter,
			&-leave-to {
				height: 0 !important;
			}
			&.is-active {
				.c-accordion__item-title-text {
					color: #cc0000;
				}
			}
			&-enter-active,
			&-leave-active {
				will-change: height;
				transition: height 0.2s ease;
			}
			&-details {
				overflow: hidden;
				color: #fff;
				&-inner {
					padding: 24px;
					border-bottom: 1px solid #222120;
					position: relative;
					&:before {
						content: '';
						display: block;
						height: 20px;
						width: 20px;
						z-index: 2;
						background: linear-gradient(
							135deg,
							#000 49%,
							#222120 50%,
							#000 51%
						);
						position: absolute;
						right: 0;
						bottom: -1px;
					}
					&:after {
						content: '';
						display: block;
						height: 100%;
						width: 20px;
						background: #000;
						position: absolute;
						right: 0;
						bottom: -1px;
					}
				}
			}
			&-trigger {
				padding: 12px 0;
				cursor: pointer;
				width: 100%;
				position: relative;
				text-align: left;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #222120;
				padding-right: 60px;
				&:before {
					content: '';
					display: block;
					height: 20px;
					width: 20px;
					z-index: 2;
					background: linear-gradient(
						135deg,
						#000 49%,
						#222120 50%,
						#000 51%
					);
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:after {
					content: '';
					display: block;
					height: 100%;
					width: 20px;
					background: #000;
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:focus {
					outline: none;
				}
				&-icon {
					position: absolute;
					right: 18px;
					top: 12px;
					color: #fff;
					font-size: 24px;
					transform: rotate(0deg);
					transition: 0.25s;
				}
			}
			&-title {
				position: relative;
				&-trigger,
				&-details-inner {
					padding: 0.75rem 1.25rem;
				}
				h4 {
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					margin: 0;
					color: #fff;
					@include nova(700);
				}
			}
		}
		@media screen and (max-width: 540px) {
			&__item-details-inner {
				padding: 24px 24px 24px 0;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 48px 0;
	}
}
</style>
