<template>
	<div class="c-tournament-schedule bp-768:padding-b-120">
		<h2
			class="font-size-24 font-weight-700 padding-b-24 margin-b-12 border-b margin-t-24 display-none bp-1024:display-block"
		>
			Matchups
		</h2>
		<div class="c-tournament-schedule__tabs">
			<Tabs>
				<Tab
					:name="item.name"
					:selected="false"
					v-for="item in details.phases[0].groups[0].rounds"
					:key="item.id"
				>
					<div
						class="c-tournament-schedule__tab-row flex-wrap-wrap bp-768:flex-wrap-no-wrap padding-x-24 bp-540:padding-x-0 padding-y-24 font-size-18 display-flex align-items-center bp-768:padding-y-0 bp-768:justify-content-center border-y"
						v-for="matchup in item.matches"
						:key="matchup.index"
					>
						<div
							class="c-tournament-schedule__tab-row__date bp-768:margin-r-auto"
						>
							{{ parseDate(matchup.matchStartTime) }}
						</div>
						<div
							class="c-tournament-schedule__tab-row__team bp-768:padding-x-12 bp-768:text-align-right"
							v-if="matchup.games[0]"
						>
							{{
								locateTeamName(
									matchup.games[0].gameResults[0].teamId,
								)
							}}
						</div>
						<div
							class="c-tournament-schedule__tab-row__team bp-768:padding-x-12 bp-768:text-align-right"
							v-else
						>
							TBD
						</div>
						<div
							class="c-tournament-schedule__tab-row__time bp-768:padding-x-12 text-align-center"
						>
							{{ parseTime(matchup.matchStartTime) + ' EST' }}
						</div>
						<div
							class="c-tournament-schedule__tab-row__team bp-768:padding-x-12 margin-r-auto"
							v-if="matchup.games[0]"
						>
							{{
								locateTeamName(
									matchup.games[0].gameResults[1].teamId,
								)
							}}
						</div>
						<div
							class="c-tournament-schedule__tab-row__team bp-768:padding-x-12 margin-r-auto"
							v-else
						>
							TBD
						</div>
					</div>
				</Tab>
			</Tabs>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Tabs from '@/components/Tabs/ScheduleTabs.vue';
import Tab from '@/components/Tabs/ScheduleTab.vue';
export default {
	name: 'TournamentSchedule',
	components: {
		Tabs,
		Tab,
	},
	props: {
		details: {
			type: Object,
			required: true,
		},
	},
	methods: {
		parseDate(date) {
			return moment(date).format('MMM D');
		},
		parseTime(time) {
			return moment(time).format('h:mm A');
		},
		locateTeamName(ID) {
			return _.filter(this.details.phases[0].groups[0].teams, {
				teamId: ID,
			})[0].name;
		},
	},
};
</script>

<style scoped lang="scss">
.c-tournament-schedule {
	&__tabs {
		.c-tabs {
			&__item {
				a {
					font-size: 0.75rem;
				}
			}
		}
	}
	&__tab-row {
		height: 108px;
		&:not(:last-of-type) {
			border-bottom: none !important;
		}
		&__date {
			flex: 0 0 60px;
		}
		&__team {
			flex: 0 0 30%;
		}
		&__time {
			flex: 0 0 140px;
		}
	}
	@media (max-width: 1023px) {
		&__tab-row {
			&:first-of-type {
				border-top: none !important;
				margin-top: 12px;
			}
		}
	}
	@media (max-width: 767px) {
		&__tab-row {
			height: auto;
			&:last-of-type {
				border-bottom: none !important;
			}
			&__date {
				flex: 0 0 auto;
				order: 1;
				margin-bottom: 12px;
				&:after {
					content: '/';
					display: inline-block;
					margin: 0 10px 0 8px;
					color: $muted-text;
				}
			}
			&__team {
				flex: 0 0 100%;
				order: 3;
			}
			&__time {
				flex: 0 0 auto;
				order: 2;
				margin-bottom: 12px;
			}
		}
	}
}
</style>
