<template>
	<div class="c-champions-hero">
		<div class="c-champions-hero__imagecenter position-relative">
			<slot name="heroimage"></slot>
			<div
				class="c-champions-hero__conference__wrapper z-index-1 width-100 height-100  display-flex flex-direction-column align-items-flex-center justify-content-center"
			>
				<div
					class="c-champions-hero__conference c-champions-hero__confrence--center c-champions-hero__conference--center__logo  display-flex align-items-flex-center justify-content-center padding-24"
				>
					<slot name="image"></slot>
				</div>

				<div
					class="display-flex flex-direction-column align-items-center text-align-center justify-content-center padding-24"
				>
					<slot name="title"></slot>
					<slot name="teaser"></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CenterHero',
	data: () => ({}),
};
</script>

<style lang="scss">
.c-champions-hero {
	&__image {
		height: 556px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
	}
	&__imagecenter {
		height: 870px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
	}
	&__details {
		max-width: 1440px;
		h1 {
			max-width: 970px;
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
				position: absolute;
				left: 0;
				bottom: 0;
				transition: 0.25s;
			}
		}
		.register_button {
			background-color: $red;
			border-radius: 24px;
			position: relative;
			text-decoration: none;
			transition: 0.25s;
			height: 48px;
			width: 260px;
			color: $white;
			&:hover {
				background-color: darken($red, 5%);
				cursor: pointer;
			}
		}
	}
	&__logo {
		max-width: 300px;
	}
	&__conference {
		max-width: 1440px;
		margin: 0 auto;

		&--center {
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: flex-end;
			&__logo {
				position: relative;
				width: 245px;
				height: 245px;

				img {
					margin: auto;
				}
			}
		}
		&--left {
			display: flex;
			justify-content: left;
			align-content: left;
			align-items: flex-end;
			&__logo {
				position: relative;
			}
		}
		&__logo {
			width: 245px;
			height: 245px;
			left: 24px;
			bottom: 56px;
			border-color: $border-gray;
			background: $black;
			img {
				max-width: 180px;
			}
		}
	}

	@media (max-width: 1280px) {
		&__conference {
			&__logo {
				width: 120px;
				height: 120px;
				bottom: 48px;
				img {
					max-width: 60px;
				}
			}
		}

		&__conference--center {
			&__logo {
				width: 158px;
				height: 158px;
				bottom: 48px;
				img {
					max-width: 158px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		margin-top: 96px;
		&__image {
			height: 302px;
		}
	}
	@media (max-width: 540px) {
		&__details {
			.register_button {
				width: 100%;
			}
		}
		&__image {
			height: 150px;
		}
		&__logo {
		}
		&__conference {
			&__logo {
				bottom: 36px;
				width: 96px;
				height: 96px;
			}
		}

		&__conference--center {
			&__logo {
				bottom: 36px;
				width: 120px;
				height: 120px;
			}
		}
	}
}
</style>
