<template>
	<div id="tournament-info-container">
		<div id="tournament-info">
			<h1 class="font-size-48">
				Tournament Info
			</h1>
			<p class="text-muted-on-dark">
				Introducing the 3rd annual LevelNext Madden National Championship. LevelNext is giving college students the opportunity to represent their school on a national stage and compete for a piece of the $150,000 prize pool.
			</p>
			<CustomTabs />
		</div>
	</div>
</template>

<script>
import 'vue-nav-tabs/themes/vue-tabs.css';
import CustomTabs from '@/components/ChampionSeries/CustomTabs.vue';

export default {
	name: 'TournamentInfo',
	components: {
		CustomTabs,
	},
	data: function() {
		return {};
	},
};
</script>

<style scoped lang="scss">
#tournament-info-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 24px;

	#tournament-info {
		max-width: 1196px;
		padding: 120px 0px;

		h1 {
			display: flex;
			flex-direction: column;
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
			}
			@media screen and (max-width: 768px) {
				font-size: 30px;
			}
		}
		p {
			padding: 36px 0px;
			font-size: 18px;
		}
		.live-button {
			background: #cc0000;
		}
		@media screen and (max-width: 768px) {
			padding: 50px 0px;
		}
		@media screen and (max-width: 450px) {
			padding: 24px 0px;
		}
	}
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		background: linear-gradient(
			90deg,
			#000000 0%,
			#cc0000 51.56%,
			#000000 100%
		);
	}
}
</style>
