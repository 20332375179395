<template>
	<div class="tournament-swiper-container" :class="background">
		<div class="tournament-swiper">
			<Swiper ref="mySwiper" :options="swiperOption" class="swiper">
				<SwiperSlide
					v-for="(tournament, index) in tournaments"
					:key="index"
					class="display-flex flex-direction-column align-items-center"
				>
					<div class="swiper-content">
						<div class="swiper-image">
							<img
								:src="
									require(`@/assets/champion-series/${tournament.imgName}`)
								"
								:alt="tournament.imgAlt"
							/>
						</div>
						<div class="swiper-text">
							<span
								class="display-flex align-items-center"
							>
								<h4 class="swiper-header">
									{{ tournament.header }}
								</h4>
								<div
									v-if="tournaments.length > 1"
									class="pagination display-flex justify-content-space-between"
								>
									<span
										v-for="(_,
										paginationIndex) in tournaments"
										:key="paginationIndex"
										@click="slideTo(paginationIndex)"
										class="pagination-index display-flex text-muted-on-dark"
										:class="{
											paginationSelected:
												paginationIndex === index,
										}"
									>
										0{{ paginationIndex }}
									</span>
								</div>
							</span>
							<h3 class="swiper-title">{{ tournament.title }}</h3>
							<div class="swiper-small-stuff">
								<div class="swiper-misc-container display-flex">
									<span
										v-for="(miscItem,
										index) in tournament.misc"
										:key="index"
										class="swiper-misc"
									>
										{{ miscItem }}
									</span>
								</div>
								<a
									:href="tournament.buttonHref"
									color="secondary"
									:class="{
										greenButton:
											tournament.buttonText ===
											'Register Now',
									}"
									class="swiper-button reset mb-2 sa-block-parallax__link display-flex align-items-center text-align-center font-size-14 font-weight-700"
								>
									{{ tournament.buttonText }}
								</a>
							</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

export default {
	name: 'TournamentSwiper',
	components: {
		Swiper,
		SwiperSlide,
	},
	props: {
		tournaments: {
			type: Array,
			required: true,
		},
		useBackground: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			swiperOption: {},
		};
	},
	methods: {
		slideTo(slideIndex) {
			this.swiper.slideTo(slideIndex, 500, false);
		},
	},
	computed: {
		background() {
			return this.useBackground ? 'tournament-swiper-background' : '';
		},
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
};
</script>

<style scoped lang="scss">
.tournament-swiper-background {
	background-image: url('../../assets/champion-series/bg_1920x1080.jpeg');
	background-size: cover;
	background-color: #111;
}
.tournament-swiper-container {
	background-color: black;

	.tournament-swiper {
		padding: 110px 0px;
		display: flex;
		align-items: center;
		.swiper-content {
			display: flex;
			flex-direction: row;
			max-width: 1196px;
			.swiper-image {
				width: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 24px;
				img { 
					max-height: 300px;
					@media screen and (max-width: 768px) {
						max-height: 200px;
					}
				}
				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}
			.swiper-text {
				width: 50%;
				padding: 24px;
				.swiper-header {
					font-size: 25px;
					margin: 50px 0px;
					@media screen and (max-width: 768px) {
						margin: 0;
					}
					@media screen and (max-width: 450px) {
						font-size: 18px;
					}
				}
				.pagination {
					margin-left: auto;
				}
				.pagination-index {
					padding: 0px 5px 5px 5px;
					&:hover {
						cursor: pointer;
						color: white;
					}
				}
				.paginationSelected {
					color: $red;
					font-weight: bolder;
				}
				.swiper-title {
					font-size: 39px;
					line-height: 125%;
					margin: 10px 0px;
					@media screen and (max-width: 450px) {
						font-size: 25px;
					}
				}
				.swiper-small-stuff {
					display: flex;
					flex-direction: row;
					align-items: center;
					.swiper-misc {
						font-size: 16px;
						display: flex;
						align-items: center;
						text-align: center;
						&:after {
							margin: 0px 12px;
							content: '';
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: #cc0000;
							display: inline-block;
						}
					}
					.swiper-button {
						padding: 0px 12px;
					}
					@media screen and (max-width: 450px) {
						flex-direction: column;
						.swiper-misc-container {
							width: 100%;
							flex-wrap: wrap;
						}
						.swiper-button {
							margin-top: 24px;
							padding: 0px 30%;
						}
					}
				}
				@media screen and (max-width: 768px) {
					width: 100%;
				}
			}
			@media screen and (max-width: 768px) {
				flex-direction: column;
				align-items: center;
			}
		}
		@media screen and (max-width: 768px) {
			padding: 24px 0px;
		}
	}
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		background: linear-gradient(
			90deg,
			#000000 0%,
			#cc0000 51.56%,
			#000000 100%
		);
	}
}

// Button style
.sa-block-parallax {
	height: 100vh;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.75)
		);
	}
	&__row {
		&:after {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}
	&__wrapper {
		max-width: 1512px;
	}
	&__details {
	}
	&__text {
		z-index: 2;
		&-upper {
			font-weight: 900;
			max-width: 700px;
		}
		&-lower {
			font-weight: 400;
		}
	}
	&__link {
		background-color: $red;
		border-radius: 192px;
		position: relative;
		height: 36px;
		border-radius: 18px;
		text-decoration: none;
		transition: 0.25s;
		// &::before {
		// 	content: '';
		// 	height: 4px;
		// 	width: 4px;
		// 	background-color: $red;
		// 	position: absolute;
		// 	left: -12px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }
		&:hover {
			background-color: #a30000;
		}
	}
	@media (max-width: 1023px) {
		height: auto;
		padding-bottom: 56.25%;
		margin-top: 96px;
		&__row {
			&:after {
				height: 2px;
			}
		}
	}
	@media (max-width: 539px) {
		height: calc(100vh - 96px);
		padding-bottom: 0;
		&__logo {
			height: 65% !important;
		}
		&__wrapper {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 30%;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 1)
				);
			}
		}
		&__link {
			&:before {
				display: none;
			}
		}
	}
}
.greenButton {
	background-color: $green;
	&:hover {
		background: darken($green, 8%);
	}
}

.swiper {
	width: 100%;
}
</style>
