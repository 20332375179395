<template>
	<div>
		<RegisterPitch />
		<TournamentInfo />
		<TournamentSwiper
			:tournaments="this.tournaments.upcoming"
			:useBackground="true"
		/>
		<TournamentSwiper
			:tournaments="this.tournaments.past"
			:useBackground="false"
		/>
	</div>
</template>

<script>
import RegisterPitch from '@/components/ChampionSeries/RegisterPitch.vue';
import TournamentInfo from '@/components/ChampionSeries/TournamentInfo.vue';
import TournamentSwiper from '@/components/ChampionSeries/TournamentSwiper.vue';

export default {
	name: 'ChampionSeries',
	components: {
		RegisterPitch,
		TournamentInfo,
		TournamentSwiper,
	},
	data: function() {
		return {
			tournaments: {
				upcoming: [
					{
						imgName: 'madden nat champ white.png',
						imgAlt: 'LevelNext Madden National Championship Logo',
						header: 'Upcoming Tournaments',
						title: 'LevelNext Madden National Championship',
						misc: [
							'$150,000 Prize Pool',
							'Open to all College Students',
						],
						buttonText: 'Tune In',
						buttonHref: 'https://www.twitch.tv/levelnextgg',
					},
				],
				past: [
					{
						imgName: '2022-LevelNext-FIFA-22-College-National-Championship.png',
						imgAlt:
							'Image for 2022 LevelNext FIFA 22 College National Championship - Champions',
						header: 'Past Tournaments',
						title: '2022 LevelNext FIFA 22 College National Championship',
						misc: ['$100,000 Prize Pool'],
						buttonText: 'Read the Recap',
						buttonHref:
							'/recap/fifa2022',
					},
					{
						imgName: '2022-LevelNext-Rocket-League-Spring-Showcase.png',
						imgAlt:
							'Image for LevelNext Rocket League Spring Showcase 2022 - Champions',
						header: 'Past Tournaments',
						title: '2022 LevelNext Rocket League Spring Showcase',
						misc: ['$125,000 Prize Pool'],
						buttonText: 'Read the Recap',
						buttonHref:
							'/recap/rocketleague2022',
					},
					{
						imgName: 'tournament-2021.png',
						imgAlt:
							'Image for LevelNext 2021 Madden National Championship Recap - NoahUpNxt',
						header: 'Past Tournaments',
						title: '2021 Madden National Championship Recap',
						misc: ['$150,000 Prize Pool'],
						buttonText: 'Read the Recap',
						buttonHref:
							'/recap/madden2021',
					},
					{
						imgName: 'tournament-2021-rocket-league.jpg',
						imgAlt:
							'Image for LevelNext Rocket League Spring Showcase 2021 - Champions',
						header: 'Past Tournaments',
						title: '2021 Rocket League Spring Showcase Recap',
						misc: ['$125,000 Prize Pool'],
						buttonText: 'Read the Recap',
						buttonHref:
							'/recap/rocketleague2021',
					},
					{
						imgName: 'tournament-2020.jpg',
						imgAlt:
							'Image for LevelNext 2020 Madden National Championship Recap - JWall',
						header: 'Past Tournaments',
						title: '2020 Madden National Championship Recap',
						misc: ['$150,000 Prize Pool'],
						buttonText: 'Read the Recap',
						buttonHref:
							'/recap/madden2020',
					},
				],
			},
		};
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.sa-block-parallax {
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.75)
		);
	}
	&__row {
		position: relative;
		background-attachment: fixed;
		background-size: cover;
		background-position: center;
		height: 558px;
		&:after {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
		}
	}
	&__details {
		a {
			height: 36px;
		}
	}
	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
		&-upper {
			img {
				width: 594px;
				padding: 0 24px;
			}
		}
		&-lower {
			font-weight: 400;
			font-size: 21px;
			line-height: 32px;
			text-align: center;
		}
	}
	&__link {
		background-color: $red;
		border-radius: 192px;
		position: relative;
		text-decoration: none;
		transition: 0.25s;
		&::before {
			height: 4px;
			width: 4px;
			background-color: $red;
			position: absolute;
			left: -12px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			background-color: #a30000;
		}
	}
	@media screen and (max-width: 1023px) {
		&__row {
			background-attachment: scroll;
		}
	}
	@media screen and (max-width: 767px) {
		&__text-upper {
			font-size: 64px;
		}
	}
	@media screen and (max-width: 540px) {
		&__text-upper {
			font-size: 46px;
			text-align: center;
		}
	}
}
</style>
