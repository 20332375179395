<template>
	<section class="c-faq">
		<header class="c-faq__header s-common__headerCenter">
			<h2>FAQS</h2>
		</header>
		<div class="c-faq__content">
			<div class="c-faq__accordion">
				<div class="row">
					<div
						class="c-faq__accordion--left c-faq__accordion--column"
					>
						<Accordion
							id="accordion-left"
							:content="accordion"
							:multiple="true"
						/>
					</div>
					<div
						class="c-faq__accordion--right c-faq__accordion--column"
					>
						<Accordion
							id="accordion-right"
							:content="accordion2"
							:multiple="true"
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion';
export default {
	name: 'GeneralFaq',
	components: {
		Accordion,
	},
	data: function() {
		return {
			accordion: [
				{
					id: 1,
					active: false,
					title: 'What is LevelNext?',
					details: `
                    <p>Esports is the next frontier of sports, and college esports is an industry where there are millions of gamers, yet only a small number of them are actually participating in college esports. LevelNext is a way to welcome every college gamer and give them an opportunity to participate in college esports.</p>`,
				},
				{
					id: 2,
					active: false,
					title:
						'How is this different than other college esports organizations?',
					details: `
                    <p>Learfield is the largest college multimedia rights holder in the United States and is leveraging its official marketing and IP rights with our university partners to enhance and amplify the college esports community. While other college esports competitions focus solely on club or varsity esports programs, LevelNext is open to the everyday gamer…anyone who is a current student and wants to compete!</p>`,
				},
				{
					id: 3,
					active: false,
					title:
						'Our school already has an esports team, can they get involved?',
					details: `
                    <p>There are many ways for current esports programs to get involved. Here’s how: </p>
                    <ul>
                        <li>Play – The most basic way to get involved is to sign up for Champions Series and Challenger Series tournaments.</li>
                        <li>Collaborate – If your school is represented by Learfield, we may already be working with you on events and if not, we can! Find out if you are a member school here: https://www.learfield.com/what-we-do/partners/ </li>
                        <li>Communicate – We want to support you esports programs as much as possible, so if there are any events or opportunities you’d like to host with LevelNext let us know, If there are any event’s you’d like ClickStart U to cover, let us know!</li>
                    </ul>`,
				},
			],
			accordion2: [
				{
					id: 1,
					active: false,
					title: 'Is there a cost to participate?',
					details: `
                    <p>No. There is no cost to participate in either Champions Series or Challengers Series events.</p>`,
				},
				{
					id: 2,
					active: false,
					title: 'Who is Learfield?',
					details: `
                    <p>Learfield is the leading media and technology services company in intercollegiate athletics. The company unlocks the value of college sports for brands and fans through an omnichannel platform with innovative content and commerce solutions for fan engagement. The Learfield suite of services includes licensing and multimedia sponsorship management; publishing, audio, digital and social media; data analytics; ticketing, ticket sales and professional concessions expertise; branding; campus-wide business and sponsorship development; and venue technology systems. Headquartered in Plano, Texas, the company has long had the privilege of being an advocate for intercollegiate athletics and the student-athlete experience.</p>`,
				},
				{
					id: 3,
					active: false,
					title: 'What games are played in LevelNext?',
					details: `
                    <p>We have the ability to play most games in LevelNext tournaments. Our inaugural Fall Champions Series kicked off with Madden 21 through an official partnership with Electronic Arts (EA). Through our direct partnership with Psyonix, Rocket League is the official game of the LevelNext Rocket League Spring Showcase. </p>
                   `,
				},
			],
			is_active: false,
		};
	},
	computed: {
		isActive: function() {
			return this.is_active;
		},
	},
	methods: {
		check_if_ambassador() {
			this.is_active = this.$route.matched.some(
				({ name }) => name == 'ambassador',
			);
		},
	},
	mounted() {
		this.check_if_ambassador();
	},
};
</script>

<style lang="scss">
.c-faq {
	background: #000;
	padding: 120px 0 175px 0;
	border-top: 1px solid #222120;
	&__accordion {
		&--column {
			flex: 1 1 50%;
		}
		&--left {
			padding: 0 24px 0 30px;
		}
		&--right {
			padding: 0 30px 0 24px;
		}
		@media screen and (max-width: 1024px) {
			margin-top: 24px;
			&--column {
				flex: 1 1 100%;
			}
			&--left,
			&--right {
				padding: 0 24px;
			}
		}
	}
	.c-accordion {
		padding: 0;
		margin: 78px 0 0 0;
		@media screen and (max-width: 1024px) {
			margin: 0;
		}
		dd {
			margin-left: 0;
		}
		&__item {
			margin-bottom: 24px;
			&.is-active {
				.c-accordion__item-trigger-icon {
					transform: rotate(45deg);
					transition: 0.25s;
				}
			}
			&-enter,
			&-leave-to {
				height: 0 !important;
			}
			&.is-active {
				.c-accordion__item-title-text {
					color: #cc0000;
				}
			}
			&-enter-active,
			&-leave-active {
				will-change: height;
				transition: height 0.2s ease;
			}
			&-details {
				overflow: hidden;
				color: #fff;
				&-inner {
					padding: 24px;
					border-bottom: 1px solid #222120;
					position: relative;
					&:before {
						content: '';
						display: block;
						height: 20px;
						width: 20px;
						z-index: 2;
						background: linear-gradient(
							135deg,
							#000 49%,
							#222120 50%,
							#000 51%
						);
						position: absolute;
						right: 0;
						bottom: -1px;
					}
					&:after {
						content: '';
						display: block;
						height: 100%;
						width: 20px;
						background: #000;
						position: absolute;
						right: 0;
						bottom: -1px;
					}
				}
			}
			&-trigger {
				padding: 12px 0;
				cursor: pointer;
				width: 100%;
				position: relative;
				text-align: left;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #222120;
				padding-right: 60px;
				&:before {
					content: '';
					display: block;
					height: 20px;
					width: 20px;
					z-index: 2;
					background: linear-gradient(
						135deg,
						#000 49%,
						#222120 50%,
						#000 51%
					);
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:after {
					content: '';
					display: block;
					height: 100%;
					width: 20px;
					background: #000;
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:focus {
					outline: none;
				}
				&-icon {
					position: absolute;
					right: 18px;
					top: 12px;
					color: #fff;
					font-size: 24px;
					transform: rotate(0deg);
					transition: 0.25s;
				}
			}
			&-title {
				position: relative;
				&-trigger,
				&-details-inner {
					padding: 0.75rem 1.25rem;
				}
				h4 {
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					margin: 0;
					color: #fff;
					@include nova(700);
				}
			}
		}
		@media screen and (max-width: 540px) {
			&__item-details-inner {
				padding: 24px 24px 24px 0;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 48px 0;
	}
}
</style>
