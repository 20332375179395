<template>
	<div class="c-recap">
		<!-- START Header -->
		<div
			class="c-recap__header position-relative bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
			:style="{
				backgroundImage:
					'url(' +
					require('@/assets/champion-series/tournament-2021.png') +
					')',
			}"
		>
			<div
				class="c-recap__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-recap__header__title padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<video-modal
						:video="
							require('@/assets/2021-madden-recap/Header Video.mp4')
						"
						caption="2021 LevelNext Madden Recap Video"
					></video-modal>
					<h1
						class="font-size-30 bp-1024:font-size-48 margin-0 bp-1024:max-width-50
"
					>
						2021 LevelNext® Madden National Championship Recap
					</h1>
					<div
						class="c-recap__header-meta display-flex align-items-center"
					>
						<span>Madden</span
						><span
							class="c-recap__header-meta-divider margin-x-12 position-relative"
						></span
						><span>$150,000 Prize Pool</span>
					</div>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				></div>
			</div>
		</div>
		<!-- END Header -->

		<!-- START Content -->
		
		<!-- Content is displayed in three formats: -->
		<!-- (1) Section Header Format
			<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
				Header Text
			</h2>
		-->
		<!-- (2) Section Text Format
			<p>Section text</p>
		-->
		<!-- (3) Section Image Format
			<p class="display-flex justify-content-center">
				<img
					:src="
						require('@/assets/2021-madden-recap/IMAGE 1.png')
					"
					alt="Conference Champions"
				/>
			</p>
		-->
		<div
			class="c-recap__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<social-share></social-share>
			<div class="c-recap__content__wrapper">
				<h2 class="c-recap__title position-relative padding-b-12">
					Tournament Recap
				</h2>
				<p>In September 2021, LevelNext and EA announced the return of the highly-anticipated college esports event, the $150,000 LevelNext Madden National Championship.</p>
				<p>The Fall competition saw some format changes, including the expansion of the number of conferences from 4 regions to 11 regions. In addition, the competition introduced 8 Championship spots in the Last Chance Qualifier, as well as a double elimination Championship Bracket.</p>
				<p>The competition kicked off in October 2021 with dozens of intra-collegiate qualifiers, where hundreds of players from all 50 states competed to represent their school on the national stage.</p>
				<p>Upon the conclusion of the qualifiers, players were sorted into eleven regional conferences and the Power 5 conferences. Players went head-to-head in the double-elimination Conference Bracket, slimming the pack down with each round. Several players made successful runs through the Conference lower bracket, with a couple clutching spots in the top 16.</p>
				<p>Among the 16 conferences, winners included 2x Pac-12 Champion, University of Oregon; West Virginia (Big 12); South Carolina (SEC); James Madison (Gridiron); and Weber State (Wildcat). These players punched their tickets straight to the Championship Bracket, where they would await eight final competitors from the Last Chance Qualifier.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 1.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Last Chance Qualifier
				</h2>
				<p>The Last Chance Qualifier offered just that: one final chance for 24 players to secure one of eight remaining spots in the National Championship. While they were not Conference Champions, these players proved to be fierce competitors, hungry for a Championship spot.</p>
				<p>While the number of Championship spots from the LCQ expanded from two in the previous season to eight, the stakes were as high as ever. It was sink or swim in this 3-day single elimination bracket, as players fought tooth and nail for a Championship spot.</p>
				<p>The Last Chance Qualifier delivered intense games and major upsets, with the final eight, a mix of new and familiar players, punching their tickets to the National Championship. Among the eight included SoDakJack from South Dakota, Justin Emmanuel from Vanderbilt, and KarsonKite from Texas Tech.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 2.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Welcome to the Championship
				</h2>
				<p>The LevelNext Madden National Championship came to a head in the 24-player double elimination bracket.</p>
				<p>In the upper bracket, West Virginia’s NoahUpNxt stomped opponent after opponent, sending them down to the fiery pits of the lower bracket. Among those wins, West Virginia defeated Fairleigh Dickinson and Weber State.</p>
				<p>On the other side of the bracket, Oregon had an equally dominant run, sending Northwood’s Dntcareaboutice and Minnesota State Mankato’s Taylor Savoy to the lower bracket.</p>
				<p>However, the players sent down to the lower bracket were not yet defeated. Weber State met UAlbany in the lower bracket semi-finals, both players equally hungry for the ticket to the Grand Finals. Weber State clutched the game winning field goal with 2 seconds left on the clock.</p>
				<p>The winner’s semi-finals was a clash of Madden Titans as West Virginia’s NoahUpNxt (ranked 4th worldwide) defeated Oregon’s MrFootball88 (ranked 7th worldwide).</p>
				<p>With Oregon’s MrFootball88 in the lower bracket, West Virginia was a shoe-in to win the National Title, awaiting the Lower Bracket Finals between Oregon and Weber State.</p>
				<p>With their only losses dealt by West Virginia, Oregon’s MrFootball88 and Weber State’s Chiggsof300 had a lot in common -- and a lot on the line. The winner of the match would have one final chance to take a game off the undefeated NoahUpNxt.</p>
				<p>The match concluded after University of Oregon could not close the point gap following Weber State’s field goal early in the 4th quarter.</p>
				<p>In the Grand Finals, #5 seed Weber State faced off against the undefeated, #1 seed West Virginia in a rematch, after WVU sent Weber State to the lower bracket a couple rounds prior.</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Grand Finals
				</h2>
				<p>The stage was set and it was all eyes on West Virginia and Weber State. Weber State needed 2 wins against West Virginia to take home the title of National Champion.</p>
				<p>It was a back and forth battle, with Weber State answering the points that West Virginia put up. With 2 minutes left in the 4th quarter, Weber State nearly closed the point gap with a touchdown. However, as time ticked away, NoahUpNxt picked up a set of new downs to close out the National Championship.</p>
				<p>And just like that, West Virginia’s NoahUpNxt, runner-up from the 2020 Fall Champions Series, secured the LevelNext Madden National Championship. In addition, he secured the Championship UNDEFEATED, making LevelNext history as the only Madden Champion to earn the National Title without a single loss. He takes home a National Championship Trophy and Championship Ring, as well as a SNICKERS Prize Pack and $25,000! Congratulations Noah!</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 3.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Final Championship Bracket
				</h2>
				<p>Check out the final bracket to see how the action played out:</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 4.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Prizes
				</h2>
				<p>Noah wasn’t the only competitor walking away with cash. The top 16 all walked away with $2,500+ each for a total of $100,000.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 5.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 6.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Twitter Competition
				</h2>
				<p>The LevelNext Madden National Championship saw the third installment of the Twitter Competition, where schools could earn money for their esports programs merely by tweeting. After 2 months of tweeting, the top 20 schools walked away with $50,000 to help grow their esports programs!</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-madden-recap/IMAGE 7.png')
						"
						alt="Last Chance Qualifiers tournament bracket"
					/>
				</p>
			</div>
		</div>
		<!-- END Content -->
	</div>
</template>

<script>
import SocialShare from '@/Social/SocialShare.vue';
import VideoModal from '../../Videos/VideoModal.vue';

export default {
	components: {
		SocialShare,
		VideoModal
	},
	name: 'Madden2021Recap',
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-recap {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: none !important;
			}
		}
		&-meta {
			&-divider {
				&:after {
					content: '';
					background-color: $red;
					height: 4px;
					width: 4px;
					top: 0;
					left: 0;
					position: absolute;
				}
			}
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
				font-size: 21px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
			}
		}
	}
	&__title {
		font-size: 48px;
		line-height: 1;
		margin: 0;
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: 0.25s;
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 1024px;
				width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	&__video {
		position: relative;
		max-width: 75%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
