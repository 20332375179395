<template>
	<div
		class="sa-participants__item border-b"
		:class="{ 'not-expanded': !this.expanded }"
	>
		<header
			class="sa-participants__header display-flex justify-content-space-between align-items-center padding-y-24 padding-x-16 cursor-pointer"
			role="button"
			@click="toggleCardState"
		>
			<div class="sa-participants__title font-size-24">
				<div>{{ teamname }}</div>
			</div>
			<span class="sf-minus-minimal"></span>
		</header>

		<div class="sa-participants__list display-flex flex-wrap-wrap">
			<div
				class="sa-participants__list--item padding-x-12 padding-y-16 width-100 flex-grow-0 flex-shrink-0 col-12-of-12 bp-540:col-6-of-12 bp-1024:col-4-of-12 bp-1440:col-3-of-12 display-flex align-items-center"
				v-for="(member, index) in members"
				:key="index"
			>
				<div class="font-size-18">
					{{ member.firstName }} {{ member.lastName }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueCollapse from 'vue2-collapse';
export default {
	name: 'ParticipantAccordion',
	props: {
		teamname: {
			type: String,
			required: true,
		},
		members: {
			type: Array,
			required: true,
		},
	},
	component: {
		VueCollapse,
	},
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		toggleCardState() {
			this.expanded = !this.expanded;
		},
	},
	mounted() {
		this.expanded = false;
	},
};
</script>

<style lang="scss" scoped>
.sa-participants {
	&__header {
		transition: background-color 0.25s;
		&:hover {
			background-color: $dark-gray;
		}
	}
	&__item {
		&.not-expanded {
			.sa-participants__header {
				padding-bottom: 24px !important;
			}
			.sa-participants__list {
				opacity: 0;
				max-height: 0;
				overflow: hidden;
				padding: 0;
			}
			.sf-minus-minimal {
				&::before {
					content: '\e072';
				}
			}
		}
	}
	&__list {
		max-height: 3000px;
		opacity: 1;
		transition: 0.15s;
		padding: 0 24px 24px;
		&--item {
			&:before {
				content: '\e177';
				font-family: 'sidearm_font_v2';
				margin-right: 8px;
				font-size: 20px;
			}
		}
	}
}
</style>
