<template>
	<div class="p-recap">
		<recap :type="game"></recap>
	</div>
</template>

<script>
import Recap from '@/components/Recap/Recap.vue';

export default {
	name: 'RecapPage',
	props: {
		game: {
			type: String,
			default: null,
		},
	},
	components: {
		Recap,
	},
};
</script>
