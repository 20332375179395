<template>
	<div v-if="slideshow" class="sa-block-parallax position-relative">
		<div
			class="sa-block-parallax__row display-flex justify-content-center"
			:style="{
				'background-image':
					'url(http://levelnext.sidearmsports.com' +
					slideshowImage.image +
					')',
			}"
		>
			<div
				class="sa-block-parallax__text text-uppercase text-center mb-8 padding-24"
			>
				<div
					class="sa-block-parallax__text-upper display-block text-on-dark font-size-36"
				>
					<img
						src="../../assets/svg/interested_stroke.svg"
						alt="LevelNext Logo"
					/>
				</div>
				<div
					class="sa-block-parallax__details display-flex flex-direction-column padding-t-40 align-items-center"
				>
					<div
						v-html="slideshow.teaser"
						class="display-block sa-block-parallax__text-lower font-size-16 font-weight-900 "
					></div>

					<a
						color="secondary"
						style=""
						class="reset width-100 mb-2 display-flex align-items-center font-weight-700 font-size-14 sa-block-parallax__link padding-x-16 margin-t-32 justify-content-center"
						v-for="link in slideshow.links"
						:key="'slideshowlink' + link.link_id"
						:href="link.link_url"
					>
						{{ link.link_text }}
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '../../helper';

export default {
	name: 'BlockSlideshowHero',
	data: () => ({
		slideshow: {
			primary_image: {
				images: [],
			},
		},
		slideshowImg: '',
	}),
	methods: {
		getSlideshow() {
			const request_options = {
				type: 'slideshow',
				sport_id: 0,
				extra: JSON.stringify({ slideshow_id: 3 }),
				count: 1,
			};

			get('/services/adaptive_components.ashx', request_options).then(
				slideshow => {
					if (slideshow && slideshow.length) {
						this.slideshow = slideshow[0];
						if (this.slideshow.primary_image.images.length > 0) {
							this.slideshowImg = this.slideshow.primary_image.images[0];
						}
					}
				},
			);
		},
	},
	computed: {
		slideshowImage: function() {
			return this.slideshowImg;
		},
	},
	created() {
		this.getSlideshow();
	},
};
</script>

<style scoped lang="scss">
.sa-block-parallax {
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.75)
		);
	}
	&__row {
		position: relative;
		background-attachment: fixed;
		background-size: cover;
		background-position: center;
		height: 558px;
		&:after {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
		}
	}
	&__details {
		a {
			height: 36px;
		}
	}
	&__text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
		&-upper {
			img {
				width: 594px;
				padding: 0 24px;
			}
		}
		&-lower {
			font-weight: 400;
			font-size: 21px;
			line-height: 32px;
			text-align: center;
		}
	}
	&__link {
		background-color: $red;
		border-radius: 192px;
		position: relative;
		text-decoration: none;
		transition: 0.25s;
		&::before {
			height: 4px;
			width: 4px;
			background-color: $red;
			position: absolute;
			left: -12px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			background-color: #a30000;
		}
	}
	@media screen and (max-width: 1023px) {
		&__row {
			background-attachment: scroll;
		}
	}
	@media screen and (max-width: 767px) {
		&__text-upper {
			font-size: 64px;
		}
	}
	@media screen and (max-width: 540px) {
		&__text-upper {
			font-size: 46px;
			text-align: center;
		}
	}
}
</style>
