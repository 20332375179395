<template>
	<section class="component c-mission">
		<div class="c-mission__left">
			<div class="c-mission__left-inside">
				<div class="c-mission__left-content">
					<div class="c-mission__content">
						<header class="c-mission__header s-common__header">
							<h2 class="position-relative">
								Taking College Esports Up a Level - LevelNext
							</h2>
						</header>
						<div
							class="c-mission__description c-common__description"
						>
							Combining the best of traditional sports with
							esports to create the next frontier of college
							esports - one that is open to all students through
							inter-collegiate and intra-collegiate tournaments.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="c-mission__right"></div>
	</section>
</template>

<script>
export default {
	name: 'Mission',
	components: {},
	data: function() {
		return {};
	},
	computed: {},
	mounted() {},
};
</script>

<style scoped lang="scss">
.c-mission {
	background: #000;
	display: flex;
	&__left,
	&__right {
		width: 50%;
		position: relative;
		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url('../assets/grid-pattern_black.png');
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}
	&__left {
		&-inside {
			padding-bottom: 100%;
			height: 0;
			position: relative;
			overflow: hidden;
			background: url('../assets/background_second-section_left-side.jpg')
				no-repeat top center;
			background-size: 120%;
		}
		&-content {
			padding: 80px;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;
		}
	}
	&__right {
		background: url('../assets/left.jpg') no-repeat top center;
		background-size: cover;
	}
	&__header {
		margin-bottom: 24px;
		h2 {
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
	}
	&__description {
		max-width: 468px;
	}
	&__content {
		max-width: 569px;
		color: #fff;
		font-size: 1rem;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1.5rem;
		text-align: left;
	}
	@media screen and (max-width: 1120px) {
		&__left-content {
			padding: 24px;
		}
		&__header {
			h2 {
				font-size: 1.875rem;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				line-height: 2.344rem;
				text-align: left;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.c-mission__left-content {
			align-items: flex-start;
		}
	}
	@media screen and (max-width: 767px) {
		display: block;
		&__left,
		&__right {
			width: 100%;
		}
		&__right {
			height: 0;
			padding-bottom: 100%;
		}
	}
	@media screen and (max-width: 540px) {
		&__header h2 {
			font-size: 1.563rem;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 1.953rem;
			text-align: left;
		}
		&__description {
			font-size: 1rem;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: 1.5rem;
			text-align: left;
		}
	}
}
</style>
