<template>
	<div class="component c-homehero display-flex align-items-center">
		<div
			class="c-homehero__info flex-item-1 flex-direction-column display-flex align-items-center justify-content-center"
		>
			<div class="c-homehero__image">
				<img
					src="@/assets/svg/logo_level-next_primary_white.svg"
					alt="LevelNext Big Logo"
				/>
			</div>
			<div class="c-homehero__video">
				<div class="c-homehero__video-inside">
					<video
						ref="myvideo"
						src="/Learfield_Sizzle_V07_CS_FINAL.mp4"
						title="LevelNext Hero Video"
						poster="@/assets/levelnext-poster.png"
					></video>
					<div v-if="!isPlaying" class="c-homehero__video-overlay ">
						<div class="c-homehero__video-logo">
							<img
								src="@/assets/svg/logo_level-next_primary_white.svg"
								alt="LevelNext Big Logo"
							/>
						</div>
						<button class="c-homehero__video-play" @click="play">
							<div>
								<svg
									width="45"
									height="46"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
										fill="currentcolor"
									/>
								</svg>
							</div>
							<span>Play</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeroHero',
	components: {},
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
	computed: {},
	mounted() {},
};
</script>

<style scped lang="scss">
.c-homehero {
	padding: 24px;
	padding-top: 96px;
	padding-bottom: 0;
	background: #000 url('../../assets/news_bg_texture.jpg') no-repeat top
		center;
	background-size: cover;
	width: 100%;
	height: 100vh;
	position: relative;
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		background: linear-gradient(
			90deg,
			#000000 0%,
			#cc0000 51.56%,
			#000000 100%
		);
		position: absolute;
		bottom: 0;
		left: 0;
	}
	&__video {
		position: relative;
		max-width: 66%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	&__title {
		max-width: 620px;
		margin-top: 48px;
	}
	&__info {
		color: #ffffff;
		@include nova(700);
		font-size: 2.063rem;
		font-style: normal;
		letter-spacing: normal;
		line-height: 2.578rem;
		text-align: center;
	}
	&__image {
		display: none;
		max-width: 600px;
	}
	@media screen and (max-width: 1220px) {
		&__video-logo {
			max-width: 300px;
		}
	}
	@media screen and (max-width: 1024px) {
		&__video {
			max-width: 75%;
		}
	}
	@media screen and (max-width: 768px) {
		height: auto;
		padding: 92px 24px;
		margin-top: 96px;
		&__video {
			max-width: 100%;
			margin-top: 48px;
		}
		&__image {
			display: block;
			max-width: 360px;
		}
	}
	@media screen and (max-width: 767px) {
		.c-homehero__video-logo {
			display: none;
			display: none;
		}
	}
	@media screen and (max-width: 540px) {
		&__image {
			max-width: 225px;
		}
		&__info {
			text-align: left;
			color: #ffffff;
			font-size: 1.563rem;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 1.953rem;
			text-align: left;
		}
	}
}
</style>
