<template>
	<div id="custom-tabs-container">
		<div id="custom-tabs">
			<div id="tabs">
				<div
					@click="setPrizesSelected(true)"
					:class="{ selectedTab: prizesSelected }"
					class="tab"
				>
					Prizes
				</div>
				<div
					@click="setPrizesSelected(false)"
					:class="{ selectedTab: !prizesSelected }"
					class="tab"
				>
					Schedule
				</div>
			</div>
			<div id="content">
				<div
					class="tab-content"
					:class="{ selectedContent: prizesSelected }"
				>
					<div class="display-flex flex-direction-column">
						<ul>
							<li
								v-for="(prize, index) in prizes"
								:key="index"
								class="text-muted-on-dark"
							>
								{{ prize }}
							</li>
						</ul>
					</div>
					<!-- <p>TBA</p> -->
				</div>
				<div
					class="tab-content"
					:class="{ selectedContent: !prizesSelected }"
				>
					<ul>
						<li
							v-for="(event, index) in schedule"
							:key="index"
							class="text-muted-on-dark"
						>
							{{ event }}
						</li>
					</ul>
					<!-- <p>
						The Tournament will be streamed&nbsp;
						<a
							href="http://twitch.tv/levelnextgg"
							class="live-button text-default-on-dark"
							>LIVE</a
						>
						&nbsp;on Twitch on June 25.
					</p> -->
					<!-- <p>TBA</p> -->
				</div>
			</div>
		</div>
		<div id="accordion">
			<section class="c-faq">
				<div class="c-faq__content">
					<div class="c-faq__accordion">
						<div class="row">
							<div
								class="c-faq__accordion--left c-faq__accordion--column"
							>
								<Accordion
									id="accordion-left"
									:content="accordion"
									:multiple="true"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
import Accordion from '@/components/Accordion/Accordion';

export default {
	name: 'CustomTabs',
	components: {
		Accordion,
	},
	data: function() {
		return {
			prizesSelected: true,
			prizes: [
				"1st Place - $25,000",
				"2nd Place - $15,000",
				"3rd Place - $10,000",
				"4th Place - $7,500",
				"5th Place - $6,000",
				"6th Place - $6,000",
				"7th Place - $4,250",
				"8th Place - $4,250",
				"9th Place - $3,000",
				"10th Place - $3,000",
				"11th Place - $3,000",
				"12th Place - $3,000",
				"13th Place - $2,500",
				"14th Place - $2,500",
				"15th Place - $2,500",
				"16th Place - $2,500",
			],
			schedule: [
				"Registration (August 18th - September 12th)",
				"Conference Swiss Stage (September 17th - September 18th)",
				"Conference Bracket Stage (September 27th - September 28th; October 4th - October 5th)",
				"Last Chance Qualifier (October 11th - October 13th)",
				"Championship Week (October 17th - October 20th) ",
			],
			accordion: [
				{
					id: 1,
					active: false,
					title: 'Prizes',
					details: `<ul><li class="text-muted-on-dark"> Registration (August 18th - September 12th) </li><li class="text-muted-on-dark"> Conference Swiss Stage (September 17th - September 18th) </li><li class="text-muted-on-dark"> Conference Bracket Stage (September 27th - September 28th; October 4th - October 5th) </li><li class="text-muted-on-dark"> Last Chance Qualifier (October 11th - October 13th) </li><li class="text-muted-on-dark"> Championship Week (October 17th - October 20th)  </li></ul>`,
				},
				{
					id: 2,
					active: false,
					title: 'Schedule',
					details: `<ul><li class="text-muted-on-dark"> Registration (August 18th - September 12th) </li><li class="text-muted-on-dark"> Conference Swiss Stage (September 17th - September 18th) </li><li class="text-muted-on-dark"> Conference Bracket Stage (September 27th - September 28th; October 4th - October 5th) </li><li class="text-muted-on-dark"> Last Chance Qualifier (October 11th - October 13th) </li><li class="text-muted-on-dark"> Championship Week (October 17th - October 20th)  </li></ul>`,
				},
			],
		};
	},
	methods: {
		setPrizesSelected(newPrizesSelected) {
			this.prizesSelected = newPrizesSelected;
		},
	},
};
</script>

<style lang="scss">
#custom-tabs {
	display: block;
	#tabs {
		display: flex;
		border-top: 1px solid #222120;
		border-bottom: 1px solid #222120;
		.tab {
			width: 50%;
			font-size: 25px;
			color: var(--color-dark-theme-muted-text);
			text-align: center;
			padding: 10px;
			&:hover {
				cursor: pointer;
				color: white;
			}
		}
		.selectedTab {
			font-weight: bolder;
			color: white;
			border-bottom: 4px solid #cc0000;
		}
	}
	#content {
		.tab-content {
			display: none;
			width: 100%;
			.live-button {
				padding: 4px;
				background-color: black;
				text-decoration: none;
				letter-spacing: 1.5px;
				font-weight: 700;
				border: 1px solid #cc0000;
				transition: 0.25s;
				&:hover {
					background-color: #cc0000;
				}
			}
		}
		.selectedContent {
			display: block;
		}
	}
	@media screen and (max-width: 450px) {
		display: none;
	}
}
#accordion {
	display: none;
	@media screen and (max-width: 450px) {
		display: block;
	}
}

// Copied from GeneralFaq.vue (but "scoped" by using #accordion)
#accordion .c-faq {
	background: #000;
	padding: 120px 0 175px 0;
	border-top: 1px solid #222120;
	&__accordion {
		&--column {
			flex: 1 1 50%;
		}
		&--left {
			padding: 0 24px 0 30px;
		}
		&--right {
			padding: 0 30px 0 24px;
		}
		@media screen and (max-width: 1024px) {
			margin-top: 24px;
			&--column {
				flex: 1 1 100%;
			}
			&--left,
			&--right {
				padding: 0 24px;
			}
		}
	}
	.c-accordion {
		padding: 0;
		margin: 78px 0 0 0;
		@media screen and (max-width: 1024px) {
			margin: 0;
		}
		dd {
			margin-left: 0;
		}
		&__item {
			margin-bottom: 24px;
			&.is-active {
				.c-accordion__item-trigger-icon {
					transform: rotate(45deg);
					transition: 0.25s;
				}
			}
			&-enter,
			&-leave-to {
				height: 0 !important;
			}
			&.is-active {
				.c-accordion__item-title-text {
					color: #cc0000;
				}
			}
			&-enter-active,
			&-leave-active {
				will-change: height;
				transition: height 0.2s ease;
			}
			&-details {
				.live-button {
					padding: 4px;
					background-color: black;
					text-decoration: none;
					letter-spacing: 1.5px;
					font-weight: 700;
					border: 1px solid #cc0000;
					transition: 0.25s;
					&:hover {
						background-color: #cc0000;
					}
				}
				
				overflow: hidden;
				color: #fff;
				&-inner {
					padding: 24px;
					border-bottom: 1px solid #222120;
					position: relative;
					&:before {
						content: '';
						display: block;
						height: 20px;
						width: 20px;
						z-index: 2;
						background: linear-gradient(
							135deg,
							#000 49%,
							#222120 50%,
							#000 51%
						);
						position: absolute;
						right: 0;
						bottom: -1px;
					}
					&:after {
						content: '';
						display: block;
						height: 100%;
						width: 20px;
						background: #000;
						position: absolute;
						right: 0;
						bottom: -1px;
					}
				}
			}
			&-trigger {
				padding: 12px 0;
				cursor: pointer;
				width: 100%;
				position: relative;
				text-align: left;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #222120;
				padding-right: 60px;
				&:before {
					content: '';
					display: block;
					height: 20px;
					width: 20px;
					z-index: 2;
					background: linear-gradient(
						135deg,
						#000 49%,
						#222120 50%,
						#000 51%
					);
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:after {
					content: '';
					display: block;
					height: 100%;
					width: 20px;
					background: #000;
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:focus {
					outline: none;
				}
				&-icon {
					position: absolute;
					right: 18px;
					top: 12px;
					color: #fff;
					font-size: 24px;
					transform: rotate(0deg);
					transition: 0.25s;
				}
			}
			&-title {
				position: relative;
				&-trigger,
				&-details-inner {
					padding: 0.75rem 1.25rem;
				}
				h4 {
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 24px;
					margin: 0;
					color: #fff;
					@include nova(700);
				}
			}
		}
		@media screen and (max-width: 540px) {
			&__item-details-inner {
				padding: 24px 24px 24px 0;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 24px 0;
	}
}

// This is out on it's own because tunneling into the 
.live-button {
	padding: 4px;
	background-color: black;
	text-decoration: none;
	letter-spacing: 1.5px;
	font-weight: 700;
	border: 1px solid #cc0000;
	transition: 0.25s;
	&:hover {
		background-color: #cc0000;
	}
}
</style>
