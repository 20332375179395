<template>
	<div>
		<div class="c-pressrelease">
			<div class="c-pressrelease__hero">
				<img
					src="@/assets/level-next-registration-header.jpg"
					class="c-pressrelease__hero-image"
					alt="Homepage Image"
				/>
			</div>
			<div class="c-pressrelease__video">
				<div class="c-pressrelease__row">
					<div class="c-pressrelease__video-inside">
						<video
							ref="myvideo"
							src="/Level_Next_Reel_60s_RL.mp4"
							title="LevelNext Hero Video"
						></video>
						<div
							v-if="!isPlaying"
							class="c-pressrelease__video-overlay"
						>
							<button
								class="c-pressrelease__video-play"
								@click="play"
							>
								<div>
									<svg
										width="45"
										height="46"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
											fill="currentcolor"
										/>
									</svg>
								</div>
							</button>
						</div>
					</div>
					<div class="c-pressrelease__copy">
						<header class="c-pressrelease__title s-common__header">
							<h2>LevelNext Rocket League Spring Showcase</h2>
						</header>
						<div class="c-pressrelease__secondary">
							College Teams Battle on the Rocket League Pitch for
							$125,000 Prize Pool
						</div>
						<div class="c-pressrelease__body">
							<p>
								LevelNext has partnered with Psyonix to host the
								LevelNext Rocket League Spring Showcase!
							</p>

							<p>
								All full-time students have the opportunity to
								earn the right to represent their school and
								compete against other schools for glory and
								$125,000 in cash prizes! Rocket League teams
								will play for a $75,000 prize pool while school
								esports programs will vie for $50,000 in the
								<a href="/twittercompetition"
									>#LevelNextShowcase Twitter competition</a
								>.
							</p>

							<p>
								The tournament is FREE to enter and kicks off
								May 10, so assemble your team and register here
								starting May 4th!
							</p>
						</div>
						<div class="c-pressrelease__register">
							Come Back to Register on May 4th
						</div>
					</div>
				</div>
				<div class="c-pressrelease__keydate">
					<div class="c-pressrelease__keydate-row">
						<header
							class="c-pressrelease__keydate-header s-common__header"
						>
							<h2>Key Dates</h2>
						</header>
						<div class="c-pressrelease__keydate-container">
							<div class="c-pressrelease__keydate-item">
								<div class="c-pressrelease__keydate-date">
									May 4 - 9
								</div>
								<div class="c-pressrelease__keydate-title">
									Registration
								</div>
							</div>
							<div class="c-pressrelease__keydate-item">
								<div class="c-pressrelease__keydate-date">
									May 10 - 16
								</div>
								<div class="c-pressrelease__keydate-title">
									Intra and Inter-Collegiate Qualifers
								</div>
							</div>
							<div class="c-pressrelease__keydate-item">
								<div class="c-pressrelease__keydate-date">
									May 18 - 23
								</div>
								<div class="c-pressrelease__keydate-title">
									Group Stage
								</div>
							</div>
							<div class="c-pressrelease__keydate-item">
								<div class="c-pressrelease__keydate-date">
									May 28 - 30
								</div>
								<div class="c-pressrelease__keydate-title">
									Last Chance Qualifer
								</div>
							</div>
							<div class="c-pressrelease__keydate-item">
								<div class="c-pressrelease__keydate-date">
									June 4 - 6
								</div>
								<div class="c-pressrelease__keydate-title">
									Championships
								</div>
							</div>
						</div>
						<div class="c-pressrelease__keydate-promo">
							<a href="#">
								<picture>
									<source
										srcset="@/assets/promo_desktop.png"
										media="(min-width: 1024px)"
									/>
									<img
										src="@/assets/promo_mobile.png"
										alt="Open To All College Students. Registration Opens May "
									/>
								</picture>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Mission />
		<div class="c-pressrelease__partner">
			<div class="c-pressrelease__partner-inner">
				<div class="c-pressrelease__partner-row">
					<header
						class="c-pressrelease__partner-header s-common__header"
					>
						<h2>Unilever and United for America Join the Team</h2>
					</header>
					<div class="c-pressrelease__partner-copy">
						Unilever and its United for America program joined the
						tournament to help create awareness for the pervasive
						issue of food insecurity on U.S. college campuses. As a
						LevelNext Rocket League Spring Showcase partner,
						Unilever will donate one million meals to Feeding
						America to create awareness for food insecurity on
						campuses and to promote its National Day of Service on
						May 14.
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Mission from '@/components/Mission.vue';
export default {
	name: 'PressRelease',
	components: {
		Mission,
	},
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
	computed: {},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.c-pressrelease {
	background: url('../assets/desktop-bg.jpg') fixed center center no-repeat;
	background-size: cover;
	@include nova(700);
	&__hero {
		padding-bottom: 56.25%;
		position: relative;
		&-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	&__row {
		max-width: 1008px;
		width: 100%;
		margin: 0 auto;
		padding: 0 24px;
	}
	&__video {
		position: relative;
		width: 100%;
		padding-top: 120px;
		padding-bottom: 98px;
		color: #fff;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.8) 0%,
			rgba(0, 0, 0, 0.6) 17.63%,
			rgba(0, 0, 0, 0.4) 36.52%,
			rgba(0, 0, 0, 0.4) 65.64%,
			rgba(0, 0, 0, 0.6) 83.34%,
			rgba(0, 0, 0, 0.8) 100%
		);
		&-logo {
			max-width: 350px;
			margin: 0 auto;
			padding-bottom: 60px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid rgba(255, 255, 255, 0.25);
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	&__title {
		font-size: 1.667rem;
		padding-bottom: 24px;
		padding-top: 60px;
	}
	&__secondary {
		font-size: 1.667rem;
	}
	&__copy {
		padding: 0 76px;
	}
	&__body {
		@include nova(400);
		a {
			color: $red;
			text-decoration: none;
			position: relative;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				height: 1px;
				width: 0;
				transition: 0.25s;
				background-color: $red;
			}
			&:hover {
				&::after {
					width: 100%;
				}
			}
		}
	}
	&__register {
		margin-top: 36px;
		background-color: $red;
		text-align: center;
		border-radius: 24px;
		padding: 14px 0;
	}
	&__keydate {
		padding-top: 143px;
		&-row {
			max-width: 1344px;
			padding: 0 24px;
			width: 100%;
			margin: 0 auto;
		}
		&-header {
			h2 {
				font-size: 1.667rem;
				text-align: center;
				&::after {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		&-container {
			display: flex;
			text-align: center;
			padding-top: 60px;
			padding-bottom: 120px;
		}
		&-item {
			flex: 1 1 20%;
			min-height: 1px;
			min-width: 1px;
			min-height: 210px;
			background: rgba(0, 0, 0, 0.4);
			border-left: 1px solid #222120;
			border-top: 1px solid #222120;
			border-bottom: 1px solid #222120;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 10px;
			&:last-child {
				border-right: 1px solid #222120;
			}
		}
		&-date {
			font-size: 1.667rem;
		}
		&-title {
			@include nova(400);
			font-size: 1rem;
			padding-top: 12px;
			min-height: 60px;
		}
		&-promo {
			text-align: center;
		}
	}
	&__partner {
		background-color: #000;
		position: relative;
		&::before {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			top: -2px;
			left: 0;
		}
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.8) 0%,
				rgba(0, 0, 0, 0.6) 17.63%,
				rgba(0, 0, 0, 0.4) 36.52%,
				rgba(0, 0, 0, 0.4) 65.64%,
				rgba(0, 0, 0, 0.6) 83.34%,
				rgba(0, 0, 0, 0.8) 100%
			);
			z-index: 1;
		}
		&-inner {
			background: url('../assets/desktop-bg.jpg') fixed center center
				no-repeat;
			padding-bottom: 158px;
			background-size: cover;
		}
		&-header {
			h2 {
				text-align: center;
				&::after {
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
		&-row {
			max-width: 1464px;
			margin: auto;
			padding-left: 304px;
			padding-right: 304px;
			padding-top: 120px;
			position: relative;
			z-index: 2;
		}
		&-copy {
			color: #fff;
			padding-top: 52px;
		}
	}
	@media screen and (max-width: 1280px) {
		&__partner {
			&-inner {
				padding-bottom: 62px;
			}
			&-row {
				padding-left: 120px;
				padding-right: 120px;
				padding-top: 72px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		&__video {
			padding-top: 72px;
		}
		&__keydate-container {
			flex-wrap: wrap;
		}
		&__keydate-item {
			flex: 1 1 25%;
			&:nth-child(4) {
				border-right: 1px solid #222120;
			}
			&:last-child {
				border-top: 1px solid transparent;
			}
		}
		&__keydate {
			padding-top: 42px;
		}
		&__partner-inner {
			background-attachment: scroll;
		}
	}
	@media screen and (max-width: 768px) {
		margin-top: 96px;
		&__keydate-item {
			flex: 1 1 100%;
			border-right: 1px solid #222120;
			border-bottom: 1px solid transparent;
			&:last-child {
				border-top: 1px solid #222120;
				border-bottom: 1px solid #222120;
			}
		}
		&__row {
			padding: 0 24px;
		}
		&__copy {
			padding: 0;
		}
		&__partner-row {
			padding-left: 24px;
			padding-right: 24px;
		}
	}
}
</style>
