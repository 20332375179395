<template>
	<div>
		<component :is="type"></component>
	</div>
</template>

<script>
import GeneralFaq from '@/components/Common/Faqs/GeneralFaq.vue';
import AmbassadorFaq from '@/components/Common/Faqs/AmbassadorFaq.vue';
export default {
	name: 'FAQ',
	components: {
		general: GeneralFaq,
		ambassador: AmbassadorFaq,
	},
	data() {
		return {
			type: 'general',
		};
	},
	methods: {
		updateTemplate() {
			if (this.$route.matched.some(({ name }) => name == 'Ambassador')) {
				this.type = 'ambassador';
			} else {
				this.type = 'general';
			}
		},
	},
	watch: {
		$route() {
			this.updateTemplate();
		},
	},
	created() {
		this.updateTemplate();
	},
};
</script>

<style lang="scss">
.c-faq {
	background: #000;
	padding: 120px 0 175px 0;
	border-top: 1px solid #222120;
	&__accordion {
		&--column {
			flex: 1 1 50%;
		}
		&--left {
			padding: 0 24px 0 30px;
		}
		&--right {
			padding: 0 30px 0 24px;
		}
		@media screen and (max-width: 1024px) {
			margin-top: 24px;
			&--column {
				flex: 1 1 100%;
			}
			&--left,
			&--right {
				padding: 0 24px;
			}
		}
	}
	.c-accordion {
		padding: 0;
		margin: 78px 0 0 0;
		@media screen and (max-width: 1024px) {
			margin: 0;
		}
		dd {
			margin-left: 0;
		}
		&__item {
			margin-bottom: 24px;
			&.is-active {
				.c-accordion__item-trigger-icon {
					transform: rotate(45deg);
					transition: 0.25s;
				}
			}
			&-enter,
			&-leave-to {
				height: 0 !important;
			}
			&.is-active {
				.c-accordion__item-title-text {
					color: #cc0000;
				}
			}
			&-enter-active,
			&-leave-active {
				will-change: height;
				transition: height 0.2s ease;
			}
			&-details {
				overflow: hidden;
				color: #fff;
				&-inner {
					padding: 24px;
					border-bottom: 1px solid #222120;
					position: relative;
					&:before {
						content: '';
						display: block;
						height: 20px;
						width: 20px;
						z-index: 2;
						background: linear-gradient(
							135deg,
							#000 49%,
							#222120 50%,
							#000 51%
						);
						position: absolute;
						right: 0;
						bottom: -1px;
					}
					&:after {
						content: '';
						display: block;
						height: 100%;
						width: 20px;
						background: #000;
						position: absolute;
						right: 0;
						bottom: -1px;
					}
				}
			}
			&-trigger {
				padding: 12px 0;
				cursor: pointer;
				width: 100%;
				position: relative;
				text-align: left;
				background-color: transparent;
				border: none;
				border-bottom: 1px solid #222120;
				padding-right: 60px;
				&:before {
					content: '';
					display: block;
					height: 20px;
					width: 20px;
					z-index: 2;
					background: linear-gradient(
						135deg,
						#000 49%,
						#222120 50%,
						#000 51%
					);
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:after {
					content: '';
					display: block;
					height: 100%;
					width: 20px;
					background: #000;
					position: absolute;
					right: 0;
					bottom: -1px;
				}
				&:focus {
					outline: none;
				}
				&-icon {
					position: absolute;
					right: 18px;
					top: 12px;
					color: #fff;
					font-size: 24px;
					transform: rotate(0deg);
					transition: 0.25s;
				}
			}
			&-title {
				position: relative;
				&-trigger,
				&-details-inner {
					padding: 0.75rem 1.25rem;
				}
				h4 {
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;
					margin: 0;
					color: #fff;
					@include nova(700);
				}
			}
		}
		@media screen and (max-width: 540px) {
			&__item-details-inner {
				padding: 24px 24px 24px 0;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 48px 0;
	}
}
</style>
