<template>
	<div class="c-recap">
		<!-- START Header -->
		<div
			class="c-recap__header position-relative bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
            :style="{
				backgroundImage:
					'url(' +
					require('@/assets/champion-series/2022-LevelNext-FIFA-22-College-National-Championship.png') +
					')',
			}"
		>
			<div
				class="c-recap__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-recap__header__title padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<video-modal
						:video="
							require('@/assets/2022-fifa-recap/Header Video.mp4')
						"
						caption="2022 LevelNext FIFA 22 College National Championship Recap"
					></video-modal>
					<h1
						class="font-size-30 bp-1024:font-size-48 margin-0 bp-1024:max-width-50
"
					>
						2022 LevelNext® FIFA 22 College National Championship Recap
					</h1>
					<div
						class="c-recap__header-meta display-flex align-items-center"
					>
						<span>FIFA</span
						><span
							class="c-recap__header-meta-divider margin-x-12 position-relative"
						></span
						><span>$100,000 Prize Pool</span>
					</div>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				></div>
			</div>
		</div>
		<!-- END Header -->

		<!-- START Content -->
		
		<!-- Content is displayed in four formats: -->
		<!-- (1) Section Header Format
			<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
				Header Text
			</h2>
		-->
		<!-- (2) Section Text Format
			<p>Section text</p>
		-->
		<!-- (3) Section Image Format
			<p class="display-flex justify-content-center">
				<img
					:src="
						require('@/assets/2022-fifa-recap/IMAGE 1.png')
					"
					alt="Conference Champions"
				/>
			</p>
		-->
        <!-- (4) Recap Video Format
            <div class="c-recap__video margin-x-auto margin-t-24">
                <div class="c-recap__video-inside">
                    <video
                        ref="myvideo"
                        :src="
                            require('@/assets/2020-madden-recap/madden-recap_highlight.mp4')
                        "
                        title="LevelNext Madden Highlight Video"
                    ></video>
                    <div v-if="!isPlaying" class="c-recap__video-overlay">
                        <button class="c-recap__video-play" @click="play">
                            <div>
                                <svg
                                    width="45"
                                    height="46"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
                                        fill="currentcolor"
                                    />
                                </svg>
                            </div>
                            <span>Play</span>
                        </button>
                    </div>
                </div>
            </div>
        -->
		<div
			class="c-recap__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<social-share></social-share>
			<div class="c-recap__content__wrapper">
				<h2 class="c-recap__title position-relative padding-b-12">
                    Tournament Recap
                </h2>
                <p>In March 2022, LevelNext and EA announced the first-ever $50,000 LevelNext FIFA 22 College National Championship.</p>
                <p>The tournament kicked off on April 30th with the group swiss stage, where the top 8 players from each conference moved on to the single-elimination group stage. The group swiss stage saw dominant runs from players such as Aliattack (Penn State), ZJszn (UNC Charlotte), MaxMex99 (New Mexico State), and Huntersniper101 (University of Illinois-Springfield), who did not drop a single match.</p>
                <p>The finals of the single-elimination group stage were broadcasted live and the stage was set. The top player from each conference would secure their spots in the Championship, leaving those who placed 2nd through 4th to fight it out in the Last Chance Qualifier gauntlet. The group stage finals saw close games between Baylor and North Texas, with Baylor securing their championship spot after a golden goal in overtime.</p>
                <p>Check out the top 16 Conference Champions who punched their tickets to the Championship:</p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 1.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
                    Last Chance Qualifier
                </h2>
                <p>The Last Chance Qualifier offered the 48 remaining players one final opportunity to clutch one of eight final championship spots. It’s sink or swim in this best-of-one, single elimination bracket.</p>
                <p>Meet the 48 Last Chance Qualifier contenders, vying for a spot at the top:</p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 2.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 3.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <p>North Texas made a strong showing through the LCQ bracket, crushing opponents from Northern Kentucky, Minnesota State Mankato, and Baruch College. On the other side of the bracket, CSU Northridge clutched their spot after a close call against Bowie State. At long last, CSUN, Webster, Illinois-Springfield, UT Arlington, Kennesaw State, Eastern Kentucky, North Texas, and Baruch College punched their tickets to the LevelNext FIFA 22 College National Championship.</p>
                <h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
                    Championship
                </h2>
                <p>The LevelNext FIFA 22 College National Championship culminated in a 3-day, double-elimination Championship Bracket. The top 16 who punched their tickets through the group stage took their places in the upper bracket, while the LCQ Champions secured their spots in the lower bracket.</p>
                <p>The LCQ was intense, but with $50,000 on the line, the Championship Bracket proved to be intense in a different way.</p>
                <p>In the upper bracket, Penn State’s Aliattack had a dominant run, sending opponent after opponent into the lower bracket. On the other side of the upper bracket, ZJSzn from UNC Charlotte had an equally dominant run, beating out Purchase College, Northeastern, and CUNY Queens College.</p>
                <p>After being sent to the lower bracket by Aliattack from Penn State, Evangel University’s Vic7 defeated fan-favorite UNLV, CUNY Queens College, and Minnesota State, Mankato.</p>
                <p>Penn State met UNC Charlotte in the upper bracket semi-finals. Aliattack came out swinging, scoring 2 goals in the first half of the game. While ZJszn from UNC Charlotte was able to get a goal on the board, Penn State finished strong with another 2 goals in the second half.</p>
                <p>ZJszn was not going to let one loss get his hopes up – he had one final chance in the lower bracket to show the world what he was made of. He met Evangel University’s Vic7, hot off 2 wins of the night, in the lower bracket finals. The match remained 1-1 until the 21st minute of overtime, when ZJszn scored the golden goal.</p>
                <p>The stage was set. ZJszn from UNC Charlotte geared for a rematch against Penn State’s Aliattack. ZJszn needed two wins over Aliattack to reset the bracket and become the champion of the LevelNext FIFA 22 College National Championship. UNC Charlotte maintained a 1-0 lead for the majority of the match until minute 74 when Penn State tied up the match.</p>
                <p>The game came to a close in overtime – in the 17th minute, Aliattack scored the golden goal – securing himself $12,500, a Championship trophy, and the Championship ring.</p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 4.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
                    Game Winning Goal
                </h2>
                <div class="c-recap__video margin-x-auto margin-t-24">
                    <div class="c-recap__video-inside">
                        <video
                            ref="myvideo"
                            :src="
                                require('@/assets/2022-fifa-recap/Game Winning Goal.mp4')
                            "
                            title="2022 LevelNext FIFA 22 College National Championship Game Winning Goal"
                        ></video>
                        <div v-if="!isPlaying" class="c-recap__video-overlay">
                            <button class="c-recap__video-play" @click="play">
                                <div>
                                    <svg
                                        width="45"
                                        height="46"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
                                            fill="currentcolor"
                                        />
                                    </svg>
                                </div>
                                <span>Play</span>
                            </button>
                        </div>
                    </div>
                </div>
                <h2 class="c-recap__title position-relative padding-b-12 margin-t-32">
                    Final Championship Bracket
                </h2>
                <p>Check out the final bracket to see how the action played out:</p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 5.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
                    Prizes
                </h2>
                <p>Aliattack wasn’t the only player walking away with cash. The top 16 players each took a piece of the $50,000 prize pool. Check out the prize breakdown:</p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 6.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
                <p class="display-flex justify-content-center">
                    <img
                        :src="
                            require('@/assets/2022-fifa-recap/IMAGE 7.png')
                        "
                        alt="Conference Champions"
                    />
                </p>
			</div>
		</div>
		<!-- END Content -->
	</div>
</template>

<script>
import SocialShare from '@/Social/SocialShare.vue';
import VideoModal from '../../Videos/VideoModal.vue';

export default {
	components: {
		SocialShare,
		VideoModal
	},
	name: 'FifaRecap2022',
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-recap {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: none !important;
			}
		}
		&-meta {
			&-divider {
				&:after {
					content: '';
					background-color: $red;
					height: 4px;
					width: 4px;
					top: 0;
					left: 0;
					position: absolute;
				}
			}
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
				font-size: 21px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
			}
		}
	}
	&__title {
		font-size: 48px;
		line-height: 1;
		margin: 0;
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: 0.25s;
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 1024px;
				width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	&__video {
		position: relative;
		max-width: 75%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
