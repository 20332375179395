<template>
	<div class="c-recap">
		<!-- START Header -->
		<div
			class="c-recap__header position-relative bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
			:style="{
				backgroundImage:
					'url(' +
					require('@/assets/champion-series/2022-LevelNext-Rocket-League-Spring-Showcase.png') +
					')',
			}"
		>
		
			<div
				class="c-recap__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-recap__header__title padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<video-modal
						:video="
							require('@/assets/2022-rocket-league-recap/Header Video.mp4')
						"
						caption="2022 LevelNext Rocket League Spring Showcase Recap"
					></video-modal>
					<h1
						class="font-size-30 bp-1024:font-size-48 margin-0 bp-1024:max-width-50
"
					>
						2022 LevelNext® Rocket League Spring Showcase Recap
					</h1>
					<div
						class="c-recap__header-meta display-flex align-items-center"
					>
						<span>Rocket League</span
						><span
							class="c-recap__header-meta-divider margin-x-12 position-relative"
						></span
						><span>$125,000 Prize Pool</span>
					</div>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				></div>
			</div>
		</div>
		<!-- END Header -->

		<!-- START Content -->
		
		<!-- Content is displayed in three formats: -->
		<!-- (1) Section Header Format
			<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
				Header Text
			</h2>
		-->
		<!-- (2) Section Text Format
			<p>Section text</p>
		-->
		<!-- (3) Section Image Format
			<p class="display-flex justify-content-center">
				<img
					:src="
						require('@/assets/2022-rocket-league-recap/IMAGE 1.png')
					"
					alt="Conference Champions"
				/>
			</p>
		-->
        
		<div
			class="c-recap__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<social-share></social-share>
			<div class="c-recap__content__wrapper">
				<h2 class="c-recap__title position-relative padding-b-12">
					Tournament Recap
				</h2>
				<p>On March 1, 2022, LevelNext and Psyonix announced the 2nd-annual LevelNext Rocket League Spring Showcase.</p>
				<p>While this year’s competition promised $75,000, a crystal trophy, and a championship ring – there was more at stake than ever before. The LevelNext Rocket League Spring Showcase offered four spots to the CRL Last Chance Qualifier – which would feed into the CRL World Championship.</p>
				<p>The LevelNext Rocket League Spring Showcase kicked off April 3, 2022 with the conference stage, where teams competed in their respective Power 5 conferences and 11 regional divisions in a swiss bracket.</p>
				<p>After the swiss bracket concluded, the top 8 teams from each conference moved on to compete in the single-elimination bracket stage within their conference, where the top team from each advanced straight to the Championship Bracket.</p>
				<p>The line-up of Conference Champions included the likes of the reigning champions, University of Akron; Northwood University, Texas Tech, and Colorado.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 1.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Last Chance Qualifier
				</h2>
				<p>While these top 16 schools punched their tickets to the LevelNext Championship, it wasn’t game over yet for those who placed 2nd and 3rd in their conference. These teams had a final opportunity to compete for one of eight Championship spots in the LevelNext Last Chance Qualifier.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 2.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 3.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<p>The competition was fierce in the LCQ, with the best teams swimming as others sank. Among the few to clutch the final 8 championship spots were Mizzou, West Virginia, and the University of Florida.</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Championship
				</h2>
				<p>The LevelNext Rocket League Spring Showcase came to a head in the 3-Day Championship Bracket.</p>
				<p>Northwood University maintained a dominant performance as they sent team after team down to the lower bracket. On the other side of the bracket, UT Arlington fought through the lower bracket gauntlet, nearly going home after a close match against the Florida Gators. UT Arlington ultimately fell to reigning Champions, the University of Akron, in the lower bracket semi-finals.</p>
				<p>In the lower bracket finals, the Akron Zips defeated Seton Hall to meet Northwood in the Grand Finals for a rematch of the 2021 Spring Showcase Finals. Northwood University maintained their dominant performance, denying the Zips a chance to even take a game off of them. The Northwood Timberwolves secured their Championship title with a swift 4-0 over Akron.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 4.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Bracket
				</h2>
				<p>Check out the final bracket:</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 5.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Top 4 CRL LCQ Spots
				</h2>
				<p>Since many of the top 24 teams already had secured their spots either in the CRL World Championship or the CRL Last Chance Qualifier, the 4 spots went to Florida Poly, Rutgers, James Madison, and Siena Heights.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 6.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Prizes
				</h2>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 7.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 8.png')
						"
						alt="Conference Champions"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12 margin-t-24">
					Takis Twitter Competition
				</h2>
				<p>This year’s competition also saw the $50,000 Takis Twitter Competition, where esports programs competed for a chance at $50,000. The competition proved to be as intense as Takis #TheMostIntenseSnacks.</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2022-rocket-league-recap/IMAGE 9.png')
						"
						alt="Conference Champions"
					/>
				</p>
			</div>
		</div>
		<!-- END Content -->
	</div>
</template>

<script>
import SocialShare from '@/Social/SocialShare.vue';
import VideoModal from '../../Videos/VideoModal.vue';

export default {
	components: {
		SocialShare,
		VideoModal
	},
	name: 'RocketLeagueRecap2022',
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-recap {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: none !important;
			}
		}
		&-meta {
			&-divider {
				&:after {
					content: '';
					background-color: $red;
					height: 4px;
					width: 4px;
					top: 0;
					left: 0;
					position: absolute;
				}
			}
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
				font-size: 21px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
			}
		}
	}
	&__title {
		font-size: 48px;
		line-height: 1;
		margin: 0;
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: 0.25s;
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 1024px;
				width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	&__video {
		position: relative;
		max-width: 75%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
