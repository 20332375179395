<template>
	<div>
		<hero type="ambassador"></hero>
		<perks>
			<template v-slot:left-image>
				<div class="c-ambassador__leftimage"></div>
			</template>
			<template v-slot:right-image>
				<div class="c-ambassador__rightimage"></div>
			</template>
			<template v-slot:desciption>
				<ul>
					<li>Up to $1,000 cash</li>
					<li>Sweet LevelNext® gear</li>
					<li>First to know about all LevelNext® events & info</li>
					<li>Access to a nationwide network of student leaders</li>
					<li>Leadership and resume-building opportunities</li>
					<li>
						Opportunity for your college esports program to win one
						of 20 annual $1,000 scholarships that can be awarded to
						any member
					</li>
				</ul>
			</template>
		</perks>
		<SlideshowContent />
	</div>
</template>

<script>
import Hero from '@/components/Common/Hero/Hero.vue';
import Perks from '@/components/Common/HtmlContentBlock.vue';
import SlideshowContent from '@/components/Slideshow/BlockSlideshowContent.vue';

export default {
	name: 'Ambassador',
	components: {
		Hero,
		Perks,
		SlideshowContent,
	},
};
</script>

<style scoped lang="scss">
.c-ambassador {
	&__leftimage {
		padding-bottom: 100%;
		height: 0;
		position: relative;
		overflow: hidden;
		//background: url('../assets/ambassador.jpeg') no-repeat top center;
		background-size: cover;
	}
	&__rightimage {
		background: url('../assets/ambassador.jpeg') no-repeat top center;
		background-size: cover;
	}
	&__title {
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg) translateX(-50%);
			position: absolute;
			left: 50%;
			bottom: 0;
		}
	}
	&__link {
		background-color: $red;
		border-radius: 192px;
		position: relative;
		text-decoration: none;
		transition: 0.25s;
		padding: 16px;
		&::before {
			height: 4px;
			width: 4px;
			background-color: $red;
			position: absolute;
			left: -12px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			background-color: #a30000;
		}
	}
	@media (max-width: 768px) {
		&__leftimage {
			padding-bottom: 0;
		}
	}
}
</style>
