<template>
	<div class="p-overview">
		<overview-hero />
		<overview-mission />
		<!--<LiveStreaming2 /> -->
		<section id="involved" class="s-tournamentModes">
			<div class="row small pad position-relative">
				<div class="s-tournamentModes__right">
					<header>
						<h2 class="hide">Get Involved</h2>
						<div class="s-tournamentModes__arrow">
							<img
								src="../assets/overview_arrow.png"
								alt="Get Involved"
							/>
						</div>
						<div class="s-tournamentModes__logo">
							<img
								src="../assets/svg/overview_get_involved.svg"
								alt="Get Involved"
							/>
						</div>
					</header>
					<TournamentMode />
				</div>
			</div>
		</section>
		<!-- <overview-learn-more /> -->
		<!--<div class="s-tournamentModesChallenger">
			<div class="row small pad position-relative">
				<TournamentModeChallenger />
			</div>
		</div>-->
		<!-- <overview-recap /> -->
	</div>
</template>

<script>
import OverviewHero from '@/components/Overview/OverviewHero.vue';
import OverviewMission from '@/components/Overview/OverviewMission.vue';
// import LiveStreaming2 from '@/components/LiveStreaming2.vue';
//import OverviewLearnMore from '@/components/Overview/OverviewLearnMore.vue';
import TournamentMode from '@/components/Overview/TournamentMode.vue';
//import TournamentModeChallenger from '@/components/Overview/TournamentModeChallenger.vue';
// import OverviewRecap from '@/components/Overview/OverviewRecap.vue';
export default {
	name: 'Overview',
	components: {
		OverviewHero,
		OverviewMission,
		// LiveStreaming2,
		//OverviewLearnMore,
		TournamentMode,
		//TournamentModeChallenger,
		// OverviewRecap,
	},
	data: function() {
		return {};
	},
	computed: {},
	mounted() {},
};
</script>
<style scoped lang="scss">
.s-tournamentModesChallenger {
	@media screen and (max-width: 860px) {
		background: #000;
		padding-top: 48px;
		padding-bottom: 48px;
	}
}
.s-tournamentModes {
	padding-top: 140px;
	padding-bottom: 156px;
	&__logo {
		margin-bottom: 162px;
	}
	&__arrow {
		top: 240px;
		left: -110px;
		position: absolute;
		z-index: 2;
		img {
			max-height: 311px;
		}
	}
	@media screen and (max-width: 1600px) {
		&__right {
			header {
				position: relative;
				display: flex;
				padding: 0 24px 130px 93px;
				margin-bottom: 24px;
			}
		}
		&__logo {
			max-width: 505px;
			margin: 0 0 0 0;
		}
		&__arrow {
			width: 93px;
			flex-shrink: 0;
			left: 0;
			top: auto;
			bottom: 0px;
		}
	}
	@media screen and (max-width: 860px) {
		background: #000;
		padding-top: 48px;
		padding-bottom: 48px;
		&__right {
			header {
				position: relative;
				display: flex;
				padding: 0 24px 130px 93px;
				margin-bottom: 24px;
			}
		}
		&__logo {
			max-width: 505px;
			margin: 0 0 0 0;
		}
		&__arrow {
			width: 93px;
			flex-shrink: 0;
			left: 0;
			top: auto;
			bottom: 0px;
		}
	}
	@media screen and (max-width: 640px) {
		padding-top: 78px;
		&__arrow {
			width: 70px;
		}
		&__right {
			header {
				position: relative;
				display: flex;
				padding: 0 0 188px 0;
				margin-bottom: 24px;
			}
		}
	}
}
</style>
