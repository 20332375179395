var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-tournament"},[(!_vm.loading)?_c('div',[(_vm.details && _vm.details.url)?_c('TournamentHero',{attrs:{"details":_vm.details}}):_vm._e(),_c('Participants',{attrs:{"guid":_vm.guid}}),(_vm.overview.description)?_c('TournamentInfo',{attrs:{"overview":_vm.overview,"details":_vm.details}}):_vm._e(),(_vm.details.phases && _vm.details.phases[0].groups.length)?_c('div',{staticClass:"row"},[(_vm.details.phases && _vm.details.phases[0].groups.length)?_c('h2',{staticClass:"font-size-30 bp-1024:font-size-48 margin-b-24 flex-basis-100 padding-x-24"},[_vm._v(" Schedule ")]):_vm._e(),(!this.isMobile)?_c('div',{staticClass:"row width-100"},[_c('div',{staticClass:"s-tournament-schedule padding-x-24 col-8-of-12"},[(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].rounds.length
						)?_c('TournamentSchedule',{attrs:{"details":_vm.details}}):_vm._e(),(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].rounds.length <
									1
						)?_c('div',{staticClass:"s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"},[_c('p',[_vm._v("Matchups to be determined after registration")])]):_vm._e()],1),_c('div',{staticClass:"s-tournament-standings padding-x-24 col-4-of-12"},[(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].standings.length
						)?_c('TournamentStandings',{attrs:{"details":_vm.details}}):_vm._e(),(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].standings
									.length < 1
						)?_c('div',{staticClass:"s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"},[_c('p',[_vm._v("Rankings to be determined after gameplay")])]):_vm._e()],1)]):_c('div',{staticClass:"width-100"},[_c('div',{staticClass:"s-tournament__buttons padding-x-24 margin-b-24 display-flex"},[_c('button',{staticClass:"display-flex align-items-center font-size-14 font-weight-700 flex-basis-50 justify-content-center text-default-on-dark border-none transition margin-r-12",class:[this.showSchedule ? 'active' : ''],on:{"click":function($event){return _vm.toggleSchedule()}}},[_vm._v(" Matchups ")]),_c('button',{staticClass:"display-flex align-items-center font-size-14 font-weight-700 flex-basis-50 justify-content-center text-default-on-dark border-none transition margin-l-12",class:[this.showSchedule ? '' : 'active'],on:{"click":function($event){return _vm.toggleSchedule()}}},[_vm._v(" Standings ")])]),(this.showSchedule)?_c('div',{staticClass:"s-tournament-mobile-schedule width-100 bp-540:padding-x-24"},[(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].rounds.length
						)?_c('TournamentSchedule',{attrs:{"details":_vm.details}}):_vm._e(),(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].rounds.length <
									1
						)?_c('div',{staticClass:"s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"},[_c('p',[_vm._v("Matchups to be determined after registration")])]):_vm._e()],1):_vm._e(),(!this.showSchedule)?_c('div',{staticClass:"s-tournament-mobile-standings width-100 padding-x-24"},[(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].standings.length
						)?_c('TournamentStandings',{attrs:{"details":_vm.details}}):_vm._e(),(
							_vm.details.phases &&
								_vm.details.phases[0].groups.length &&
								_vm.details.phases[0].groups[0].standings
									.length < 1
						)?_c('div',{staticClass:"s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"},[_c('p',[_vm._v("Rankings to be determined after gameplay")])]):_vm._e()],1):_vm._e()])]):_vm._e()],1):_c('div',{staticClass:"loading-indicator"},[_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--small"}),_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--large"}),_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--small"})])])}
var staticRenderFns = []

export { render, staticRenderFns }