<template>
	<div>
		<section class="component c-tournamentMode">
			<div class="c-tournamentMode__content display-flex">
				<div class="c-tournamentMode__left">
					<header
						class="c-tournamentMode__header s-common__header large"
					>
						<h2>
							Champions
							<div>Series</div>
						</h2>
					</header>
					<div class="c-tournamentMode__about">
						<header
							class="c-tournamentMode__about--heading s-common__header nounder"
						>
							<h2>LevelNext Champions Series</h2>
						</header>
						<div class="c-tournamentMode__about--content">
							<div class="c-tournamentMode__about--description">
								The only officially licensed, national college
								esports league where all students have the
								chance to represent their school and compete
								against other universities for the opportunity
								to win $150,000 in cash prizes.
							</div>
						</div>
					</div>
				</div>
				<div class="c-tournamentMode__right">
					<div class="c-tournamentMode__sponsor">
						<!-- <a
							target="_blank"
							href="https://www.ea.com/games/madden-nfl/madden-nfl-21"
						>
							<img
								src="../../assets/EAS_MaddenNFL21_Primary_1Color_Horizontal_White.png"
								alt="Rocket League Logo"
							/>
						</a> -->
					</div>
					<div class="c-tournamentMode__image">
						<div class="c-tournamentMode__image-inside">
							<img
								src="../../assets/LevelNext_homeofcollegiateesports Black.png"
								alt="LevelNext Spring Logo"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="c-tournamentMode__howitworks">
				<ul class="c-howitworks reset-list display-flex">
					<li class="c-howitworks__item first">
						<span
							>How it
							<div>Works</div></span
						>
					</li>
					<li class="c-howitworks__item">
						<div class="c-howitworks__icons">
							<i class="sf-user-identity"></i>
							<i class="sf-user-identity"></i>
						</div>
						<div class="c-howitworks__headline">
							Intra-School Sourced Competitions
						</div>
						<div class="c-howitworks__description">
							Students compete to represent their schools
						</div>
					</li>
					<li class="c-howitworks__item ">
						<div class="c-howitworks__icons">
							<i class="sf-university"></i>
							<i class="sf-university"></i>
						</div>
						<div class="c-howitworks__headline">
							Inter-School Competitions
						</div>
						<div class="c-howitworks__description">
							Conference and Regional Play
						</div>
					</li>
					<li class="c-howitworks__item ">
						<div class="c-howitworks__icons">
							<i class="sf-trophy"></i>
						</div>
						<div class="c-howitworks__headline">
							National
							<div>Tournaments</div>
						</div>
						<div class="c-howitworks__description">
							Bracket-styled Tournaments to Crown National
							Champions
						</div>
					</li>
				</ul>
			</div>
			<div class="c-tournamentMode__left second">
				<div class="c-tournamentMode__about">
					<header
						class="c-tournamentMode__about--heading s-common__header nounder"
					>
						<h2>Key Benefits</h2>
					</header>
					<div class="c-tournamentMode__about--content">
						<div class="c-tournamentMode__about--description">
							The LevelNext Champions Series is the first ever
							collegiate esports competition to feature official
							school marks and promotional assets, while enabling
							all full-time students the opportunity to represent
							their college in esports.
						</div>
					</div>
				</div>
			</div>
			<div class="c-tournamentMode__howitworks">
				<ul class="c-howitworks reset-list display-flex">
					<li class="c-howitworks__item">
						<div class="c-howitworks__icons">
							<i class=" sf-dollar"></i>
							<i class=" sf-dollar"></i>
						</div>
						<div class="c-howitworks__headline">
							Large Cash<br />Prize Pools
						</div>
						<div class="c-howitworks__description">
							$125,000 up for grabs in the LevelNext Rocket League
							Spring Showcase
						</div>
					</li>
					<li class="c-howitworks__item">
						<div class="c-howitworks__icons">
							<i class="sf-user-identity"></i>
							<i class="sf-university"></i>
						</div>
						<div class="c-howitworks__headline">
							Open to All Full-time College Students
						</div>
						<div class="c-howitworks__description">
							Join the fun and compete to win!
						</div>
					</li>
					<li class="c-howitworks__item ">
						<div class="c-howitworks__icons">
							<i class="sf-user-identity"></i>
							<span
								><img
									src="../../assets/svg/EA-sports-logo-icon.svg"
									alt="EA Logo"
								/>
							</span>
						</div>
						<div class="c-howitworks__headline">
							Exclusive Partnerships with Publishers
						</div>
						<div class="c-howitworks__description">
							Psyonix and EA exclusive partners with more on the
							way
						</div>
					</li>
					<li class="c-howitworks__item ">
						<div class="c-howitworks__icons">
							<i class="sf-calendar-minimal"></i>
							<i class="sf-grid"></i>
						</div>
						<div class="c-howitworks__headline">
							National
							<div>Tournaments</div>
						</div>
						<div class="c-howitworks__description">
							Large scale tournaments to crown National Champions
						</div>
					</li>
				</ul>
			</div>
			<!-- <a
				class="c-tournamentMode__getinvolved display-flex align-items-center justify-content-center width-100 border-none font-size-16 font-weight-700 text-default-on-dark cursor-pointer text-decoration-none"
				href="/teams"
			>
				<span>Register Now</span>
			</a> -->
		</section>
	</div>
</template>

<script>
export default {
	name: 'TournamentMode',
	components: {},
	data: function() {
		return {
			tournaments: null,
			recentstreams: null,
		};
	},
	computed: {},
	mounted() {},
};
</script>

<style scoped lang="scss">
.c-howitworks {
	border: 1px solid #222120;

	&__icons {
		display: flex;
		align-items: center;
		justify-content: center;
		i,
		span {
			font-size: 34px;
			margin: 0 12px;
			&:before {
				margin: 0;
			}
		}
	}
	&__description {
		margin-top: 24px;
		border-top: 1px solid #222120;
		padding: 20px 0;
		color: #fefefe;
		font-size: 1rem;
		font-weight: 400;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1.5rem;
		text-align: center;
	}
	&__headline {
		margin-top: 24px;
		color: #ffffff;
		font-size: 1.313rem;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1.641rem;
		text-align: center;
	}
	&__item {
		text-align: center;
		padding: 40px;
		min-height: 297px;
		border-right: 1px solid #222120;
		flex: 1 11 25%;
		&:last-child {
			border-right: none;
		}
		&.first {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		> span {
			font-size: 2rem;
			font-weight: 600;
			font-style: normal;
			letter-spacing: 0.31rem;
			line-height: 2.5rem;
			text-align: center;
			text-transform: uppercase;
		}
	}

	@media screen and (max-width: 1120px) {
		flex-wrap: wrap;
		&__item {
			flex: 0 1 50%;
			min-height: 297px;
			border-right: none;
			&:nth-child(odd) {
				border-right: 1px solid #222120;
			}
			&:nth-child(1),
			&:nth-child(2) {
				border-bottom: 1px solid #222120;
			}
		}
	}
	@media screen and (max-width: 640px) {
		border: none !important;
		margin: 0 -24px;
		&__icons {
			i {
				font-size: 36px;
			}
		}
		&__item {
			padding: 40px 44px 60px 44px;
			flex: 0 1 100%;
			border: none !important;
			border-bottom: 1px solid #222120 !important;
			min-height: auto;
			&.first {
				padding: 0 0 24px 0;
				min-height: auto;
			}
			&:last-child {
				border-bottom: none !important;
			}
		}
		&__description {
			padding-bottom: 0;
		}
	}
}
.c-tournamentMode {
	color: #fff;
	width: 100%;
	&.challenger {
		margin-top: 240px;
	}
	&__schedule {
		margin-top: 60px;
		&--heading {
			h2 {
			}
			> div {
				margin: 0 0 36px 0;
			}
		}
		&--section {
			padding: 0 24px;
			border-right: 1px solid #222120;
			&:last-child {
				border-right: none;
			}
		}
		&--content {
			margin: 0 -24px;
			&-item {
				padding: 24px 0;
				border-bottom: 1px solid #222120;
				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
					border-bottom: 0;
				}
			}
		}
	}
	&__getinvolved {
		background-color: $red;
		margin-top: 60px;
		height: 60px;
		&:hover {
			transition: background-color 0.25s;
			background-color: darken($red, 8%);
			&:after {
				transform: rotate(0);
			}
		}
		&:after {
			content: '\e026';
			display: inline-block;
			font-family: 'sidearm_font_v2';
			font-weight: 400;
			transform: rotate(45deg);
			font-size: 20px;
			margin-left: 8px;
			transition: transform 0.25s;
		}
	}
	&__header {
		margin-bottom: 24px;
	}
	&__right {
		width: 324px;
		flex-shrink: 0;
		margin-left: 24px;
	}
	&__left {
		max-width: 744px;
		margin-right: auto;
		&.second {
			margin-top: 60px;
		}
	}
	&__howitworks {
		margin-top: 60px;
	}
	&__sponsor {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 60px;
		img,
		svg {
			width: 100%;
			height: auto;
			max-width: 300px;
		}
	}
	&__content {
	}
	&__about {
		&--description {
			color: #e8e8e8;
			font-size: 1.125rem;
			font-weight: 400;
			font-style: normal;
			letter-spacing: normal;
			line-height: 1.688rem;
			text-align: left;
		}
	}
	&__image {
		width: 324px;
		height: 324px;
		position: relative;
		&:after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 317px;
			height: 324px;
			background: url('../../assets/svg/tournament-thumbnail-rectangles.svg');
		}
		&-inside {
			top: 0;
			z-index: 2;
			left: 0;
			position: absolute;
			width: 292px;
			height: 292px;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 12px;
		}
		svg {
			max-width: 240px;
			width: 100%;
		}
	}
	@media screen and (max-width: 860px) {
		&__howitworks {
			margin-top: 36px;
		}
		&__right {
			width: 277px;
			flex-shrink: 0;
		}

		&__getinvolved {
			margin-top: 36px;
		}
		&__left.second {
			margin-top: 48px;
			.s-common__header.nounder h2 {
				font-size: 3rem;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				line-height: 3.75rem;
				text-align: left;
				padding-bottom: 24px;
			}
		}
		.s-common__header.nounder h2 {
			font-size: 1.875rem;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 2.344rem;
			text-align: left;
			padding-bottom: 12px;
		}
		.s-common__header.large h2 {
			font-size: 3rem;
			font-weight: 700;
			font-style: normal;
			letter-spacing: normal;
			line-height: 3.75rem;
			text-align: left;
		}
		&__about {
			&--description {
				font-size: 1.125rem;
				font-weight: 400;
				font-style: normal;
				letter-spacing: normal;
				line-height: 1.688rem;
				text-align: left;
			}
		}
		&__image {
			width: 272px;
			height: 272px;
			&:after {
				width: 266px;
				height: 272px;
				background-size: contain;
			}
			&-inside {
				width: 245px;
				height: 245px;
			}
		}
		&__schedule {
			&--section {
				padding: 0 12px;
			}
			&--content {
				margin: 0 -12px;
				&-item {
					padding: 12px 0;
				}
			}
			&--heading {
				&.s-common__header.nounder h2 {
					font-size: 3rem;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: 3.75rem;
					text-align: left;
					padding-bottom: 24px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		&__schedule {
			&--section {
				padding: 0;
				border: none;
				&:last-child {
					.c-tournamentMode__schedule--content-item:last-child {
						border-bottom: 0 !important;
						padding-bottom: 0 !important;
					}
				}
			}
			&--content {
				margin: 0;
				display: block !important;
				&-item {
					padding: 24px 0 !important;
					border-bottom: 1px solid #222120 !important;
				}
			}
			&--heading {
				&.s-common__header.nounder h2 {
					font-size: 3rem;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: 3.75rem;
					text-align: left;
					padding-bottom: 24px;
				}
			}
		}
	}
	@media screen and (max-width: 640px) {
		&__left.second {
			margin-top: 36px;
			.s-common__header.nounder h2 {
				font-size: 1.875rem;
				font-weight: 700;
				font-style: normal;
				letter-spacing: normal;
				line-height: 2.344rem;
				text-align: left;
			}
		}
		&__schedule {
			&--heading {
				&.s-common__header.nounder h2 {
					font-size: 1.875rem;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: 2.344rem;
					text-align: left;
				}
				> div {
					margin: 0;
					font-size: 1.125rem;
					font-weight: 400;
					font-style: normal;
					letter-spacing: normal;
					line-height: 1.688rem;
					text-align: left;
				}
			}
		}
		&__content {
			display: block !important;
		}
		&__right {
			width: auto;
			margin: 0;
		}
		&__sponsor {
			margin: 24px auto 0 auto;
			justify-content: center;
		}
		&__image {
			margin: 24px auto 0 auto;
			width: 324px;
			height: 324px;
			position: relative;
			&:after {
				content: '';
				display: block;
				position: absolute;
				right: 0;
				bottom: 0;
				width: 317px;
				height: 324px;
				//background: url('../assets/svg/tournament-thumbnail-rectangles.svg');
			}
			&-inside {
				top: 0;
				z-index: 2;
				left: 0;
				position: absolute;
				width: 292px;
				height: 292px;
				background: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 12px;
			}
			svg {
				max-width: 240px;
				width: 100%;
			}
		}
	}
}
</style>
