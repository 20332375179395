<template>
	<div
		class="c-stories bg-color-black padding-x-0 padding-b-64 bp-768:padding-x-24 bp-1024:padding-b-120 position-relative"
		v-if="stories.length"
	>
		<div class="c-stories__inner">
			<h2
				class="font-size-30 font-weight-700 padding-b-12 position-relative margin-b-32 margin-l-24 bp-768:margin-l-0 bp-768:margin-b-48"
			>
				Latest News
			</h2>
			<div
				class="c-stories__item position-relative display-flex padding-b-24 flex-wrap-wrap bp-768:align-items-center bp-1280:align-items-flex-start bp-768:flex-wrap-no-wrap"
				v-for="item in stories"
				:key="item.content_title"
			>
				<a
					class="c-stories__image flex-basis-100 bp-768:flex-basis-50 flex-item-1 overflow-hidden"
					:href="go_to_url(item)"
					:aria-label="item.content_title"
				>
					<div class="aspect-ratio-16-9 position-relative">
						<img
							class="object-fit-cover transition position-absolute width-100 height-100 top-0 left-0"
							:src="$root._data.imageBasePath + item.image_source"
							:alt="'Image for ' + item.content_title"
						/>
					</div>
				</a>
				<div
					class="c-stories__details flex-basis-100 flex-item-1 padding-24 bp-768:flex-basis-50 bp-768:padding-24 bp-1280:padding-48"
				>
					<a
						:href="go_to_url(item)"
						class="c-stories__title display-block font-weight-700 text-default-on-dark text-decoration-none font-size-21 margin-b-12 transition"
					>
						{{ item.content_title }}
					</a>
					<div class="text-muted-on-dark font-size-14 margin-b-12">
						<span>{{ parseDate(item.content_date) }}</span>
					</div>
					<div class="font-weight-700">
						<span
							class="display-inline-block padding-x-16 padding-y-8 border-dark"
							>{{ item.sport.title }}</span
						>
					</div>
				</div>
			</div>
		</div>
		<div
			class="c-stories__cta padding-x-24 bp-768:padding-x-0 bp-768:padding-t-24"
		>
			<button
				@click="load_more"
				class="c-stories__more display-flex align-items-center justify-content-center width-100 border-none font-size-16 font-weight-700 text-default-on-dark cursor-pointer"
				id="load-more"
			>
				More News
			</button>
			<div
				class="c-stories__message text-muted-on-dark text-align-center transition"
				id="loaded-message"
			>
				All stories have been loaded.
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '/src/helper.js';
import moment from 'moment';

export default {
	name: 'StoriesList',
	props: {
		count: {
			type: [Number, String],
			default: 4,
		},
	},
	data: () => ({
		stories: [],
		storyStart: 0,
	}),
	methods: {
		parseDate(date) {
			return moment(date).format('MMMM DD, YYYY');
		},
		go_to_url(story) {
			return story.redirect_absolute_url
				? story.redirect_absolute_url
				: this.stripCharacters(story.content_url);
		},
		getLatestStories() {
			const latest_request_options = {
				type: 'stories',
				count: this.count,
				start: this.storyStart,
			};

			get(
				'/services/adaptive_components.ashx',
				latest_request_options,
			).then(response => {
				if (response.length) {
					this.stories = [...this.stories, ...response];
				} else {
					document.getElementById('load-more').remove();
					document
						.getElementById('loaded-message')
						.classList.add('show');
					return;
				}
			});
		},
		load_more() {
			this.storyStart += 4;
			this.getLatestStories();
		},
		stripCharacters(url) {
			url = url
				.replace('https://levelnext.sidearmsports.com', '')
				.replace('.aspx', '');
			return url;
		},
	},
	mounted() {
		this.getLatestStories();
	},
};
</script>
<style lang="scss">
.c-stories {
	color: #fff;
	//background-image: url('../../assets/news_bg_texture.jpg');
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	background-color: #000;
	&:after {
		content: '';
		display: block;
		height: 4px;
		width: 100%;
		background: linear-gradient(
			90deg,
			#000000 0%,
			#cc0000 51.56%,
			#000000 100%
		);
		position: absolute;
		bottom: -2px;
		left: 0;
	}
	&__inner {
		max-width: 1464px;
		margin: auto;
	}
	h2 {
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	&__cta {
		max-width: 1464px;
		margin: auto;
	}
	&__more {
		background-color: $red;
		height: 48px;
		&:hover {
			transition: background-color 0.25s;
			background-color: darken($red, 8%);
			&:after {
				transform: rotate(90deg);
			}
		}
		&:after {
			content: '\e026';
			display: inline-block;
			font-family: 'sidearm_font_v2';
			font-weight: 400;
			transform: rotate(45deg);
			font-size: 20px;
			margin-left: 8px;
			transition: transform 0.25s;
		}
	}
	#loaded-message {
		display: none;
		&.show {
			display: block;
		}
	}
	&__item {
		&:not(:last-of-type) {
			margin-bottom: 24px;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				display: block;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(
					to right,
					rgba(204, 0, 0, 0),
					rgba(204, 0, 0, 1),
					rgba(204, 0, 0, 0)
				);
			}
		}
	}
	&__image {
		&:hover,
		&:focus {
			img {
				transform: scale(1.1);
			}
		}
	}
	&__title {
		&:hover,
		&:focus {
			color: $red;
		}
	}
	@media (max-width: 1023px) {
		background-image: none;
		&:after {
			display: none;
		}
	}
}
</style>
