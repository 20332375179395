import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { get } from '../helper';

const store = new Vuex.Store({
	state: {
		mainNavItems: [],
	},
	mutations: {
		setNavItems(state, payload) {
			state.mainNavItems = payload;
		},
	},
	actions: {
		getNavItems() {
			const request_options = {
				type: 'main-navigation',
			};
			get('/services/adaptive_components.ashx', request_options).then(
				res => {
					store.commit('setNavItems', res);
				},
			);
		},
	},
});

export default store;
