<template>
	<div id="register-pitch-container">
		<div id="register-pitch">
			<img
				:src="
					require(`@/assets/champion-series/madden nat champ white.png`)
				"
				alt="LevelNext Madden National Championship Logo"
			/>
			<h1 class="font-size-48">
				LevelNext Madden National Championship
			</h1>
			<p class="text-muted-on-dark font-size-18">
				The LevelNext Madden National Championship is now open to all full-time college students. Competition kicks off September 17th and features a prize pool of $150,000.
			</p>
			<a
				color="secondary"
				href="https://www.twitch.tv/levelnextgg"
				class="reset mb-2 sa-block-parallax__link margin-t-12 bp-540:margin-t-0 display-flex align-items-center font-size-14 bp-768:font-size-16 font-weight-700"
			>
				Tune In
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'RegisterPitch',
};
</script>

<style scoped lang="scss">
#register-pitch-container {
	background-image: url('../../assets/champion-series/bg_1920x1080.jpeg');
	background-color: #111;
	background-size: cover;

	display: flex;
	flex-direction: column;
	align-items: center;

	#register-pitch {
		display: flex;
		flex-direction: column;
		align-items: center;

		max-width: 800px;
		padding: 120px 0px;
		img {
			max-height: 500px;
			padding: 48px 24px 24px 24px;
			@media screen and (max-width: 768px) {
				// padding: 32px 24px; Big_12_CS_Logo.png already has its own padding, unlike past images.
				max-width: 80%;
			}
		}
		h1 {
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 0px 24px;
			margin: 0px;
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
			}
			@media screen and (max-width: 768px) {
				font-size: 30px;
				border: 1px solid black;
			}
		}
		p {
			text-align: center;
			padding: 24px;
		}
		a {
			padding: 9px 118px;
			@media screen and (max-width: 768px) {
				font-size: 14px;
				border: 1px solid black;
			}
			@media screen and (max-width: 360px) {
				padding: 9px 90px;
			}
		}
		@media screen and (max-width: 768px) {
			padding: 100px 0px;
		}
		@media screen and (max-width: 450px) {
			padding: 80px 0px 50px 0px;
		}
	}
	&:after {
		content: '';
		display: block;
		height: 2px;
		width: 100%;
		background: linear-gradient(
			90deg,
			#000000 0%,
			#cc0000 51.56%,
			#000000 100%
		);
	}
}

.sa-block-parallax {
	height: 100vh;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.75)
		);
	}
	&__row {
		&:after {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}
	&__wrapper {
		max-width: 1512px;
	}
	&__details {
	}
	&__text {
		z-index: 2;
		&-upper {
			font-weight: 900;
			max-width: 700px;
		}
		&-lower {
			font-weight: 400;
		}
	}
	&__link {
		background-color: $green;
		border-radius: 192px;
		position: relative;
		height: 36px;
		border-radius: 18px;
		text-decoration: none;
		transition: 0.25s;
		// &::before {
		// 	content: '';
		// 	height: 4px;
		// 	width: 4px;
		// 	background-color: $red;
		// 	position: absolute;
		// 	left: -12px;
		// 	top: 50%;
		// 	transform: translateY(-50%);
		// }
		&:hover {
			background: darken($green, 8%);
		}
	}
	@media (max-width: 1023px) {
		height: auto;
		padding-bottom: 56.25%;
		margin-top: 96px;
		&__row {
			&:after {
				height: 2px;
			}
		}
	}
	@media (max-width: 539px) {
		height: calc(100vh - 96px);
		padding-bottom: 0;
		&__logo {
			height: 65% !important;
		}
		&__wrapper {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 30%;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 1)
				);
			}
		}
		&__link {
			&:before {
				display: none;
			}
		}
	}
}
</style>
