<template>
	<div class="c-story">
		<div
			class="c-story__header position-relative z-index-1 bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
			v-bind:style="{
				backgroundImage:
					'url(http://levelnext.sidearmsports.com' +
					story.image_source +
					')',
			}"
		>
			<div
				class="c-story__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-story__header__title z-index-2 padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<h2 class="font-size-30 bp-1024:font-size-48 margin-0">
						{{ story.title }}
					</h2>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				>
					<span>{{ parseDate(story.date) }}</span>
					<span v-if="story.date && story.category"
						>&nbsp;|&nbsp;</span
					>
					<span>{{ story.category }}</span>
				</div>
			</div>
		</div>
		<div
			class="c-story__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<div
				v-if="story.links && story.links.length"
				class="c-story__content__links margin-b-12"
			>
				<h3 class="hide">Related Links</h3>
				<ul class="reset-list display-flex flex-wrap-wrap">
					<li
						v-for="item in story.links"
						:key="item.id"
						class="flex-basis-50 bp-768:flex-basis-33 bp-1024:flex-basis-auto"
					>
						<a
							class="display-flex padding-x-12 align-items-center justify-content-center margin-r-12 margin-b-12 text-default-on-dark transition text-decoration-none font-size-14 font-weight-700"
							:href="item.link_url"
						>
							{{ item.link_text }}
						</a>
					</li>
				</ul>
			</div>
			<div
				class="c-story__content__share display-flex flex-wrap-wrap position-relative flex-align-center text-align-center padding-b-12 bp-1024:padding-t-24 bp-1024:flex-direction-column bp-1024:position-absolute"
			>
				<h3
					class="margin-0 font-size-12 font-weight-700 margin-b-24 flex-basis-100 text-align-left bp-1024:flex-basis-auto bp-1024:text-align-center"
				>
					Share:
				</h3>
				<a
					class="twitter-link display-flex align-items-center justify-content-center text-default-on-dark text-decoration-none margin-b-12 flex-justify-center flex-grow-1 bp-1024:flex-basis-auto transition"
					:href="
						`http://twitter.com/share?text=` +
							story.title +
							`&url=` +
							this.currentUrl
					"
					>Twitter</a
				>
				<a
					class="facebook-link display-flex align-items-center justify-content-center text-default-on-dark text-decoration-none margin-b-12 flex-justify-center flex-grow-1 bp-1024:flex-basis-auto transition"
					:href="
						`https://www.facebook.com/sharer.php?u=` +
							this.currentUrl +
							`&p[title]=` +
							story.title
					"
					>Facebook</a
				>
			</div>
			<div
				class="c-story__content__wrapper"
				v-html="contentVideoWrap"
			></div>
		</div>
	</div>
</template>
<script>
import { get } from '/src/helper.js';
import map from 'lodash/map';
import moment from 'moment';

export default {
	props: {
		year: {
			type: [Number, String],
			default: null,
		},
		month: {
			type: [Number, String],
			default: null,
		},
		day: {
			type: [Number, String],
			default: null,
		},
		filename: {
			type: String,
			default: null,
		},
	},
	data: () => ({
		story: {},
		currentUrl: window.location.href,
	}),
	computed: {
		contentVideoWrap() {
			const el = document.createElement('div');
			el.innerHTML = this.story.fulltext_raw;
			el.querySelectorAll('iframe').forEach(iframe => {
				// Wrapper logic adapted from
				// https://stackoverflow.com/a/57377341/395910
				const wrapper = document.createElement('div');
				wrapper.className = 'c-story__content__video-wrapper';
				iframe.parentNode.insertBefore(wrapper, iframe);
				wrapper.appendChild(iframe);
			});
			return el.innerHTML;
		},
	},
	methods: {
		parseDate(date) {
			return moment(date).format('M/D/YYYY hh:mm:ss A');
		},
		getStory(date, filename) {
			let request_options = {
				date: date,
				filename: filename,
				format: 'json',
			};
			get('/services/stories_xml.aspx', request_options)
				.then(response => {
					let _story = null;
					if (response.stories && response.stories.length) {
						if (response.stories[0].redirect_absolute_url) {
							window.location.replace(
								response.stories[0].redirect_absolute_url,
							);
						} else {
							_story = response.stories[0];
							_story.date = _story.pubDate;
							_story.image_source = null;

							let removeFrom = 'sidearmsports.com';
							if (_story.enclosure && _story.enclosure.url) {
								_story.image_source = _story.enclosure.url.substring(
									_story.enclosure.url.indexOf(removeFrom) +
										removeFrom.length,
								);
							}
							_story.image_alt_text =
								_story.enclosure && _story.enclosure.caption
									? _story.enclosure.caption
									: _story.title;

							_story.links = _story.related_links.length
								? map(_story.related_links, link => {
										return {
											link_text: link.text,
											link_url: link.url,
										};
								  })
								: null;
						}
					}
					this.story = _story;

					/* this.setMetaSidearm(this.story.title, [
						{
							name: 'description',
							content: this.story.description,
						},
					]); */
				})
				.catch(e => {
					console.log(e);
					//this.$router.replace({ name: 'Home' });
				});
		},
	},
	mounted() {
		let date = '' + this.month + '/' + this.day + '/' + this.year;
		let filename = this.filename.replace('.aspx', '');
		this.getStory(date, filename);
	},
};
</script>
<style lang="scss" scoped>
.c-story {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
				position: absolute;
				left: 0;
				bottom: 0;
				transition: 0.25s;
			}
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
