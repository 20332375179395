<template>
	<div>
		<component :is="type"> </component>
	</div>
</template>

<script>
import LeftHero from '@/components/Common/Hero/LeftHero.vue';
import CenterHero from '@/components/Common/Hero/CenterHero.vue';
import AmbassadorHero from '@/components/Common/Hero/AmbassadorHero.vue';
import RegistionHero from '@/components/Common/Hero/RegistrationHero.vue';
import ChampionSeriesHero from '@/components/Common/Hero/ChampionSeriesHero.vue';
export default {
	name: 'Hero',
	props: {
		type: {
			type: String,
			required: false,
			default: 'left',
		},
	},
	components: {
		left: LeftHero,
		center: CenterHero,
		ambassador: AmbassadorHero,
		registration: RegistionHero,
		championseries: ChampionSeriesHero,
	},
};
</script>
