<template>
	<nav
		class="component c-navigation--main c-navigation display-flex justify-content-end align-items-center position-fixed z-index-1000 top-0 left-0 width-100 "
	>
		<h2 class="accessible-hide">Main Navigation</h2>
		<div class="c-navigation__logo">
			<router-link to="/">
				<img
					src="@/assets/svg/logo_level-next_primary_white.svg"
					alt="LevelNext logo"
				/>
			</router-link>
		</div>
		<ul class="c-navigation__list display-flex">
			<li
				class="c-navigation__list--item d-flex"
				v-for="item in navItems"
				:key="'level-1-' + item.id"
			>
				<router-link
					class="flex-item-1 display-flex align-items-center justify-content-center position-relative"
					:to="item.url"
					:aria-label="'Go to ' + item.title + ' page'"
				>
					{{ item.title }}
				</router-link>
			</li>
			<a
				class="c-navigation__getinvolved"
				href="https://www.twitch.tv/levelnextgg"
				target="_blank"
			>
				<span>Tune In</span>
				<i class="sf-external-link-empty"></i>
			</a>
		</ul>
		<div class="c-navigation__icons">
			<ul class="reset-list display-flex align-items-center">
				<li class="c-navigation__icon c-navigation__icon-twitch">
					<a
						href="https://www.twitch.tv/levelnextgg"
						aria-label="Twitch"
					>
						<i class="sf-twitch"></i>
					</a>
				</li>
				<li class="c-navigation__icon c-navigation__icon-discord">
					<a
						href="https://discord.gg/93EkDQr6C7"
						aria-label="Discord"
					>
						<svg
							id="Capa_1"
							viewBox="0 0 40 40"
							width="28px"
							height="28px"
							fill="white"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M22.26 14.39l-.19.22a9.16 9.16 0 013.42 1.74A11.22 11.22 0 0021.36 15a11.59 11.59 0 00-2.77 0h-.24a10.36 10.36 0 00-3.11.86c-.51.24-.81.4-.81.4a9.27 9.27 0 013.57-1.7l-.14-.17a6.77 6.77 0 00-3.85 1.44 18.34 18.34 0 00-2 8 5 5 0 004.19 2.08l.91-1.14a4.27 4.27 0 01-2.4-1.62s.14.1.39.24h.05l.13.07a7.31 7.31 0 001 .46 11.3 11.3 0 002 .59 9.61 9.61 0 003.56 0 8.85 8.85 0 002-.59 7.92 7.92 0 001.58-.81A4.32 4.32 0 0123 24.8c.41.52.9 1.11.9 1.11a5 5 0 004.2-2.08 18.41 18.41 0 00-2-8 6.81 6.81 0 00-3.84-1.44zm-4.74 8.16a1.53 1.53 0 111.4-1.55 1.47 1.47 0 01-1.4 1.55zm5 0a1.53 1.53 0 010-3 1.53 1.53 0 010 3z"
								fill="none"
							/>
							<path
								d="M29.19 8H10.81A2.82 2.82 0 008 10.83v18.34A2.82 2.82 0 0010.81 32h15.55l-.72-2.54 1.75 1.83 1.66 1.53L32 35.43v-24.6A2.82 2.82 0 0029.19 8zm-5.3 17.91s-.49-.59-.9-1.11a4.32 4.32 0 002.48-1.63 7.92 7.92 0 01-1.58.81 8.85 8.85 0 01-2 .59 9.61 9.61 0 01-3.56 0 11.3 11.3 0 01-2-.59 7.31 7.31 0 01-1-.46l-.13-.07h-.05c-.25-.14-.39-.24-.39-.24a4.27 4.27 0 002.4 1.62l-.91 1.14a5 5 0 01-4.19-2.08 18.34 18.34 0 012-8 6.77 6.77 0 013.85-1.44l.14.17a9.27 9.27 0 00-3.61 1.79s.3-.16.81-.4a10.36 10.36 0 013.11-.86h.24a11.59 11.59 0 012.77 0 11.22 11.22 0 014.13 1.31 9.16 9.16 0 00-3.42-1.74l.19-.22a6.81 6.81 0 013.86 1.44 18.41 18.41 0 012 8 5 5 0 01-4.24 1.97z"
							/>
							<ellipse cx="22.52" cy="21.03" rx="1.4" ry="1.52" />
							<path
								d="M17.52 19.51a1.53 1.53 0 101.4 1.49 1.46 1.46 0 00-1.4-1.49z"
							/>
						</svg>
					</a>
				</li>
				<li class="c-navigation__icon c-navigation__icon-twitter">
					<a
						href="https://twitter.com/LEVELNEXTGG"
						aria-label="Twitter"
					>
						<span class="sf-twitter"></span>
					</a>
				</li>
				<li class="c-navigation__icon c-navigation__icon-facebook">
					<a
						href="https://www.facebook.com/LevelNextGG/"
						aria-label="Facebook"
					>
						<span class="sf-facebook-2"></span>
					</a>
				</li>
				<li class="c-navigation__icon c-navigation__icon-instagram">
					<a
						href="https://www.instagram.com/LEVELNEXTGG/"
						aria-label="Instagram"
					>
						<span class="sf-instagram"></span>
					</a>
				</li>
				<li class="c-navigation__icon c-navigation__icon-youtube">
					<a
						href="https://www.youtube.com/channel/UCtTuP-KPLL8QDYPK16E8G0w?guided_help_flow=3"
						aria-label="Youtube"
					>
						<span class="sf-youtube-play"></span>
					</a>
				</li>
			</ul>
		</div>
		<div class="c-navigation__mobile ">
			<div class="display-flex align-items-center">
				<a class="c-navigation__getinvolved mobile" href="/teams">
					<span>Register <span>Today</span></span>
					<span class="sf-external-link-empty"></span>
				</a>
				<button
					type="button"
					class="c-navigation__toggle"
					:class="this.toggled ? 'active' : ''"
					v-on:click="toggle_menu()"
				>
					<div class="c-navigation__toggle-icon">
						<i v-if="toggled" class="sf-close-minimal"></i>
						<i v-if="!toggled" class="sf-menu-minimal"></i>
					</div>
					<div class="c-navigation__toggle-text hide">
						Menu
					</div>
				</button>
			</div>
			<div
				class="c-navigation__mobile--dropdown"
				:class="this.toggled ? 'active' : ''"
			>
				<ul class="c-navigation__mobile--list ">
					<li
						class="c-navigation__mobile--list--item "
						v-for="item in navItems"
						:key="'level-1-' + item.id"
					>
						<router-link
							class="flex-item-1 display-flex align-items-center justify-content-center position-relative"
							:to="item.url"
							:aria-label="'Go to ' + item.title + ' page'"
						>
							{{ item.title }}
						</router-link>
					</li>
					<a
						class="c-navigation__getinvolved"
						href="https://rallycry.gg/levelnext/competition/534/info"
						target="_blank"
					>
						<span>Register Now</span>
						<i class="sf-arrows-thick-right"></i>
					</a>
				</ul>
				<div class="c-navigation__mobile--icons">
					<ul class="reset-list display-flex align-items-center">
						<li
							class="c-navigation__icon c-navigation__icon-twitch"
						>
							<a
								href="https://www.twitch.tv/levelnextgg"
								aria-label="Twitch"
							>
								<i class="sf-twitch"></i>
							</a>
						</li>
						<li
							class="c-navigation__icon c-navigation__icon-discord"
						>
							<a
								href="https://discord.gg/93EkDQr6C7"
								aria-label="Discord"
							>
								<svg
									id="Capa_1"
									viewBox="0 0 40 40"
									width="28px"
									height="28px"
									fill="white"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M22.26 14.39l-.19.22a9.16 9.16 0 013.42 1.74A11.22 11.22 0 0021.36 15a11.59 11.59 0 00-2.77 0h-.24a10.36 10.36 0 00-3.11.86c-.51.24-.81.4-.81.4a9.27 9.27 0 013.57-1.7l-.14-.17a6.77 6.77 0 00-3.85 1.44 18.34 18.34 0 00-2 8 5 5 0 004.19 2.08l.91-1.14a4.27 4.27 0 01-2.4-1.62s.14.1.39.24h.05l.13.07a7.31 7.31 0 001 .46 11.3 11.3 0 002 .59 9.61 9.61 0 003.56 0 8.85 8.85 0 002-.59 7.92 7.92 0 001.58-.81A4.32 4.32 0 0123 24.8c.41.52.9 1.11.9 1.11a5 5 0 004.2-2.08 18.41 18.41 0 00-2-8 6.81 6.81 0 00-3.84-1.44zm-4.74 8.16a1.53 1.53 0 111.4-1.55 1.47 1.47 0 01-1.4 1.55zm5 0a1.53 1.53 0 010-3 1.53 1.53 0 010 3z"
										fill="none"
									/>
									<path
										d="M29.19 8H10.81A2.82 2.82 0 008 10.83v18.34A2.82 2.82 0 0010.81 32h15.55l-.72-2.54 1.75 1.83 1.66 1.53L32 35.43v-24.6A2.82 2.82 0 0029.19 8zm-5.3 17.91s-.49-.59-.9-1.11a4.32 4.32 0 002.48-1.63 7.92 7.92 0 01-1.58.81 8.85 8.85 0 01-2 .59 9.61 9.61 0 01-3.56 0 11.3 11.3 0 01-2-.59 7.31 7.31 0 01-1-.46l-.13-.07h-.05c-.25-.14-.39-.24-.39-.24a4.27 4.27 0 002.4 1.62l-.91 1.14a5 5 0 01-4.19-2.08 18.34 18.34 0 012-8 6.77 6.77 0 013.85-1.44l.14.17a9.27 9.27 0 00-3.61 1.79s.3-.16.81-.4a10.36 10.36 0 013.11-.86h.24a11.59 11.59 0 012.77 0 11.22 11.22 0 014.13 1.31 9.16 9.16 0 00-3.42-1.74l.19-.22a6.81 6.81 0 013.86 1.44 18.41 18.41 0 012 8 5 5 0 01-4.24 1.97z"
									/>
									<ellipse
										cx="22.52"
										cy="21.03"
										rx="1.4"
										ry="1.52"
									/>
									<path
										d="M17.52 19.51a1.53 1.53 0 101.4 1.49 1.46 1.46 0 00-1.4-1.49z"
									/>
								</svg>
							</a>
						</li>
						<li
							class="c-navigation__icon c-navigation__icon-twitter"
						>
							<a
								href="https://twitter.com/LEVELNEXTGG"
								aria-label="Twitter"
							>
								<span class="sf-twitter"></span>
							</a>
						</li>
						<li
							class="c-navigation__icon c-navigation__icon-facebook"
						>
							<a
								href="https://www.facebook.com/LevelNextGG/"
								aria-label="Facebook"
							>
								<span class="sf-facebook-2"></span>
							</a>
						</li>
						<li
							class="c-navigation__icon c-navigation__icon-instagram"
						>
							<a
								href="https://www.instagram.com/LEVELNEXTGG/"
								aria-label="Instagram"
							>
								<span class="sf-instagram"></span>
							</a>
						</li>
						<li
							class="c-navigation__icon c-navigation__icon-youtube"
						>
							<a
								href="https://www.youtube.com/channel/UCtTuP-KPLL8QDYPK16E8G0w?guided_help_flow=3"
								aria-label="Youtube"
							>
								<span class="sf-youtube-play"></span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</nav>
</template>
<script>
import { get } from '../helper';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'MainNavigation',
	data() {
		return {
			toggled: false,
			ishidden: false,
		};
	},
	methods: {
		getNavigation() {
			const request_options = {
				type: 'main-navigation',
			};
			get('/services/adaptive_components.ashx', request_options).then(
				response => {
					this.navItems = response;
				},
			);
		},
		toggle_menu() {
			this.toggled = !this.toggled;
		},
		hideLink() {
			this.ishidden = moment().isBefore('2021-05-14');
		},
	},
	computed: {
		...mapState(['mainNavItems']),
		navItems() {
			return this.mainNavItems;
		},
		hideRegisterLink: function() {
			return this.ishidden;
		},
	},
	created() {
		this.hideLink();
	},
	watch: {
		$route() {
			this.toggle_menu();
		},
	},
};
</script>

<style scoped lang="scss">
.c-navigation {
	padding: 0 60px;
	background: rgba(0, 0, 0, 0.8);
	height: 96px;
	z-index: 9000 !important;
	box-shadow: inset 0 -1px 0 0 #222120;
	&__getinvolved {
		height: 60px;
		padding: 0 18px 0 24px;
		align-self: center;
		margin: 0 18px;
		background: $green;
		border-radius: 999px;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		font-size: 1rem;
		font-weight: 700;
		font-style: normal;
		letter-spacing: normal;
		line-height: 1.5rem;
		text-align: left;
		transition: 0.25s;
		&:hover {
			transition: 0.25s;
			background: darken($green, 8%);
		}
		i {
			font-size: 22px;
			margin-left: 8px;
		}
		&.mobile {
			display: none;
		}
	}
	&__icons {
		padding-left: 61px;
		position: relative;
		&:before {
			content: '';
			display: block;
			height: 25px;
			width: 1px;
			opacity: 0.2;
			background: #fff;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -12px;
		}
		a {
			display: block;
			color: #fff;
			font-size: 22px;
			transition: 0.25s;
			&:hover {
				color: $red;
				transition: 0.25s;
				svg {
					fill: $red;
				}
			}
		}
		i:before {
			margin: 0;
		}
	}
	&__icon {
		margin: 0 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		&:first-child {
			margin-left: 0;
		}
		&-discord {
			height: 28px;
			width: 28px;
			a {
				height: 28px;
				width: 28px;
				margin-bottom: 4px;
			}
			svg {
				max-width: 28px;
				color: $red;
			}
		}
	}
	&__logo {
		max-width: 141px;
		margin-right: auto;
	}
	&__list {
		margin: 0;
		list-style: none;
		padding: 0 30px;
		&--item {
			margin: 0 24px;
			@include nova(700);
			font-style: normal;
			font-size: 18px;
			line-height: 27px;
			a {
				height: 96px;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.35s;
				overflow: hidden;
				-webkit-transform: perspective(1px) translateZ(0);
				transform: perspective(1px) translateZ(0);
				color: #fff;
				text-decoration: none;
				&:hover,
				&.router-link-exact-active {
					&:after {
						left: 0;
						right: 0;
						-webkit-transition-property: left, right;
						transition-property: left, right;
						-webkit-transition-duration: 0.3s;
						transition-duration: 0.3s;
						-webkit-transition-timing-function: ease-out;
						transition-timing-function: ease-out;
					}
				}
				&:after {
					content: '';
					display: block;
					height: 4px;
					z-index: 2;
					background: $red;
					position: absolute;
					bottom: 0;
					position: absolute;
					left: 51%;
					right: 51%;
					-webkit-transition-property: left, right;
					transition-property: left, right;
					-webkit-transition-duration: 0.3s;
					transition-duration: 0.3s;
					-webkit-transition-timing-function: ease-out;
					transition-timing-function: ease-out;
				}
			}
		}
	}
	&__mobile {
		display: none;
		&--dropdown {
			width: 400px;
			background: #000;
			position: absolute;
			top: 96px;
			right: 0;
			transform: translateX(-9999px);
			&.active {
				transform: translateX(0);
			}
		}
		&--icons {
			ul {
				padding: 0 12px;
				margin: 0;
				list-style: none;
			}
			li {
				padding: 40px 8px;
				flex: 1;
				margin: 0;
				font-size: 24px;
				a {
					color: #fff;
					transition: 0.25s;
					&:hover {
						color: $red;
						transition: 0.25s;
						svg {
							transition: 0.25s;
							fill: $red;
						}
					}
				}
			}
		}
		&--list {
			margin: 0;
			padding: 0;
			list-style: none;
			&--item {
				height: 96px;
				display: flex;
				border-bottom: 1px solid #222120;
				a {
					padding: 24px;
					flex: 1;
					align-items: center;
					display: flex;
					color: #ffffff;
					font-size: 1.563rem;
					font-weight: 700;
					font-style: normal;
					letter-spacing: normal;
					line-height: 1.953rem;
					text-align: left;
					text-decoration: none;
					position: relative;
					transition: 0.25s;
					&:hover {
						transition: 0.25s;
						color: $red;
						&:after {
							color: $red;
							transition: 0.25s;
						}
					}
					&:after {
						transition: 0.25s;
						content: '\e006';
						display: block;
						position: absolute;
						top: 50%;
						font-size: 34px;
						font-weight: 400;
						transform: translateY(-50%);
						color: #fff;
						font-family: sidearm_font_v2;
						right: 24px;
						z-index: 2;
					}
				}
			}
		}
	}
	&__toggle {
		cursor: pointer;
		border: none;
		background: none;
		font-size: 24px;
		padding: 0;
		width: 50px;
		i {
			width: 50px;
			color: #fff;
			font-size: 50px;
		}
	}
	@media screen and (max-width: 1550px) {
		&__list--item,
		&__getinvolved {
			margin: 0 12px;
		}
		&__list {
			padding: 0 8px 0 18px;
		}
		&__icons {
			padding-left: 24px;
		}
	}
	@media screen and (max-width: 1365px) {
		&__list {
			display: none !important;
		}
		&__icons {
			display: none !important;
		}
		&__mobile {
			display: block;
			&--list {
				&--item {
					a {
						font-size: 1.25rem;
						justify-content: flex-start;
						text-align: left;
					}
				}
			}
		}
		&__getinvolved {
			border-radius: 0;
			width: 100%;
			margin-left: 0;
			height: 96px;
			font-size: 1.25rem;
			position: relative;
			justify-content: flex-start;
			i {
				position: absolute;
				right: 24px;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
				font-size: 24px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		padding: 0 24px;
		background: #000;
		&__mobile {
			&--icons {
				border-bottom: 1px solid #222120;
			}
			&--dropdown {
				display: flex;
				flex-direction: column;
				height: calc(100vh - 96px);
				width: 100%;
			}
			&--list {
				&--item {
					a {
						font-size: 1.25rem;
						justify-content: flex-start;
						text-align: left;
					}
				}
			}
		}
		&__getinvolved {
			padding: 0 24px;
			justify-content: flex-start;
			text-align: left;
		}
	}
	@media screen and (max-width: 450px) {
		&__getinvolved {
			&.mobile {
				width: auto;
				padding: 0 24px;
				i {
					display: none;
				}
				> span > span {
					display: none;
				}
			}
		}
		&__toggle {
			i:before {
				margin: 0;
			}
		}
	}
}
</style>
