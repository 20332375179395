<template>
	<div id="app">
		<header>
			<MainNavigation />
		</header>
		<router-view />
		<FAQ />
		<MainFooter />
	</div>
</template>

<script>
// @ is an alias to /src
import MainNavigation from '@/components/MainNavigation';
import FAQ from '@/components/Common/Faqs/FAQ';
import MainFooter from '@/components/MainFooter';
import { mapActions } from 'vuex';
export default {
	name: 'App',
	components: {
		MainNavigation,
		FAQ,
		MainFooter,
	},
	data() {
		return {
			isAmbassador: false,
		};
	},
	methods: {
		...mapActions(['getNavItems']),
	},
	created() {
		this.getNavItems();
		this.isAmbassador = this.$route.matched.some(
			({ name }) => name == 'Ambassador',
		);
	},
};
</script>

<style lang="scss">
@import '../src/styles/app.scss';

:root {
	/* Background Colors */
	--color-primary: #cc0000;
	--color-secondary: #e29846;
	--color-default-theme-page: #fff;
	--color-default-theme-background: #fff;
	--color-default-theme-surface: #f9f9f9;
	--color-default-theme-surface-alt: #e0e0e0;

	/* Text Colors */
	--color-text-on-primary: #fff;
	--color-text-on-secondary: #fff;
	--color-default-theme-default-text: #000;
	--color-default-theme-safe-text: #000;
	--color-default-theme-muted-text: #666;
	--color-default-theme-brand-text: #cc0000;
	--color-default-theme-link-text: #cc0000;

	/* Stroke Colors */
	--color-default-theme-stroke: #222120;

	/* Other Colors */
	--color-dark: #000;
	--color-text-on-dark: #fff;
	--color-danger: #f60039;
	--color-text-on-danger: #fff;
	--swiper-navigation-color: #095ba5;

	/* Layout Variables */
	--header-height: 70px;
	--full-header-height: var(--header-height);
	--grid-width: 1416px;

	@media screen and (min-width: 1024px) {
		--header-height: 84px;
	}
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/*** Loading Dots ***/
@keyframes pulse {
	0% {
		opacity: 0.5;
		transform: scale(1);
	}
	50% {
		transform: scale(1.5);
		opacity: 1;
	}
	100% {
		opacity: 0.5;
		transform: scale(1);
	}
}
@keyframes pulse-reverse {
	0% {
		opacity: 1;
		transform: scale(1.5);
	}
	50% {
		transform: scale(1);
		opacity: 0.5;
	}
	100% {
		opacity: 1;
		transform: scale(1.5);
	}
}
.loading-indicator {
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	&__dot {
		border-radius: 100%;
		width: 12px;
		height: 12px;
		background: #f60039;
		margin: 0 12px;
		&--small {
			animation: pulse 0.6s ease-in-out infinite;
		}
		&--large {
			animation: pulse-reverse 0.6s ease-in-out infinite;
		}
	}
}

html {
	&.navigation-loaded {
		.loading-indicator {
			opacity: 0;
			pointer-events: none;
			z-index: -1;
		}
	}
}
</style>
