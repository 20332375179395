<template>
	<div>
		<div class="tabs c-tabs width-100">
			<ul class="reset-list display-flex width-100">
				<li
					v-for="(tab, index) in tabs"
					:key="index"
					:class="{ 'is-active': tab.isActive }"
					class="c-tabs__item flex-item-1"
				>
					<a
						:href="tab.href"
						@click="selectTab(tab)"
						class="display-flex flex-item-1 align-items-center justify-content-center border-y text-decoration-none text-default-on-dark font-size-24 position-relative"
						>{{ tab.name }}</a
					>
				</li>
			</ul>
		</div>

		<div class="tabs-details width-100">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return { tabs: [] };
	},

	created() {
		this.tabs = this.$children;
	},
	methods: {
		selectTab(selectedTab) {
			this.tabs.forEach(tab => {
				tab.isActive = tab.name == selectedTab.name;
			});
		},
	},
};
</script>

<style scoped lang="scss">
.c-tabs {
	&__item {
		a {
			height: 84px;
			border-color: $border-gray;
			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
				background: $red;
				transition: 0.25s;
				opacity: 0;
			}
			&:hover,
			&:focus {
				&:after {
					height: 1px;
					width: 100%;
					opacity: 1;
				}
			}
		}
		&.is-active {
			a {
				&:after {
					height: 4px;
					opacity: 1;
					width: 100%;
				}
			}
		}
	}
}
</style>
