import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
import iFrameResize from 'iframe-resizer/js/iframeResizer';

Vue.directive('resize', {
	bind: function(el, { value = {} }) {
		el.addEventListener('load', () => iFrameResize(value, el));
	},
});

new Vue({
	render: h => h(App),
	data() {
		return {
			imageBasePath: '//levelnext.sidearmsports.com',
		};
	},
	router,
	store,
}).$mount('#app');
