<template>
	<div>
		<div class="tabs c-tabs width-100 position-relative">
			<ul
				class="reset-list width-100 overflow-x-auto padding-b-12 display-none bp-1024:display-flex"
			>
				<li
					v-for="(tab, index) in tabs"
					:key="index"
					:class="{ 'is-active': tab.isActive }"
					class="c-tabs__item flex-item-1"
				>
					<a
						:href="tab.href"
						@click="selectTab(tab)"
						class="display-flex flex-item-1 align-items-center justify-content-center text-decoration-none text-default-on-dark font-size-12 position-relative margin-r-56 text-align-center transition"
						>{{ tab.name }}</a
					>
				</li>
			</ul>
			<div
				class="c-tabs__mobile display-block padding-x-24 bp-540:padding-x-0 bp-1024:display-none position-relative"
			>
				<select
					class="c-tabs__mobile__select width-100 position-relative border text-default-on-dark padding-x-12 font-size-18"
					name="rounds"
					@change="selectTabList($event)"
				>
					<option
						v-for="(tab, index) in tabs"
						:key="index"
						:value="tab.name"
						class="display-flex flex-item-1 align-items-center justify-content-center text-decoration-none font-size-12 position-relative margin-r-56 text-align-center transition font-size-18"
						>{{ tab.name }}</option
					>
				</select>
			</div>
		</div>

		<div class="tabs-details width-100">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return { tabs: [] };
	},
	methods: {
		selectTab(selectedTab) {
			this.tabs.forEach(tab => {
				tab.isActive = tab.name == selectedTab.name;
			});
		},
		selectTabList(event) {
			this.selectedTab = event.target.value;
			this.tabs.forEach(tab => {
				tab.isActive = tab.name == this.selectedTab;
			});
		},
	},
	mounted() {
		this.tabs = this.$children;
		this.selectTab(this.tabs[0]);
		this.selectTabList(this.tabs[0]);
	},
};
</script>

<style scoped lang="scss">
.c-tabs {
	&:after {
		content: '';
		position: absolute;
		width: 12px;
		height: calc(100% - 8px);
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 1)
		);
		right: 0;
		top: 0;
	}
	&__item {
		a {
			height: 60px;
			color: $muted-text;
			white-space: nowrap;
			&:after {
				content: '';
				position: absolute;
				width: 0;
				height: 0;
				left: 50%;
				transform: translateX(-50%);
				bottom: 0;
				background: $red;
				transition: 0.25s;
				opacity: 0;
			}
			&:hover,
			&:focus {
				color: $anchor;
			}
		}
		&.is-active {
			a {
				color: $white;
			}
		}
	}
	&__mobile {
		&__select {
			height: 48px;
			background-color: $black;
			-webkit-appearance: none;
		}
		&:after {
			content: '\e033';
			font-family: 'sidearm_font_v2';
			font-size: 16px;
			position: absolute;
			right: 24px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 6px;
		border-left: 1px solid $border-gray;
	}
	::-webkit-scrollbar-track {
		background-color: $dark-gray;
		border-radius: 0;
	}
	::-webkit-scrollbar-thumb {
		background-color: $red;
		border-radius: 0;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: darken($red, 5%);
	}
	@media (max-width: 1023px) {
		&:after {
			display: none;
		}
	}
	@media (max-width: 539px) {
		&__mobile {
			&:after {
				right: 48px;
			}
		}
	}
}
</style>
