<template>
	<div v-if="slideshow" class="sa-block-parallax position-relative">
		<div
			class="sa-block-parallax__row display-flex flex-direction-column align-items-center justify-content-flex-end bp-1024:justify-content-center position-absolute left-0 top-0 width-100 height-100"
		>
			<img
				class="sa-block-parallax__logo position-absolute width-100 height-100 left-0 top-0 object-fit-cover"
				:src="
					'http://levelnext.sidearmsports.com' + slideshowImage.image
				"
				:alt="slideshowImage.alt"
			/>
			<div
				class="sa-block-parallax__wrapper position-relative width-100 flex-basis-50 bp-540:flex-basis-100 display-flex flex-direction-column justify-content-flex-start  bp-540:justify-content-flex-end align-items-flex-start margin-0 bp-540:margin-auto padding-x-24 padding-y-40 bp-1024:padding-y-64"
			>
				<div
					class="sa-block-parallax__text text-uppercase text-center mb-8"
				>
					<div
						class="sa-block-parallax__text-upper line-height-125 display-block text-on-dark font-size-24 bp-540:font-size-30 bp-1024:font-size-36"
					>
						{{ slideshow.title }}
					</div>
					<div
						class="sa-block-parallax__details display-flex padding-t-32 bp-540:padding-t-40 flex-direction-column align-items-flex-start bp-540:align-items-center bp-540:flex-direction-row"
					>
						<div
							class="display-block sa-block-parallax__text-lower font-size-16"
						>
							{{ slideshow.subtitle }}
						</div>
						<a
							color="secondary"
							class="reset mb-2 sa-block-parallax__link padding-x-12 margin-t-32 bp-540:margin-t-0 display-flex align-items-center bp-540:margin-l-24 font-size-14 font-weight-700"
							v-for="link in slideshow.links"
							:key="'slideshowlink' + link.link_id"
							:href="link.link_url"
						>
							{{ link.link_text }}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '../../helper';

export default {
	name: 'BlockSlideshowHero',
	data: () => ({
		slideshow: {
			primary_image: {
				images: [],
			},
		},
		slideshowImg: '',
	}),
	methods: {
		getSlideshow() {
			const request_options = {
				type: 'slideshow',
				sport_id: 0,
				extra: JSON.stringify({ slideshow_id: 2 }),
				count: 1,
			};

			get('/services/adaptive_components.ashx', request_options).then(
				slideshow => {
					if (slideshow && slideshow.length) {
						this.slideshow = slideshow[0];
						if (this.slideshow.primary_image.images.length > 0) {
							this.slideshowImg = this.slideshow.primary_image.images[0];
						}
					}
				},
			);
		},
	},
	computed: {
		slideshowImage: function() {
			return this.slideshowImg;
		},
	},
	created() {
		this.getSlideshow();
	},
};
</script>

<style lang="scss" scoped>
.sa-block-parallax {
	height: 100vh;
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.75)
		);
	}
	&__row {
		&:after {
			content: '';
			display: block;
			height: 2px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 1;
		}
	}
	&__wrapper {
		max-width: 1512px;
	}
	&__details {
	}
	&__text {
		z-index: 2;
		&-upper {
			font-weight: 900;
			max-width: 700px;
		}
		&-lower {
			font-weight: 400;
		}
	}
	&__link {
		background-color: $red;
		border-radius: 192px;
		position: relative;
		height: 36px;
		border-radius: 18px;
		text-decoration: none;
		transition: 0.25s;
		&::before {
			content: '';
			height: 4px;
			width: 4px;
			background-color: $red;
			position: absolute;
			left: -12px;
			top: 50%;
			transform: translateY(-50%);
		}
		&:hover {
			background-color: #a30000;
		}
	}
	@media (max-width: 1023px) {
		height: auto;
		padding-bottom: 56.25%;
		margin-top: 96px;
		&__row {
			&:after {
				height: 2px;
			}
		}
	}
	@media (max-width: 539px) {
		height: calc(100vh - 96px);
		padding-bottom: 0;
		&__logo {
			height: 65% !important;
		}
		&__wrapper {
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 30%;
				background-image: linear-gradient(
					to bottom,
					rgba(0, 0, 0, 0),
					rgba(0, 0, 0, 1)
				);
			}
		}
		&__link {
			&:before {
				display: none;
			}
		}
	}
}
</style>
