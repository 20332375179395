<template>
	<div class="c-recap">
		<div
			class="c-recap__header position-relative bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
			:style="{
				backgroundImage:
					'url(' +
					require('@/assets/2020-madden-recap/madden_header-image_1920x1080.jpg') +
					')',
			}"
		>
			<div
				class="c-recap__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-recap__header__title padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<video-modal
						:video="
							require('@/assets/2020-madden-recap/madden_sizzle_reel_video.mp4')
						"
						caption="LevelNext Madden Recap Video"
					></video-modal>
					<h1
						class="font-size-30 bp-1024:font-size-48 margin-0 bp-1024:max-width-50
"
					>
						2020 LevelNext® Madden National Championship Recap
					</h1>
					<div
						class="c-recap__header-meta display-flex align-items-center"
					>
						<span>Madden</span
						><span
							class="c-recap__header-meta-divider margin-x-12 position-relative"
						></span
						><span>$150,000 Prize Pool</span>
					</div>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				></div>
			</div>
		</div>
		<div
			class="c-recap__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<social-share></social-share>
			<div class="c-recap__content__wrapper">
				<h2 class="c-recap__title position-relative padding-b-12">
					Tournament Recap
				</h2>
				<p>
					Learfield and EA shook up collegiate esports space with
					their announcement of the inaugural LevelNext® Madden
					National Championship, an inter-collegiate tournament
					featuring EA's Madden NFL 21.
				</p>
				<p>
					The competition commenced in November 2020 with the
					intra-collegiate and inter-collegiate qualifiers, where 976
					players from all 50 states competed to represent their
					school on the national stage as one of the final players at
					124 universities.
				</p>
				<p>
					After the qualifiers, players were sorted into four Regional
					Divisions and Power 5 conferences. Three weeks of regular
					season play, packed with exciting rivalry action, culminated
					in the Conference and Regional Championships, where 14
					players earned their spots in the Championship. Among the
					14, conference-winners included the University of Oregon,
					Penn State University, and West Virginia University.
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2020-madden-recap/madden_conference-champs.png')
						"
						alt="Conference Champions"
					/>
				</p>

				<p>
					Players who achieved 2nd - 4th place in their Conference had
					an opportunity to redeem themselves and grab one of only two
					Championship spots in the Last Chance Qualifier (LCQ).
				</p>

				<p>
					Michael Spoto, representing the University of Albany coasted
					through his LCQ matches, securing his championship spot
					after his win against Weber State University 24-10.
				</p>

				<p>
					On the other side of the bracket, John “Jhenry” Pruette,
					representing the University of North Carolina in Charlotte,
					secured his championship spot after close games against the
					University of South Dakota and Boise State University.
				</p>

				<p>
					The Fall Champions series came to a head in the Championship
					with a 16-team single elimination bracket, meaning players
					only had one shot to secure the Championship title for their
					school.
				</p>

				<p>
					The competition narrowed until the final two competitors
					were left standing: Jonathan “Jwall” Wallack, representing
					Northeastern University, and Noah “NoahUpNxt” Johnson,
					representing the University of Maryland. The Finals were
					streamed live with appearances by Madden influencers, like
					Skimbo, TDBarrett, and Dubby, as well as NFL superstar wide
					receivers Calvin Ridley and Keenan Allen.
				</p>

				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32"
				>
					Bracket
				</h2>
				<p>
					Check out the final champions bracket to see how the action
					played out:
				</p>
				<p class="display-flex justify-content-center margin-t-24">
					<img
						:src="
							require('@/assets/2020-madden-recap/madden_bracket.png')
						"
						alt="Tournament Bracket"
					/>
				</p>
				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32
          "
				>
					Game Winner
				</h2>
				<p>
					It was an intense battle between the two Madden pros, with
					Maryland taking the first game and Northeastern the second.
				</p>
				<p>
					With the 3-game series all tied up, the final game came down
					to a field goal in the remaining seconds of the 4th quarter.
					Jwall broke the 17-17 tie and in turn secured himself
					$25,000 and the title of first ever LevelNext® Collegiate
					National Madden Champion.
				</p>
				<div class="c-recap__video margin-x-auto margin-t-24">
					<div class="c-recap__video-inside">
						<video
							ref="myvideo"
							:src="
								require('@/assets/2020-madden-recap/madden-recap_highlight.mp4')
							"
							title="LevelNext Madden Highlight Video"
						></video>
						<div v-if="!isPlaying" class="c-recap__video-overlay">
							<button class="c-recap__video-play" @click="play">
								<div>
									<svg
										width="45"
										height="46"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
											fill="currentcolor"
										/>
									</svg>
								</div>
								<span>Play</span>
							</button>
						</div>
					</div>
				</div>

				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32"
				>
					Prizes
				</h2>
				<p>
					Jwall wasn’t the only competitor walking away with cash in
					his pocket. The top 16 all walked away with $2,500+ each for
					a total of $100,000!
				</p>
				<p class="display-flex justify-content-center ">
					<img
						:src="
							require('@/assets/2020-madden-recap/madden_prize_breakdown.png')
						"
						alt="Prizes"
					/>
				</p>
				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32"
				>
					Twitter
				</h2>
				<p>
					The LevelNext® Fall Champions series also saw the
					first-of-its-kind Twitter Competition, where schools could
					earn money for their esports programs merely by tweeting.
					After 2 months of tweeting, the top 20 schools walked away
					with $50,000 to help grow their esports programs!
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2020-madden-recap/madden_twitter-competition.png')
						"
						alt="Twitter Prizes"
					/>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import SocialShare from '@/Social/SocialShare.vue';
import VideoModal from '../../Videos/VideoModal.vue';
export default {
	components: { SocialShare, VideoModal },
	name: 'MaddenLeagueRecap',
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-recap {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: none !important;
			}
		}
		&-meta {
			&-divider {
				&:after {
					content: '';
					background-color: $red;
					height: 4px;
					width: 4px;
					top: 0;
					left: 0;
					position: absolute;
				}
			}
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
				font-size: 21px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
			}
		}
	}
	&__title {
		font-size: 48px;
		line-height: 1;
		margin: 0;
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: 0.25s;
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	&__video {
		position: relative;
		max-width: 75%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
