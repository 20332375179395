<template>
	<div
		v-if="hasParticipants"
		class="row padding-y-64 padding-x-24 bp-768:padding-y-120"
	>
		<div class="c-tournament-info__inner width-100">
			<h2
				class="font-size-30 font-weight-700 position-relative margin-b-24 margin-l-0 bp-768:margin-b-32"
			>
				Participants
			</h2>

			<div v-if="hasSchools">
				<dl class="c-accordion border-t">
					<div
						v-for="(participant, index) in participants"
						:key="index"
						class="c-accordion__item"
					>
						<SchoolAccordion
							:teamname="participant.name"
						></SchoolAccordion>
					</div>
				</dl>
			</div>
			<div v-else>
				<dl class="c-accordion border-t">
					<div
						v-for="(participant, index) in participants"
						:key="index"
						class="c-accordion__item"
					>
						<ParticipantAccordion
							:teamname="participant.name"
							:members="participant.members"
						></ParticipantAccordion>
					</div>
				</dl>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '/src/helper.js';
import ParticipantAccordion from '/src/components/Tournament/ParticipantAccordion.vue';
import SchoolAccordion from './SchoolAccordion';
export default {
	name: 'Participants',
	props: {
		guid: {
			type: String,
			required: true,
		},
	},
	components: {
		ParticipantAccordion,
		SchoolAccordion,
	},
	data() {
		return {
			participants: [],
			hasSchools: false,
		};
	},
	computed: {
		hasParticipants() {
			return this.participants.length > 0;
		},
	},
	methods: {
		getParticipants() {
			get(
				`https://levelnext-api.sidearmsports.com/api/tournaments/detail/${this.guid}`,
			).then(response => {
				if (response.phases && response.phases[0].groups.length > 0) {
					this.participants = response.phases[0].groups[0].teams;
					this.hasSchools = true;
				} else {
					this.hasSchools = false;
					this.getOrganization();
				}
			});
		},
		getOrganization() {
			get(
				`https://levelnext-api.sidearmsports.com/api/tournaments/${this.guid}`,
			).then(response => {
				this.participants = response;
			});
		},
	},
	mounted() {
		this.getParticipants();
	},
};
</script>

<style lang="scss" scoped></style>
