<template>
	<div class="c-recap">
		<div
			class="c-recap__header position-relative bg-position-c bg-size-cover bg-attachment-scroll bp-1024:bg-attachment-fixed"
			:style="{
				backgroundImage:
					'url(' +
					require('@/assets/2021-rocket-league-recap/rocketleague_header-image_1920x1080.jpeg') +
					')',
			}"
		>
			<div
				class="c-recap__header__inner display-flex flex-direction-column justify-content-flex-end margin-auto padding-x-24 padding-b-36 bp-1024:padding-b-72"
			>
				<div
					class="c-recap__header__title padding-b-12 bp-1024:padding-b-24 position-relative line-height-125"
				>
					<video-modal
						:video="
							require('@/assets/2021-rocket-league-recap/rl_sizzle_reel.mp4')
						"
						caption="LevelNext Rocket League Recap Video"
					></video-modal>
					<h1
						class="font-size-30 bp-1024:font-size-48 margin-0 bp-1024:max-width-50
"
					>
						2021 LevelNext® Rocket League Spring Showcase Recap
					</h1>
					<div
						class="c-recap__header-meta display-flex align-items-center"
					>
						<span>Rocket League</span
						><span
							class="c-recap__header-meta-divider margin-x-12 position-relative"
						></span
						><span>$125,000 Prize Pool</span>
					</div>
				</div>
				<div
					class="text-muted-on-dark font-size-12 margin-b-12 z-index-2 font-weight-700 margin-t-12 bp-1024:font-weight-400 bp-1024:margin-t-24 bp-1024:font-size-14"
				></div>
			</div>
		</div>
		<div
			class="c-recap__content padding-x-24 margin-auto position-relative padding-t-24 padding-b-72 bp-1024:padding-t-80 bp-1024:padding-b-120"
		>
			<social-share></social-share>
			<div class="c-recap__content__wrapper">
				<h2 class="c-recap__title position-relative padding-b-12">
					Tournament Recap
				</h2>
				<p>
					276 teams competed but only one could be crowned as
					Champions of the LevelNext® Rocket League Spring Showcase.
					With $125,000, custom champions jerseys, and a crystal
					trophy on the line, the competition proved to be fierce.
				</p>
				<p>
					The LevelNext® Rocket League Spring Showcase kicked off May
					16, 2021 with the Group Stage presented by Unilever, where
					teams from all 50 states competed in their respective Power
					5 conferences and 11 regional divisions.
				</p>
				<p>
					The top 16 teams from each conference, including the likes
					of the University of Akron, Northwood University, and the
					University of North Texas, advanced straight to the
					championship bracket. Though it wasn't game over yet for the
					remaining group stage teams, as those who placed 2nd and 3rd
					had the opportunity to grind for one of 8 Championship spots
					in the Last Chance Qualifier (LCQ) presented by JBL Quantum.
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-rocket-league-recap/rocketleague_conference-champs.jpg')
						"
						alt="Conference Champions"
					/>
				</p>

				<p>
					The competition continued to slim in the LCQ, with the best
					teams rising above the rest. Among the few to clutch their
					championship spots were Pennsylvania State University (Penn
					State), the University of Missouri, and The Ohio State
					University (Ohio State).
				</p>

				<p>
					The LevelNext® Rocket League Spring Showcase culminated in a
					3-day, action-packed Championship with a 24-team best of
					seven double elimination bracket. As they fought to stay in
					the competition, a couple of teams faced major upsets,
					sending them down to the lower bracket or sending them home
					entirely.
				</p>

				<p>
					Akron made an undefeated run after beating Louisiana State
					University (LSU) 4-2. On the other side of the bracket,
					Northwood emerged victorious in the lower bracket, but not
					without a couple close calls in their matchups against Ohio
					State and LSU.
				</p>

				<p>
					Northwood met Akron in the Grand Finals and it was a clash
					of the titans as current and former College Rocket League
					Champions faced-off for their share of the $75,000 prize
					pool.
				</p>

				<p>
					The seven game series came to a close in overtime with Buzz
					scoring the series-winning goal and securing the
					Championship title for the University of Akron.
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-rocket-league-recap/rocketleague_champions.jpg')
						"
						alt="Rocket League Champions"
					/>
				</p>
				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32
          "
				>
					Game Winner
				</h2>
				<div class="c-recap__video margin-x-auto">
					<div class="c-recap__video-inside">
						<video
							ref="myvideo"
							:src="
								require('@/assets/2021-rocket-league-recap/rocket-league-recap_highlight.mp4')
							"
							title="LevelNext Rocket League Highlight Video"
						></video>
						<div v-if="!isPlaying" class="c-recap__video-overlay">
							<button class="c-recap__video-play" @click="play">
								<div>
									<svg
										width="45"
										height="46"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
											fill="currentcolor"
										/>
									</svg>
								</div>
								<span>Play</span>
							</button>
						</div>
					</div>
				</div>
				<h2
					class="c-recap__title position-relative padding-b-12 margin-t-32"
				>
					Bracket
				</h2>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-rocket-league-recap/rocketleague_bracket.jpg')
						"
						alt="Tournament Bracket"
					/>
				</p>
				<h2 class="c-recap__title position-relative padding-b-12">
					Prizes
				</h2>
				<p>
					While they didn’t go home with a trophy or custom champions
					jersey, the top 16 walked away with cash prizes.
				</p>
				<p class="display-flex justify-content-center">
					<img
						:src="
							require('@/assets/2021-rocket-league-recap/rocketleague_prizing.jpg')
						"
						alt="Prizes"
					/>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import SocialShare from '@/Social/SocialShare.vue';
import VideoModal from '../../Videos/VideoModal.vue';
export default {
	components: { SocialShare, VideoModal },
	name: 'RocketLeagueRecap',
	data: function() {
		return {
			isPlaying: false,
		};
	},
	methods: {
		play() {
			this.$refs.myvideo.play();
			this.$refs.myvideo.setAttribute('controls', 'controls');
			this.isPlaying = true;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-recap {
	&__header {
		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 100%;
			height: 4px;
			background-image: linear-gradient(to right, $black, $red, $black);
		}
		h2 {
			max-width: 970px;
		}
		&__inner {
			min-height: 696px;
			max-width: 1440px;
		}
		&__title {
			&:after {
				content: none !important;
			}
		}
		&-meta {
			&-divider {
				&:after {
					content: '';
					background-color: $red;
					height: 4px;
					width: 4px;
					top: 0;
					left: 0;
					position: absolute;
				}
			}
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
				font-size: 21px;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
			}
		}
	}
	&__title {
		font-size: 48px;
		line-height: 1;
		margin: 0;
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
			transition: 0.25s;
		}
	}
	&__content {
		max-width: 1440px;
		min-height: 350px;
		&__wrapper {
			line-height: 1.875;
			img {
				max-width: 100%;
				height: auto;
			}
			a {
				text-decoration: none;
				color: $anchor;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
		&__share {
			background-color: $button-gray;
			right: calc(100% + 24px);
			width: 82px;
			top: 80px;
			a {
				font-size: 0;
				&:before {
					font-family: 'sidearm_font_v2';
					font-size: 24px;
				}
				&.twitter-link {
					&:before {
						content: '\e035';
					}
				}
				&.instagram-link {
					&:before {
						content: '\e044';
					}
				}
				&.facebook-link {
					&:before {
						content: '\e205';
					}
				}
				&:hover,
				&:focus {
					color: $anchor;
				}
			}
		}
		&__links {
			ul {
				li {
					a {
						background-color: $button-gray;
						height: 40px;
						&:hover,
						&:focus {
							background-color: $red;
						}
						&:before {
							font-family: 'sidearm_font_v2';
							content: '\e199';
							font-size: 18px;
							font-weight: 400;
							margin-right: 4px;
						}
					}
				}
			}
		}
		&__video-wrapper {
			width: 100%;
			height: 0;
			padding-bottom: 56.25%;
			position: relative;
			iframe {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
			}
		}
	}
	&__video {
		position: relative;
		max-width: 75%;
		width: 100%;
		background: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0.85),
			rgba(0, 0, 0, 0.85)
		);

		box-sizing: border-box;
		&-logo {
			max-width: 350px;
		}
		&-play {
			border: none;
			padding: 0;
			color: #fff;
			background: none;
			@include nova(700);
			font-size: 21px;
			outline: none;
			transition: 0.25s;
			&:hover {
				transition: 0.25s;
				span {
					color: $red;
					transition: 0.25s;
				}
				div {
					transition: 0.25s;
					background: $red;
					border: 2px solid $red;
					svg {
						fill: $red;
					}
				}
			}
			span {
				cursor: pointer;
				transition: 0.25s;
			}
			div {
				display: flex;
				align-items: center;
				justify-content: center;
				transition: 0.25s;
				cursor: pointer;
				content: '';
				margin-bottom: 12px;
				background: rgba(0, 0, 0, 0.01);
				border-radius: 50%;
				border: 2px solid #ffffff;
				box-sizing: border-box;
				width: 72px;
				height: 72px;
				margin-top: 36px;
			}
		}
		&-overlay {
			border: 1px solid #ffffff;
			padding: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background: linear-gradient(
				0deg,
				rgba(0, 0, 0, 0.85),
				rgba(0, 0, 0, 0.85)
			);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 3;
		}
		&-inside {
			padding-bottom: 56.25%;
			height: 0;
			background: #000;
			position: relative;
			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
	@media (max-width: 1700px) {
		&__content {
			padding-left: 130px !important;
			&__share {
				right: auto;
				left: 24px;
			}
		}
	}
	@media (max-width: 1023px) {
		&__header {
			margin-top: 96px;
			&:after {
				display: none;
			}
			&__inner {
				min-height: 395px;
			}
		}
		&__content {
			padding-left: 24px !important;
			&__links {
				ul li:nth-last-of-type(3n) {
					a {
						margin-right: 0 !important;
					}
				}
			}
			&__share {
				background-color: transparent;
				top: 0;
				width: 100%;
				left: 0;
				a {
					height: 68px;
					background-color: $button-gray;
					&:hover,
					&:focus {
						color: $white;
						background-color: $red;
					}
				}
			}
		}
	}
	@media (max-width: 767px) {
		&__content {
			&__links {
				ul li {
					&:nth-last-of-type(even) {
						a {
							margin-left: 6px !important;
							margin-right: 0 !important;
						}
					}
					&:nth-last-of-type(odd) {
						a {
							margin-right: 6px !important;
						}
					}
				}
			}
		}
	}
}
</style>
