<template>
	<div
		class="c-share display-flex flex-wrap-wrap position-relative flex-align-center text-align-center padding-b-12 bp-1024:padding-t-24 bp-1024:flex-direction-column bp-1024:position-absolute"
	>
		<h3
			class="margin-0 font-size-12 font-weight-700 margin-b-24 flex-basis-100 text-align-left bp-1024:flex-basis-auto bp-1024:text-align-center"
		>
			Share:
		</h3>
		<a
			class="twitter-link display-flex align-items-center justify-content-center text-default-on-dark text-decoration-none margin-b-12 flex-justify-center flex-grow-1 bp-1024:flex-basis-auto transition"
			:href="
				`http://twitter.com/share?text=Tournament Recap&url=${currentUrl}`
			"
			>Twitter</a
		>
		<a
			class="facebook-link display-flex align-items-center justify-content-center text-default-on-dark text-decoration-none margin-b-12 flex-justify-center flex-grow-1 bp-1024:flex-basis-auto transition"
			:href="
				`https://www.facebook.com/sharer.php?u=${currentUrl}&p[title]=Tournament Recap`
			"
			>Facebook</a
		>
	</div>
</template>

<script>
export default {
	name: 'SocialShare',
	data() {
		return {
			currentUrl: '',
		};
	},
	mounted() {
		this.currentUrl = window.location.href;
		console.log(this.currentUrl);
	},
};
</script>

<style lang="scss" scoped>
.c-share {
	background-color: $button-gray;
	right: calc(100% + 24px);
	width: 82px;
	top: 80px;
	a {
		font-size: 0;
		&:before {
			font-family: 'sidearm_font_v2';
			font-size: 24px;
		}
		&.twitter-link {
			&:before {
				content: '\e035';
			}
		}
		&.instagram-link {
			&:before {
				content: '\e044';
			}
		}
		&.facebook-link {
			&:before {
				content: '\e205';
			}
		}
		&:hover,
		&:focus {
			color: $anchor;
		}
	}
	@media (max-width: 1700px) {
		right: auto;
		left: 24px;
	}
	@media (max-width: 1023px) {
		background-color: transparent;
		top: 0;
		width: 100%;
		left: 0;
		a {
			height: 68px;
			background-color: $button-gray;
			&:hover,
			&:focus {
				color: $white;
				background-color: $red;
			}
		}
	}
}
</style>
