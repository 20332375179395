<template>
	<div class="c-video-modal">
		<button
			class="c-video-modal__play display-flex align-items-center margin-b-16 bp-768:margin-b-24 bp-1024:margin-b-32"
			@click="openVideoModal"
		>
			<div>
				<svg
					width="45"
					height="46"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M14.539 9.875c-.735 0-1.414.586-1.414 1.413v23.034c0 .827.679 1.413 1.414 1.413.25 0 .506-.068.744-.217l18.428-11.517a1.41 1.41 0 000-2.392L15.283 10.092a1.393 1.393 0 00-.744-.217zm.461 2.25l17.087 10.68L15 33.485v-21.36z"
						fill="currentcolor"
					/>
				</svg>
			</div>
			<span
				class="margin-l-24 max-width-40 text-align-left"
				v-if="showText"
				>Play The Recap Video</span
			>
		</button>
		<transition name="fade">
			<div class="c-video-modal__container" v-if="dialog">
				<button
					type="button"
					class="c-video-modal__close display-flex align-items-center justify-content-center"
					@click="closeVideoModal"
					aria-label="Close video modal"
				>
					<span class="sf-close"></span>
				</button>
				<div class="c-video-modal__wrapper">
					<div class="c-video-modal__container-inner padding-24">
						<div class="c-video-modal__body">
							<video
								class="modal-body__video"
								ref="modalVideo"
								:src="video"
								controls
								title="LevelNext Rocket League Recap Video"
							></video>
						</div>

						<div class="c-video-modal__footer">
							{{ caption }}
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'VideoModal',
	props: {
		video: {
			type: String,
			required: true,
		},
		showText: {
			type: Boolean,
			default: true,
		},
		caption: {
			type: String,
		},
	},
	data() {
		return {
			dialog: false,
		};
	},
	methods: {
		openVideoModal() {
			this.dialog = true;
		},
		closeVideoModal() {
			this.dialog = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.c-video-modal {
	&__play {
		border: none;
		padding: 0;
		color: #fff;
		background: none;
		@include nova(700);
		font-size: 21px;
		outline: none;
		transition: 0.25s;
		&:hover {
			transition: 0.25s;
			span {
				color: $red;
				transition: 0.25s;
			}
			div {
				transition: 0.25s;
				background: $red;
				border: 2px solid $red;
				svg {
					fill: $red;
				}
			}
		}
		span {
			cursor: pointer;
			transition: 0.25s;
			font-size: 21px;
		}
		div {
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.25s;
			cursor: pointer;
			content: '';
			background: rgba(0, 0, 0, 0.01);
			border-radius: 50%;
			border: 2px solid #ffffff;
			box-sizing: border-box;
			width: 72px;
			height: 72px;
		}
	}
	&__container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 99999;
		display: flex;
		justify-content: center;
		align-items: center;
		&-inner {
			background: $black;
			color: $white;
		}
	}
	&__close {
		position: absolute;
		top: 24px;
		right: 24px;
		width: 40px;
		height: 40px;
		background: $black;
		color: $white;
		font-size: 20px;
		border-radius: 100%;
		border: 1px solid $white;
		cursor: pointer;
		transition: 0.25s;
		&:focus,
		&:hover {
			background: $red;
			color: $white;
		}
	}
}
.modal-body {
	&__video {
		width: 100%;
		max-width: 1080px;
	}
}
.fade-enter {
	opacity: 0;
}

.fade-leave-active {
	opacity: 0;
}

.fade-enter .modal-container,
.fade-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
</style>
