/* A copy-paste of TwitterCompetition.vue for [LEVELNEXT-33] with the url and name swapped out. */
/* eslint-disable no-unused-vars */
<template>
	<div class="p-twittercompetition">
		<iframe
			src="https://production.picogp.com/?cid=1261095&bid=1648713690950"
			frameborder="0"
			id="iFrameResizer0"
			allowtransparency="true"
			height="2000px"
			style="margin-top: 96px;"
			v-resize="{
				log: true,
				enablePublicMethods: true, // Enable methods within iframe hosted page
				heightCalculationMethod: 'max',
			}"
			width="100%"
		></iframe>
		<button
			class="pico-button"
			data-pico-id="1261095/4e7cd423_35ed_4a64_be62_716e73f1e56c"
			hidden
		></button>
	</div>
</template>

<script>
export default {
	name: 'fifatwittercompetition',
	components: {},
	data: function() {
		return {};
	},
	computed: {},
	method: {},
	mounted() {
		var script = document.createElement('script');
		script.src =
			'https://d33whie8snbuur.cloudfront.net/popupPlugin.min.js?cid=1261095';
		script.setAttribute('Settings', 1261095);
		document.body.appendChild(script);
	},
};
</script>
<style scoped lang="scss">
.p-twittercompetition {
	min-height: 100vh;
	@media screen and (max-width: 917px) {
		iframe {
			height: 2800px;
		}
	}
}
</style>
