<template>
	<div>
		<component :is="type"> </component>
	</div>
</template>

<script>
import RocketLeagueRecap2021 from '@/components/Recap/RocketLeagueRecap/RocketLeagueRecap2021.vue';
import MaddenRecap2020 from '@/components/Recap/MaddenNFLRecap/MaddenRecap2020.vue';
import MaddenRecap2021 from '@/components/Recap/MaddenNFLRecap/MaddenRecap2021.vue';
import RocketLeagueRecap2022 from '@/components/Recap/RocketLeagueRecap/RocketLeagueRecap2022.vue';
import FifaRecap2022 from '@/components/Recap/FifaRecap/FifaRecap2022.vue';

export default {
	name: 'Recap',
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	components: {
		rocketleague2021: RocketLeagueRecap2021,
		rocketleague2022: RocketLeagueRecap2022,
		madden2020: MaddenRecap2020,
		madden2021: MaddenRecap2021,
		fifa2022: FifaRecap2022,
	},
};
</script>
