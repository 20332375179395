<template>
	<div>
		<block-slideshow-hero />
		<video-slider />
		<div
			class="s-stories display-flex flex-wrap-wrap bp-1024:flex-wrap-nowrap bp-1280:padding-t-0 bp-1440:padding-t-120"
		>
			<div class="s-stories__main col-12-of-12 bp-1024:col-9-of-12">
				<StoriesList />
			</div>
			<div class="s-stories__sidebar col-12-of-12 bp-1024:col-3-of-12">
				<StoriesTrending />
			</div>
		</div>
		<div class="s-home-hero__cs-ad">
			<element-ad name="sidearm-dfp-1" />
		</div>
		<block-slideshow-homepage />
		<Mission />
	</div>
</template>

<script>
import BlockSlideshowHomepage from '../components/Slideshow/BlockSlideshowHomepage';
import StoriesList from '../components/Stories/StoriesList';
import StoriesTrending from '../components/Stories/StoriesTrending';
import VideoSlider from '@/components/Videos/VideoSlider.vue';
import Mission from '@/components/Mission.vue';
import BlockSlideshowHero from '../components/Slideshow/BlockSlideshowHero.vue';
import ElementAd from '../components/Elements/ads/ElementAd';

export default {
	name: 'Home',
	components: {
		BlockSlideshowHomepage,
		StoriesList,
		StoriesTrending,
		VideoSlider,
		Mission,
		BlockSlideshowHero,
		ElementAd,
	},
};
</script>

<style lang="scss">
.s-stories {
	max-width: 1440px;
	margin: auto;
	&__sidebar {
		min-width: 360px;
	}
	@media (max-width: 1139px) {
		padding-top: 64px;
	}
}
</style>
