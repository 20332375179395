<template>
	<div class="c-tournament-standings padding-b-120">
		<h3
			class="font-size-24 font-weight-700 padding-b-24 margin-b-12 border-b margin-t-24 display-none bp-1024:display-block"
		>
			Standings
		</h3>
		<table class="c-tournament-standings__table width-100">
			<thead>
				<tr class="font-size-12">
					<th class="font-weight-400 text-align-center">Rank</th>
					<th class="hide">Team Name</th>
					<th class="font-weight-400 text-align-center">W</th>
					<th class="font-weight-400 text-align-center">L</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="item in orderedStandings"
					:key="item.id"
					class="font-size-18"
				>
					<td class="text-align-center padding-x-8">
						{{ item.rank }}
					</td>
					<td class="width-100 padding-x-8">
						{{ locateTeamName(item.teamId) }}
					</td>
					<td class="text-align-center padding-x-12">
						{{ item.matchWins }}
					</td>
					<td class="text-align-center padding-x-12">
						{{ item.matchLosses }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	name: 'TournamentStandings',
	props: {
		details: {
			type: Object,
			required: true,
		},
	},
	computed: {
		orderedStandings: function() {
			return _.orderBy(
				this.details.phases[0].groups[0].standings,
				['rank', 'matchWins'],
				['asc', 'desc'],
			);
		},
	},
	methods: {
		locateTeamName(ID) {
			return _.filter(this.details.phases[0].groups[0].teams, {
				teamId: ID,
			})[0].name;
		},
	},
};
</script>

<style lang="scss">
.c-tournament-standings {
	&__table {
		th,
		td {
			height: 60px;
		}
	}
}
</style>
