<template>
	<dl class="c-accordion" role="presentation">
		<AccordionItem
			v-for="item in content"
			:multiple="multiple"
			:item="item"
			:groupId="groupId"
			:key="item.id"
		>
		</AccordionItem>
	</dl>
</template>

<script>
import AccordionItem from '../Accordion/AccordionItem.vue';
export default {
	props: {
		content: {
			type: Array,
			required: true,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		AccordionItem,
	},
	data() {
		return {
			groupId: null,
		};
	},
	mounted() {
		this.groupId = this.$el.id;
	},
};
</script>

<style lang="scss"></style>
