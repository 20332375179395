<template>
	<div
		:id="groupId + '-' + item.id"
		class="c-accordion__item"
		:class="{ 'is-active': item.active }"
	>
		<dt class="c-accordion__item-title">
			<button @click="toggle" class="c-accordion__item-trigger">
				<h4 class="c-accordion__item-title-text">{{ item.title }}</h4>
				<span class="c-accordion__item-trigger-icon">
					<i class="sf-plus-minimal"></i>
				</span>
			</button>
		</dt>
		<transition
			name="c-accordion__item"
			@enter="startTransition"
			@after-enter="endTransition"
			@before-leave="startTransition"
			@after-leave="endTransition"
		>
			<dd v-if="item.active" class="c-accordion__item-details">
				<div
					v-html="item.details"
					class="c-accordion__item-details-inner"
				></div>
			</dd>
		</transition></div
></template>

<script>
export default {
	props: ['item', 'multiple', 'groupId'],
	methods: {
		toggle(event) {
			if (this.multiple) this.item.active = !this.item.active;
			else {
				this.$parent.$children.forEach(item => {
					if (
						item.$el.id ===
						event.currentTarget.parentElement.parentElement.id
					)
						item.item.active = !item.item.active;
					else item.item.active = false;
				});
			}
		},

		startTransition(el) {
			el.style.height = el.scrollHeight + 'px';
		},

		endTransition(el) {
			el.style.height = '';
		},
	},
};
</script>

<style scoped lang="scss"></style>
