import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Overview from '../views/Overview.vue';
import ChampionSeries from '../views/ChampionSeries.vue';
import Tournament from '../views/Tournament.vue';
import Teams from '../views/Teams.vue';
import Story from '../views/Story.vue';
import TwitterCompetition from '../views/TwitterCompetition.vue';
import FifaTwitterCompetition from '../views/FifaTwitterCompetition.vue';
import PressRelease from '../views/PressRelease.vue';
import Ambassador from '../views/Ambassador.vue';
import Recap from '../views/Recap.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/overview',
		name: 'Overview',
		component: Overview,
	},
	{
		path: '/tournament/:guid',
		name: 'Tournament',
		component: Tournament,
		props: true,
	},
	// {
	// 	path:'/challenger-series',
	// 	name: 'ChallengerSeries',
	// 	component: ChallengerSeries,
	// },
	{
		path: '/champions',
		name: 'ChampionSeries',
		component: ChampionSeries,
	},
	{
		path: '/merch',
		name: 'Merch',
		beforeEnter() {
			window.location.href = 'http://levelnext.mybrightsites.com/';
		},
	},
	{
		path: '/teams',
		name: 'Teams',
		component: Teams,
	},
	{
		path: '/news/:year/:month/:day/:filename',
		props: true,
		name: 'Story',
		component: Story,
	},
	{
		path: '/TwitterCompetition',
		name: 'TwitterCompetition',
		component: TwitterCompetition,
	},
	{
		path: '/fifatwittercompetition',
		name: 'FifaTwitterCompetition',
		component: FifaTwitterCompetition,
	},
	{
		path: '/PressRelease',
		name: 'PressRelease',
		component: PressRelease,
	},
	{
		path: '/ambassador',
		name: 'Ambassador',
		component: Ambassador,
	},
	{
		path: '/madden',
		name: 'Madden',
		beforeEnter() {
			window.location.href =
				'https://rallycry.gg/levelnext/competition/144';
		},
	},
	{
		path: '/madden23',
		name: 'Madden23',
		beforeEnter() {
			window.location.href =
				'https://rallycry.gg/levelnext/competition/534/info';
		},
	},
	{
		path: '/register',
		name: 'Register',
		beforeEnter() {
			window.location.href = 'https://rally.gg/rocketleague';
		},
	},
	{
		path: '/rocketleague',
		name: 'RocketLeague',
		beforeEnter() {
			window.location.href = 'https://rally.gg/rocketleague';
		},
	},
	{
		path: '/signup',
		name: 'SignUp',
		beforeEnter() {
			window.location.href = 'https://rally.gg/fifa';
		},
	},
	{
		path: '/fifa',
		name: 'Fifa',
		beforeEnter() {
			window.location.href = 'https://rally.gg/fifa';
		},
	},
	{
		path: '/recap/:game',
		props: true,
		name: 'RecapPage',
		component: Recap,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: '/',
	routes,
});

export default router;
