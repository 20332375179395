<template>
	<div class="c-champions-hero">
		<div class="c-champions-hero__image position-relative">
			<img
				src="@/assets/champions-series_banner_revised.jpeg"
				class="object-fit-cover position-absolute width-100 height-100 top-0 left-0 z-index-n1"
			/>
			<div
				class="c-champions-hero__conference z-index-1 position-absolute bottom-0 left-50 width-100 height-100"
			>
				<div
					class="c-champions-hero__conference__logo position-absolute display-flex align-items-center justify-content-center border padding-24"
				>
					<img
						src="@/assets/spring_showcase_logo.png"
						alt="LevelNext Logo"
					/>
				</div>
			</div>
		</div>
		<div
			class="c-champions-hero__details padding-x-24 margin-auto bp-1280:padding-t-64"
		>
			<h1
				class="font-size-30 line-height-125 position-relative padding-b-16 bp-540:font-size-36 bp-540:padding-b-32 bp-1024:font-size-48"
			>
				LevelNext Champions Series
			</h1>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ChampionsHero',
};
</script>

<style lang="scss" scoped>
.c-champions-hero {
	&__image {
		height: 556px;
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			left: 0;
			background-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1)
			);
		}
	}
	&__details {
		max-width: 1440px;
		h1 {
			max-width: 970px;
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
				position: absolute;
				left: 0;
				bottom: 0;
				transition: 0.25s;
			}
		}
		.register_button {
			background-color: $red;
			border-radius: 24px;
			position: relative;
			text-decoration: none;
			transition: 0.25s;
			height: 48px;
			width: 260px;
			color: $white;
			&:hover {
				background-color: darken($red, 5%);
				cursor: pointer;
			}
		}
	}
	&__logo {
		max-width: 300px;
	}
	&__conference {
		max-width: 1440px;
		left: 50%;
		transform: translateX(-50%);
		&__logo {
			width: 245px;
			height: 245px;
			left: 24px;
			bottom: 56px;
			border-color: $border-gray;
			background: $black;
			img {
				max-width: 180px;
			}
		}
	}
	@media (max-width: 1280px) {
		&__conference {
			&__logo {
				width: 120px;
				height: 120px;
				bottom: 48px;
				img {
					max-width: 60px;
				}
			}
		}
	}
	@media (max-width: 768px) {
		margin-top: 96px;
		&__image {
			height: 302px;
		}
	}
	@media (max-width: 540px) {
		&__details {
			.register_button {
				width: 100%;
			}
		}
		&__image {
			height: 150px;
		}
		&__logo {
			max-width: 180px;
		}
		&__conference {
			&__logo {
				bottom: 36px;
				width: 96px;
				height: 96px;
			}
		}
	}
}
</style>
