/* eslint-disable */

export const create_url_params_from_object = params_obj => {
	let param_string = '';

	Object.keys(params_obj).forEach((key, index) => {
		param_string += index === 0 ? '?' : '&';

		param_string += `${key}=${encodeURIComponent(params_obj[key])}`;
	});

	return param_string;
};

export const slugify = string => {
	const a =
		'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b =
		'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');
	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(p, c => b.charAt(a.indexOf(c)))
		.replace(/&/g, '-and-')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '');
};

export const clean_response_data = (data, options) => {
	if (options.type && options.type === 'stories') {
		data.forEach(story => {
			story.url = story.url.replace('.aspx', '');
		});
		return data;
	}
	return data;
};

export const get = (
	url = '',
	options = {},
	callback = data => {
		return data;
	},
) => {
	return new Promise((resolve, reject) => {
		const param_string = create_url_params_from_object(options);

		if (url.startsWith('/services/')) {
			url = process.env.VUE_APP_SERVICE_ROOT + url;
		}

		fetch(url + param_string)
			.then(response => response.json())
			.then(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				if (response.data) {
					resolve(
						callback(clean_response_data(response.data, options)),
					);
					return;
				}

				resolve(callback(clean_response_data(response, options)));
			})
			.catch(response => {
				if (response.error) {
					reject(response.error);
					return;
				}

				reject(response);
			});
	});
};

export const slugify_underscore = string => {
	const a =
		'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
	const b =
		'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
	const p = new RegExp(a.split('').join('|'), 'g');
	return string
		.toString()
		.toLowerCase()
		.replace(/\s+/g, '_')
		.replace(p, c => b.charAt(a.indexOf(c)))
		.replace(/&/g, 'and')
		.replace(/[^\w\_]+/g, '')
		.replace(/\_\_+/g, '_')
		.replace(/^_+/, '')
		.replace(/_+$/, '');
};

export const get_player_from_players_by_schoolname = (players, schoolname) => {
	return players.find(player => player.school === schoolname);
};

export const get_player_from_players_by_slug = (players, player_slug) => {
	return players.find(player => player.slug === player_slug);
};

export const value_by_stringed_key = (object, string_key_path) => {
	string_key_path = string_key_path.replace(/\[(\w+)\]/g, '.$1');
	string_key_path = string_key_path.replace(/^\./, '');
	var pieces = string_key_path.split('.');

	for (let i = 0, n = pieces.length; i < n; ++i) {
		var k = pieces[i];
		if (k in object) {
			object = object[k];
		} else {
			return;
		}
	}

	return object;
};

export const get_page_url = () => {
	return window.location.href;
}
