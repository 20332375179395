<template>
	<div class="p-teams">
		<div class="display-block bp-1024:display-flex">
			<header
				class="p-teams__header flex-basis-50 flex-item-1 display-flex flex-direction-column justify-content-center align-items-center padding-t-40 padding-b-64 padding-x-24 bp-1024:padding-t-0 bp-1024:padding-b-0"
			>
				<h2
					class="p-teams__header--title padding-b-32 position-relative margin-0 display-flex"
				>
					<img
						class="width-100"
						src="../assets/svg/Find_your_school_revised.svg"
						alt="Get Involved"
					/>
					<span class="hide accessible-hide">Find Your School</span>
				</h2>
				<div
					class="p-teams__header--subtitle font-size-18 font-weight-400 margin-b-36 line-height-125 bp-1024:margin-b-64 bp-1024:font-size-24 bp-1024:font-weight-700 bp-1280:font-size-30"
				>
					<div>Want to join in on the action?</div>
					<div>Search your school and register.</div>
				</div>
				<div class="p-teams__search display-flex">
					<input
						v-model="search"
						placeholder="Start Typing Your School Name"
						type="text"
						class="font-size-16 padding-x-24 flex-item-1 border-none"
						@keyup.enter="getTeams()"
					/>
					<button
						type="button"
						class="display-flex align-items-center padding-x-24"
						aria-label="Search Schools"
						@click="getTeams()"
					>
						<span class="font-size-16 font-weight-700 margin-r-6"
							>Search</span
						>
						<i class="sf-search-3 font-size-24"></i>
					</button>
				</div>
			</header>
			<div
				class="p-teams__content_list flex-basis-50 flex-item-1 bp-540:padding-24 display-flex flex-direction-column align-items-center justify-content-center bp-1024:padding-24 bp-1280:padding-72 bp-1440:padding-120"
			>
				<div
					class="header width-100"
					v-if="filteredList.length && !loading"
				>
					<span class="header_text">School Search Results</span>
				</div>
				<div
					v-if="filteredList.length && !loading"
					class="p-teams__content_list__wrapper bp-1024:overflow-y-auto width-100"
				>
					<div
						class="content_item border border-t-none border-x-none bp-540:border-x padding-x-24 padding-y-16 display-flex align-items-center"
						v-for="(team, index) in filteredList"
						:key="index"
					>
						<div
							v-if="team.profileImage"
							class="content_item__image margin-r-24"
						>
							<img :src="team.profileImage" />
						</div>
						<div
							class="content-item__name flex-item-1 font-size-14 bp-540:font-size-18 padding-r-24"
						>
							{{ team.name }}
						</div>
						<div
							class="content-item__register"
							v-if="team.registrationLink"
						>
							<a
								color="secondary"
								:href="
									createMainlineLink(team.registrationLink)
								"
								v-if="isActiveDate(team)"
								class="register_button display-flex align-items-center justify-content-center"
								>Register</a
							>
							<div
								color="secondary"
								:href="
									createMainlineLink(team.registrationLink)
								"
								v-else
								class="register_closed font-weight-400 display-flex align-items-center justify-content-center text-align-center font-size-14 line-height-125"
							>
								Registration Closed
							</div>
						</div>
					</div>
				</div>
				<div v-if="filteredList.length && !loading">
					<div
						class="p-teams__no-events__text line-height-175 text-align-center bp-768:margin-b-40 font-size-24 bp-1280:font-size-30 font-weight-700 line-height-125 margin-b-24 margin-t-24 bp-768:margin-t-32"
					>
						Can't Find Your School? Register at the link below
					</div>
					<div class="p-teams__no-events__register width-100">
						<a
							href="https://levelnext.mainline.gg/spring-showcase/signup/-/tournament/overview"
							target="_blank"
							class="display-flex align-items-center justify-content-center text-decoration-none width-100 margin-auto"
							>Register Now</a
						>
					</div>
				</div>
				<div
					v-if="!filteredList.length && hasSearched"
					class="p-teams__no-events display-flex flex-direction-column justify-content-center align-items-center text-align-center padding-b-120 bp-1024:padding-b-0 position-relative"
				>
					<div v-if="!loading">
						<div
							class="p-teams__no-events__image width-100 position-relative padding-b-40 margin-b-24 bp-1024:margin-b-32 padding-t-40 bp-1024:padding-t-0"
						>
							<img
								class="width-100"
								src="../assets/svg/Hmm_revised.svg"
								alt="Get Involved"
							/>
						</div>
						<div
							class="p-teams__no-events__text font-size-18 line-height-175 font-weight-400 margin-b-40 bp-1024:font-size-24 bp-1280:font-size-30 bp-1024:font-weight-700 bp-1024:line-height-125 bp-1024:margin-b-64"
						>
							Your school doesn’t seem to be registered yet.
							Register at the link below
						</div>

						<div class="p-teams__no-events__register width-100">
							<a
								href="https://levelnext.mainline.gg/spring-showcase/signup/-/tournament/overview"
								target="_blank"
								class="display-flex align-items-center justify-content-center text-decoration-none width-100 margin-auto"
								>Register Now</a
							>
						</div>
					</div>
					<div v-else class="loading-indicator">
						<div
							class="loading-indicator__dot loading-indicator__dot--small"
						></div>
						<div
							class="loading-indicator__dot loading-indicator__dot--large"
						></div>
						<div
							class="loading-indicator__dot loading-indicator__dot--small"
						></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '/src/helper.js';
import moment from 'moment';

export default {
	name: 'Teams',
	data: function() {
		return {
			search: '',
			loading: false,
			hasSearched: false,
			teams: [],
			now: new Date(),
		};
	},
	methods: {
		parseDate(date) {
			return Number(moment(date).format('yyyymmDD'));
		},
		getTeams() {
			this.teams = [];
			this.loading = true;
			get(
				`https://levelnext-api.sidearmsports.com/api/tournaments/registeredlinks?school_name=${this.search}`,
			).then(response => {
				this.teams = response;
				this.loading = false;
				this.hasSearched = true;
			});
		},
		createMainlineLink(link) {
			// /-/tournament/overview is the page mainline has requested for us to link out to with the routes
			return 'https://' + link + '/-/tournament/overview';
		},
		isActiveDate(team) {
			return moment().isBefore(moment(team.registrationEndDate));
		},
	},
	computed: {
		filteredList() {
			return this.teams.filter(school => {
				if (school && school.name) {
					return school.name
						.toLowerCase()
						.includes(this.search.toLowerCase());
				} else {
					return '';
				}
			});
		},
	},
	created() {},
};
</script>

<style scoped lang="scss">
.p-teams {
	min-height: 100vh;
	padding-top: 96px;
	@include nova(700);
	.row {
		padding: 0 24px;
		max-width: 1488px;
	}

	&__content_list {
		width: 100%;
		height: calc(100vh - 96px);
		background-image: url('../assets/news_bg_texture.jpg');
		background-size: cover;
		background-position: center;
		background-attachment: fixed;
		&__wrapper {
			border-bottom: 1px solid $border-gray;
		}
		.header {
			display: flex;
			flex-direction: row;
			padding: 12px 24px;
			background: $medium-gray;
		}
		.header_text {
			@include nova(700);
			font-size: 16px;
			line-height: 24px;
			font-weight: bold;
		}
		.content_item {
			background: $dark-gray;
			border-color: $border-gray;
			&:last-of-type {
				border-bottom: none !important;
			}
			.register_button {
				background-color: $red;
				border-radius: 24px;
				position: relative;
				text-decoration: none;
				transition: 0.25s;
				height: 48px;
				width: 108px;
				color: $white;
				&:hover {
					background-color: darken($red, 5%);
					cursor: pointer;
				}
			}
			.register_closed {
				background-color: $border-gray;
				border-radius: 24px;
				position: relative;
				text-decoration: none;
				transition: 0.25s;
				height: 48px;
				width: 108px;
				color: $muted-text;
			}
			&__image {
				padding: 12px;
				flex: 0 0 60px;
				max-width: 60px;
				max-height: 60px;
				background: $white;
				border: 1px solid $border-gray;
			}
			&__register {
				flex: 0 0 108px;
			}
		}
		.p-teams__no-events {
			&__image {
				img {
					max-width: 210px;
				}
				&:after {
					display: block;
					content: '';
					width: 60px;
					height: 4px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%) skewX(-45deg);
					background: $red;
				}
			}
			&__register {
				a {
					height: 60px;
					background-color: $red;
					color: $white;
					border-radius: 30px;
					transition: 0.25s;
					max-width: 480px;
					&:hover,
					&:focus {
						background-color: darken($red, 5%);
					}
				}
			}
		}
	}
	&__asterisk {
		margin-top: 24px;
		color: $white;
		font-size: 1rem;
		line-height: 1.25;
	}
	&__search {
		max-width: 480px;
		width: 100%;
		input {
			@include nova(700);
			height: 60px;
			border-radius: 30px 0 0 30px;
			background: $white;
			color: $muted-text;
		}
		button {
			cursor: pointer;
			background: $red;
			color: $white;
			font-size: 16px;
			line-height: 24px;
			border: none;
			transition: 0.25s;
			border-radius: 0 30px 30px 0;
			&:hover {
				transition: 0.25s;
				background: darken($red, 5%);
			}
			span {
				font-weight: 600;
			}
			i {
				font-size: 24px;
			}
		}
	}
	&__header {
		height: calc(100vh - 96px);
		background: $dark-gray;
		border-right: 1px solid $border-gray;
		img {
			max-width: 600px;
		}
		&--title {
			&:after {
				display: block;
				content: '';
				width: 60px;
				height: 4px;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%) skewX(-45deg);
				background: $red;
			}
		}
		&--subtitle {
			color: $white;
			text-align: center;
			margin: 24px 0 36px 0;
		}
	}
	&__content {
		width: 100%;
	}
	@media (max-width: 1023px) {
		&__header {
			height: auto;
			border-bottom: 1px solid $border-gray;
			border-right: none;
			img {
				max-width: 325px;
			}
		}
		&__content_list {
			height: auto;
			.p-teams__no-events {
				&__image {
					img {
						max-width: 180px;
					}
				}
				&__text {
					max-width: 327px;
				}
				&__register {
					a {
						height: 48px;
						border-radius: 24px;
						max-width: 320px;
					}
				}
			}
		}
	}
	::-webkit-scrollbar {
		width: 8px;
		border-left: 1px solid $border-gray;
	}
	::-webkit-scrollbar-track {
		background-color: $dark-gray;
		border-radius: 0;
	}
	::-webkit-scrollbar-thumb {
		background-color: $red;
		border-radius: 0;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: darken($red, 5%);
	}
}
</style>
