<template>
	<div
		class="c-stories-trending bg-color-black padding-b-64 padding-t-0 padding-x-24 bp-1024:padding-b-120 position-relative"
		v-if="stories.length"
	>
		<div class="c-stories-trending__inner">
			<h2
				class="font-size-30 font-weight-700 padding-b-12 position-relative margin-b-32 bp-768:margin-b-48"
			>
				Trending Stories
			</h2>
			<div
				class="c-stories-trending__item position-relative padding-b-24"
				v-for="(item, index) in stories"
				:key="index"
			>
				<div
					class="c-stories-trending__item__top display-flex align-items-center margin-b-12"
				>
					<span
						class="c-stories-trending__number font-size-18 font-weight-700 margin-r-12 display-block"
						>{{ '0' + (index + 1) }}</span
					>
					<span class="font-size-14 display-block">{{
						item.sport.title
					}}</span>
				</div>
				<div class="c-stories-trending__item__bottom display-flex">
					<a
						:href="stripCharacters(item.content_url)"
						class="c-stories-trending__title display-block font-weight-700 text-default-on-dark text-decoration-none font-size-21 transition padding-r-12"
					>
						{{ item.content_title }}
					</a>
					<div
						class="c-stories-trending__image overflow-hidden display-none bp-1024:display-block"
					>
						<div class="aspect-ratio-1-1 position-relative">
							<img
								class="object-fit-cover transition position-absolute width-100 height-100 top-0 left-0"
								:src="
									$root._data.imageBasePath +
										item.image_source
								"
								:alt="'Image for ' + item.content_title"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '/src/helper.js';
import moment from 'moment';

export default {
	name: 'StoriesList',
	props: {
		count: {
			type: [Number, String],
			default: 4,
		},
	},
	data: () => ({
		stories: [],
		storyStart: 0,
	}),
	methods: {
		parseDate(date) {
			return moment(date).format('MMMM DD, YYYY');
		},
		getLatestStories() {
			const latest_request_options = {
				type: 'stories',
				count: this.count,
				start: this.storyStart,
				featured: true,
			};

			get(
				'/services/adaptive_components.ashx',
				latest_request_options,
			).then(response => {
				if (response.length) {
					this.stories = [...this.stories, ...response];
				} else {
					document.getElementById('load-more').remove();
					document
						.getElementById('loaded-message')
						.classList.add('show');
					return;
				}
			});
		},
		load_more() {
			this.storyStart += 4;
			this.getLatestStories();
		},
		stripCharacters(url) {
			url = url
				.replace('https://levelnext.sidearmsports.com', '')
				.replace('.aspx', '');
			return url;
		},
	},
	mounted() {
		this.getLatestStories();
	},
};
</script>
<style lang="scss">
.c-stories-trending {
	color: #fff;
	background-color: #000;
	&__inner {
		max-width: 1464px;
		margin: auto;
	}
	h2 {
		&:after {
			content: '';
			width: 60px;
			height: 4px;
			background-color: $red;
			transform: skewX(-45deg);
			position: absolute;
			left: 0;
			bottom: 0;
		}
	}
	&__item {
		&:not(:last-of-type) {
			margin-bottom: 24px;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				display: block;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(
					to right,
					rgba(204, 0, 0, 1),
					rgba(204, 0, 0, 0)
				);
			}
		}
	}
	&__number {
		color: $anchor;
	}
	&__image {
		flex: 0 0 72px;
		min-width: 72px;
		padding-top: 8px;
	}
	&__title {
		&:hover,
		&:focus {
			color: $red;
		}
	}
	@media (max-width: 1023px) {
		&:after {
			content: '';
			display: block;
			height: 4px;
			width: 100%;
			background: linear-gradient(
				90deg,
				#000000 0%,
				#cc0000 51.56%,
				#000000 100%
			);
			position: absolute;
			bottom: -2px;
			left: 0;
		}
	}
}
</style>
