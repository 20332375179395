var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tournament-swiper-container",class:_vm.background},[_c('div',{staticClass:"tournament-swiper"},[_c('Swiper',{ref:"mySwiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.tournaments),function(tournament,index){return _c('SwiperSlide',{key:index,staticClass:"display-flex flex-direction-column align-items-center"},[_c('div',{staticClass:"swiper-content"},[_c('div',{staticClass:"swiper-image"},[_c('img',{attrs:{"src":require(("@/assets/champion-series/" + (tournament.imgName))),"alt":tournament.imgAlt}})]),_c('div',{staticClass:"swiper-text"},[_c('span',{staticClass:"display-flex align-items-center"},[_c('h4',{staticClass:"swiper-header"},[_vm._v(" "+_vm._s(tournament.header)+" ")]),(_vm.tournaments.length > 1)?_c('div',{staticClass:"pagination display-flex justify-content-space-between"},_vm._l((_vm.tournaments),function(_,paginationIndex){return _c('span',{key:paginationIndex,staticClass:"pagination-index display-flex text-muted-on-dark",class:{
										paginationSelected:
											paginationIndex === index,
									},on:{"click":function($event){return _vm.slideTo(paginationIndex)}}},[_vm._v(" 0"+_vm._s(paginationIndex)+" ")])}),0):_vm._e()]),_c('h3',{staticClass:"swiper-title"},[_vm._v(_vm._s(tournament.title))]),_c('div',{staticClass:"swiper-small-stuff"},[_c('div',{staticClass:"swiper-misc-container display-flex"},_vm._l((tournament.misc),function(miscItem,index){return _c('span',{key:index,staticClass:"swiper-misc"},[_vm._v(" "+_vm._s(miscItem)+" ")])}),0),_c('a',{staticClass:"swiper-button reset mb-2 sa-block-parallax__link display-flex align-items-center text-align-center font-size-14 font-weight-700",class:{
									greenButton:
										tournament.buttonText ===
										'Register Now',
								},attrs:{"href":tournament.buttonHref,"color":"secondary"}},[_vm._v(" "+_vm._s(tournament.buttonText)+" ")])])])])])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }