<template>
	<div v-if="videosList.length">
		<div
			class="c-video-header display-flex justify-content-space-between align-items-center padding-x-24 margin-x-auto margin-b-12 margin-t-32 flex-wrap-wrap bp-540:flex-wrap-no-wrap bp-540:margin-b-32 bp-540:margin-t-64 bp-1024:margin-b-64 bp-1024:margin-t-104"
		>
			<h2
				class="font-size-30 font-weight-700 padding-b-12 position-relative margin-y-0"
			>
				Videos
			</h2>
			<a
				class="c-video-header__link display-flex align-items-center justify-content-center padding-x-32 text-default-on-dark text-decoration-none flex-basis-100 margin-t-24 bp-540:margin-t-0 bp-540:flex-basis-auto"
				href="https://www.twitch.tv/levelnextgg"
				><span class="margin-r-6 font-weight-700">More on Twitch</span
				><span class="font-size-24 sf-twitch"></span
			></a>
		</div>
		<div class="c-video-content position-relative">
			<div v-if="!loading">
				<div
					class="c-video-slider"
					:class="isMobile ? 'display-none' : 'display-block'"
				>
					<div
						class="c-video-slider__inner position-relative margin-x-auto margin-b-0 margin-t-32 bp-1024:margin-t-64 overflow-hidden"
						v-if="videosList.length"
					>
						<vue-tiny-slider
							ref="carousel"
							class="c-video-slider__slider display-flex"
							v-bind="tinySlideOptions"
						>
							<div
								class="c-video-slider__item padding-x-12"
								v-for="(item, index) in videosList.slice(
									0,
									videoLimitDesktop,
								)"
								:key="index"
							>
								<div
									class="c-video-slider__embed position-relative aspect-ratio-16-9"
								>
									<iframe
										:src="
											'https://www.youtube.com/embed/' +
												item.id
										"
										class="position-absolute height-100 width-100 left-0 top-0 border-none"
									></iframe>
								</div>
							</div>
						</vue-tiny-slider>
					</div>
				</div>
				<div
					class="c-video-list"
					:class="isMobile ? 'display-block' : 'display-none'"
				>
					<div
						class="c-video-list__inner display-flex flex-wrap-wrap padding-x-12"
					>
						<div
							class="c-video-list__item col-12-of-12 bp-768:col-6-of-12 padding-12"
							v-for="(item, index) in videosList.slice(
								0,
								videoLimitMobile,
							)"
							:key="index"
						>
							<div
								class="c-video-slider__embed position-relative aspect-ratio-16-9"
							>
								<iframe
									:src="
										'https://www.youtube.com/embed/' +
											item.id
									"
									class="position-absolute height-100 width-100 left-0 top-0 border-none"
								></iframe>
							</div>
							<div
								class="c-video-list__title margin-y-12 font-size-21 font-weight-700"
							>
								{{ item.title }}
							</div>
						</div>
					</div>
				</div>
				<button
					v-if="isMobile && videosList.length > videoLimitMobile"
					@click="loadMoreVideos()"
					class="c-video-load-more font-weight-700 font-size-16 text-default-on-dark cursor-pointer display-flex align-items-center justify-content-center border-none transition"
				>
					Load More
				</button>
			</div>
			<div v-else class="loading-indicator">
				<div
					class="loading-indicator__dot loading-indicator__dot--small"
				></div>
				<div
					class="loading-indicator__dot loading-indicator__dot--large"
				></div>
				<div
					class="loading-indicator__dot loading-indicator__dot--small"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from '../../helper';
import VueTinySlider from 'vue-tiny-slider';
import 'tiny-slider/src/tiny-slider';

export default {
	name: 'VideoSlider',
	components: {
		VueTinySlider,
	},
	data: () => ({
		videos: [],
		loading: false,
		tinySlideOptions: {
			items: 2,
			slideBy: 2,
			nav: false,
			controls: true,
			controlsText: [
				'<span class="sf-arrow-left-minimal" aria-label="Previous Video Slide"></span>',
				'<span class="sf-arrow-right-minimal" aria-label="Advance Video Slideshow"></span>',
			],
			gutter: 0,
			mouseDrag: true,
			controlsPosition: 'bottom',
			autoplay: false,
			autoheight: true,
			responsive: {
				0: {
					items: 1,
				},
				540: {
					items: 2,
				},
				768: {
					items: 2,
				},
				1024: {
					items: 2,
				},
			},
		},
		isMobile: false,
		videoLimitDesktop: 6,
		videoLimitMobile: 2,
	}),
	computed: {
		videosList() {
			return this.videos;
		},
	},
	methods: {
		getVideos() {
			this.loading = true;
			get('/services/adaptive_components.ashx?type=youtube&count=20').then(videos => {
				this.videos = videos;
				setTimeout(() => (this.loading = false), 3000);
			});
		},
		togglePause() {
			this.$refs.carousel.toggleAutoPlay();
			this.isPlaying = !this.isPlaying;
		},
		onResize() {
			this.isMobile = window.innerWidth < 1141;
		},
		loadMoreVideos() {
			this.videoLimitMobile += 2;
		},
	},
	created() {
		this.getVideos();
	},
	mounted() {
		this.onResize();
		window.addEventListener('resize', this.onResize, { passive: true });
	},
};
</script>

<style lang="scss">
.c-video {
	&-header {
		max-width: 1512px;
		h2 {
			&:after {
				content: '';
				width: 60px;
				height: 4px;
				background-color: $red;
				transform: skewX(-45deg);
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		&__link {
			border: 1px solid $red;
			background-color: $black;
			height: 60px;
			transition: background-color 0.25s;
			&:hover,
			&:focus {
				background-color: $red;
			}
		}
	}
	&-content {
		min-height: 300px;
	}
	&-load-more {
		height: 48px;
		width: calc(100% - 48px);
		margin: 12px 24px 0;
		background-color: $red;
		&:hover,
		&:focus {
			background-color: darken($red, 5%);
		}
	}
	&-slider {
		&__inner {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 20%;
				height: 100%;
				background: linear-gradient(
					90deg,
					rgba(0, 0, 0, 0.792953) 0%,
					#000000 0.01%,
					rgba(0, 0, 0, 0.75) 70.31%,
					rgba(0, 0, 0, 0) 100%
				);
				z-index: 2;
			}
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 20%;
				height: 100%;
				background: linear-gradient(
					-90deg,
					rgba(0, 0, 0, 0.792953) 0%,
					#000000 0.01%,
					rgba(0, 0, 0, 0.75) 70.31%,
					rgba(0, 0, 0, 0) 100%
				);
				z-index: 2;
			}
			.c-video-slider__item {
				&__inner {
					&:after {
						content: '';
						position: absolute;
						z-index: -1;
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-image: linear-gradient(
							to right,
							rgba(0, 0, 0, 0.8),
							rgba(0, 0, 0, 0)
						);
					}
					img {
						z-index: -1;
					}
				}
			}
			.tns {
				&-controls {
					position: absolute;
					top: 50%;
					height: 42px;
					transform: translateY(-50%);
					width: 100%;
					z-index: 3;
					pointer-events: none;
					button {
						pointer-events: all;
						position: absolute;
						cursor: pointer;
						background-color: transparent !important;
						width: 42px;
						height: 42px;
						text-align: center;
						display: flex;
						align-items: center;
						justify-content: center;
						top: 0;
						margin-right: 0 !important;
						margin-bottom: 0 !important;
						span {
							font-size: 60px;
							text-shadow: 1px 1px 6px rgba(0, 0, 0, 1);
						}
						&:first-of-type {
							left: -100px;
						}
						&:last-of-type {
							right: -100px;
						}
					}
				}
				&-visually-hidden {
					display: none;
				}
				&-outer {
					position: relative;
					text-align: right;
					max-width: 60%;
					margin: auto;
					button {
						margin-right: 12px;
						margin-bottom: 12px;
						border: none;
						background-color: $black;
						color: $white;
						transition: color 0.25s;
						font-size: 18px;
						&:hover,
						&:focus {
							color: $anchor;
						}
					}
				}
			}
			@media (max-width: 1140px) {
				width: calc(100% - 24px);
				margin-left: 12px;
				&:before,
				&:after {
					display: none;
				}
				.tns {
					&-outer {
						max-width: 100%;
					}
				}
			}
			@media (max-width: 1023px) {
				//margin-left:12px;width:calc(100% - 12px);
				.tns {
					//&-inner{padding-right:60px;}
				}
			}
			@media (max-width: 639px) {
				.tns {
					//&-inner{padding-right:36px;}
				}
			}
		}
		&__embed {
			&:before,
			&:after {
				content: '';
				width: 0;
				left: 50%;
				transform: translateX(-50%);
				height: 1px;
				background: linear-gradient(
					90deg,
					#000000 0%,
					#cc0000 51.56%,
					#000000 100%
				);
				position: absolute;
				z-index: 2;
				opacity: 0;
				transition: width 0.25s, opacity 0.25s;
			}
			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
			&:hover,
			&:focus {
				&:before,
				&:after {
					width: 100%;
					opacity: 1;
				}
			}
		}
	}
}
</style>
