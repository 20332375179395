<template>
	<div>
		<story-content
			:year="year"
			:month="month"
			:day="day"
			:filename="filename"
		/>
	</div>
</template>

<script>
import StoryContent from '../components/Stories/StoryContent.vue';

export default {
	name: 'Story',
	props: {
		year: {
			type: [Number, String],
			default: null,
		},
		month: {
			type: [Number, String],
			default: null,
		},
		day: {
			type: [Number, String],
			default: null,
		},
		filename: {
			type: String,
			default: null,
		},
	},
	components: {
		StoryContent,
	},
};
</script>

<style lang="scss"></style>
