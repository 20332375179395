var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.videosList.length)?_c('div',[_vm._m(0),_c('div',{staticClass:"c-video-content position-relative"},[(!_vm.loading)?_c('div',[_c('div',{staticClass:"c-video-slider",class:_vm.isMobile ? 'display-none' : 'display-block'},[(_vm.videosList.length)?_c('div',{staticClass:"c-video-slider__inner position-relative margin-x-auto margin-b-0 margin-t-32 bp-1024:margin-t-64 overflow-hidden"},[_c('vue-tiny-slider',_vm._b({ref:"carousel",staticClass:"c-video-slider__slider display-flex"},'vue-tiny-slider',_vm.tinySlideOptions,false),_vm._l((_vm.videosList.slice(
								0,
								_vm.videoLimitDesktop
							)),function(item,index){return _c('div',{key:index,staticClass:"c-video-slider__item padding-x-12"},[_c('div',{staticClass:"c-video-slider__embed position-relative aspect-ratio-16-9"},[_c('iframe',{staticClass:"position-absolute height-100 width-100 left-0 top-0 border-none",attrs:{"src":'https://www.youtube.com/embed/' +
											item.id}})])])}),0)],1):_vm._e()]),_c('div',{staticClass:"c-video-list",class:_vm.isMobile ? 'display-block' : 'display-none'},[_c('div',{staticClass:"c-video-list__inner display-flex flex-wrap-wrap padding-x-12"},_vm._l((_vm.videosList.slice(
							0,
							_vm.videoLimitMobile
						)),function(item,index){return _c('div',{key:index,staticClass:"c-video-list__item col-12-of-12 bp-768:col-6-of-12 padding-12"},[_c('div',{staticClass:"c-video-slider__embed position-relative aspect-ratio-16-9"},[_c('iframe',{staticClass:"position-absolute height-100 width-100 left-0 top-0 border-none",attrs:{"src":'https://www.youtube.com/embed/' +
										item.id}})]),_c('div',{staticClass:"c-video-list__title margin-y-12 font-size-21 font-weight-700"},[_vm._v(" "+_vm._s(item.title)+" ")])])}),0)]),(_vm.isMobile && _vm.videosList.length > _vm.videoLimitMobile)?_c('button',{staticClass:"c-video-load-more font-weight-700 font-size-16 text-default-on-dark cursor-pointer display-flex align-items-center justify-content-center border-none transition",on:{"click":function($event){return _vm.loadMoreVideos()}}},[_vm._v(" Load More ")]):_vm._e()]):_c('div',{staticClass:"loading-indicator"},[_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--small"}),_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--large"}),_c('div',{staticClass:"loading-indicator__dot loading-indicator__dot--small"})])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-video-header display-flex justify-content-space-between align-items-center padding-x-24 margin-x-auto margin-b-12 margin-t-32 flex-wrap-wrap bp-540:flex-wrap-no-wrap bp-540:margin-b-32 bp-540:margin-t-64 bp-1024:margin-b-64 bp-1024:margin-t-104"},[_c('h2',{staticClass:"font-size-30 font-weight-700 padding-b-12 position-relative margin-y-0"},[_vm._v(" Videos ")]),_c('a',{staticClass:"c-video-header__link display-flex align-items-center justify-content-center padding-x-32 text-default-on-dark text-decoration-none flex-basis-100 margin-t-24 bp-540:margin-t-0 bp-540:flex-basis-auto",attrs:{"href":"https://www.twitch.tv/levelnextgg"}},[_c('span',{staticClass:"margin-r-6 font-weight-700"},[_vm._v("More on Twitch")]),_c('span',{staticClass:"font-size-24 sf-twitch"})])])}]

export { render, staticRenderFns }