<template>
	<div class="p-tournament">
		<div v-if="!loading">
			<TournamentHero v-if="details && details.url" :details="details" />
			<Participants :guid="guid" />
			<TournamentInfo
				v-if="overview.description"
				:overview="overview"
				:details="details"
			/>
			<div
				class="row"
				v-if="details.phases && details.phases[0].groups.length"
			>
				<h2
					v-if="details.phases && details.phases[0].groups.length"
					class="font-size-30 bp-1024:font-size-48 margin-b-24 flex-basis-100 padding-x-24"
				>
					Schedule
				</h2>
				<div class="row width-100" v-if="!this.isMobile">
					<div class="s-tournament-schedule padding-x-24 col-8-of-12">
						<TournamentSchedule
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].rounds.length
							"
							:details="details"
						/>
						<div
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].rounds.length <
										1
							"
							class="s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"
						>
							<p>Matchups to be determined after registration</p>
						</div>
					</div>
					<div
						class="s-tournament-standings padding-x-24 col-4-of-12"
					>
						<TournamentStandings
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].standings.length
							"
							:details="details"
						/>
						<div
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].standings
										.length < 1
							"
							class="s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"
						>
							<p>Rankings to be determined after gameplay</p>
						</div>
					</div>
				</div>
				<div class="width-100" v-else>
					<div
						class="s-tournament__buttons padding-x-24 margin-b-24 display-flex"
					>
						<button
							class="display-flex align-items-center font-size-14 font-weight-700 flex-basis-50 justify-content-center text-default-on-dark border-none transition margin-r-12"
							@click="toggleSchedule()"
							v-bind:class="[this.showSchedule ? 'active' : '']"
						>
							Matchups
						</button>
						<button
							class="display-flex align-items-center font-size-14 font-weight-700 flex-basis-50 justify-content-center text-default-on-dark border-none transition margin-l-12"
							@click="toggleSchedule()"
							v-bind:class="[this.showSchedule ? '' : 'active']"
						>
							Standings
						</button>
					</div>
					<div
						v-if="this.showSchedule"
						class="s-tournament-mobile-schedule width-100 bp-540:padding-x-24"
					>
						<TournamentSchedule
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].rounds.length
							"
							:details="details"
						/>
						<div
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].rounds.length <
										1
							"
							class="s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"
						>
							<p>Matchups to be determined after registration</p>
						</div>
					</div>
					<div
						v-if="!this.showSchedule"
						class="s-tournament-mobile-standings width-100 padding-x-24"
					>
						<TournamentStandings
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].standings.length
							"
							:details="details"
						/>
						<div
							v-if="
								details.phases &&
									details.phases[0].groups.length &&
									details.phases[0].groups[0].standings
										.length < 1
							"
							class="s-tournament__nodata text-align-center padding-y-48 padding-x-48 text-muted-on-dark"
						>
							<p>Rankings to be determined after gameplay</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="loading-indicator">
			<div
				class="loading-indicator__dot loading-indicator__dot--small"
			></div>
			<div
				class="loading-indicator__dot loading-indicator__dot--large"
			></div>
			<div
				class="loading-indicator__dot loading-indicator__dot--small"
			></div>
		</div>
	</div>
</template>

<script>
import { get } from '/src/helper.js';
import TournamentHero from '../components/Tournament/TournamentHero';
import TournamentInfo from '../components/Tournament/TournamentInfo';
import TournamentSchedule from '../components/Tournament/TournamentSchedule';
import TournamentStandings from '../components/Tournament/TournamentStandings';
import Participants from '../components/Tournament/Participants';
export default {
	name: 'Tournament',
	props: {
		guid: {
			required: true,
			type: String,
		},
	},
	components: {
		TournamentHero,
		TournamentInfo,
		TournamentSchedule,
		TournamentStandings,
		Participants,
	},
	data() {
		return {
			details: {
				url: '',
			},
			overview: {
				description: '',
				format: {
					content: '',
				},
				fullRules: {
					content: '',
				},
				name: '',
				prizes: {
					content: '',
				},
				rules: {
					content: '',
				},
				schedule: {
					content: '',
				},
			},
			isMobile: false,
			showSchedule: true,
			loading: true,
		};
	},
	methods: {
		async getTournamentDetails() {
			await get(
				`https://levelnext-api.sidearmsports.com/api/tournaments/detail/${this.guid}`,
			).then(response => {
				Object.assign(this.details, response);
				this.loading = false;
			});
		},
		getTournamentOverview() {
			get(
				`/services/levelnext.ashx/tournament/overview?id=${this.guid}`,
			).then(response => {
				Object.assign(this.overview, response.data);
			});
		},
		onResize() {
			this.isMobile = window.innerWidth < 1024;
		},
		toggleSchedule() {
			if (this.showSchedule) {
				this.showSchedule = false;
			} else {
				this.showSchedule = true;
			}
		},
	},
	mounted() {
		this.getTournamentDetails();
		this.getTournamentOverview();
		this.onResize();
		window.addEventListener('resize', this.onResize, { passive: true });
	},
};
</script>

<style lang="scss">
.p-tournament {
	min-height: calc(100vh - 96px);
}
.s-tournament {
	&__buttons {
		button {
			height: 36px;
			border-radius: 18px;
			background-color: $button-gray;
			&:hover,
			&:focus,
			&.active {
				background-color: $red;
			}
		}
	}
}
</style>
