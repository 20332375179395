<template>
	<div
		v-if="overview"
		class="c-tournament-info row padding-t-24 padding-b-24 padding-x-24 bp-768:padding-y-64 bp-1024:padding-y-88"
	>
		<div class="c-tournament-info__inner width-100">
			<h2
				class="font-size-30 bp-1024:font-size-48 font-weight-700 margin-t-0 margin-b-36 line-height-125"
			>
				Tournament Info
			</h2>
			<div class="margin-b-40 font-size-18">
				{{ overview.description }}
			</div>
			<div
				class="c-tournament-info__tabs width-100 display-none bp-768:display-inline-block"
			>
				<Tabs>
					<Tab name="Prizes" :selected="true" v-if="overview.prizes">
						<div
							class="padding-t-40"
							v-html="formatTabContent(overview.prizes.content)"
						></div>
					</Tab>
					<Tab name="Format" v-if="overview.format">
						<div
							class="padding-t-40"
							v-html="formatTabContent(overview.format.content)"
						></div>
					</Tab>
					<Tab name="Rules" v-if="overview.rules">
						<div
							class="padding-t-40"
							v-html="formatTabContent(overview.rules.content)"
						></div>
					</Tab>
					<Tab name="Schedule" v-if="overview.schedule">
						<div
							class="padding-t-40"
							v-html="formatTabContent(overview.schedule.content)"
						></div>
					</Tab>
				</Tabs>
			</div>
			<div
				class="c-tournament-info__accordions display-block bp-768:display-none"
			>
				<Accordion :content="accordion" :multiple="true" />
			</div>
			<div
				class="c-tournament-info__bracket padding-t-12 bp-768:padding-t-32 bp-1024:padding-t-64"
				v-if="this.details.url"
			>
				<a
					class="display-flex align-items-center justify-content-center font-weight-700 text-default-on-dark text-decoration-none transition"
					target="_blank"
					:href="
						'https://' + this.details.url + '/-/tournament/matches'
					"
				>
					View Tournament Bracket
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import Tabs from '@/components/Tabs/InfoTabs.vue';
import Tab from '@/components/Tabs/InfoTab.vue';
import Accordion from '@/components/Accordion/Accordion';
import markd from 'markdown-it';

export default {
	name: 'TournamentInfo',
	components: {
		Tabs,
		Tab,
		Accordion,
	},
	props: {
		overview: {
			type: Object,
			required: true,
		},
		details: {
			type: Object,
			required: true,
		},
	},
	data: function() {
		return {
			accordion: [
				{
					id: 1,
					active: false,
					title: 'Prizes',
					details: this.formatTabContent(
						this.overview.prizes.content,
					),
				},
				{
					id: 2,
					active: false,
					title: 'Format',
					details: this.formatTabContent(
						this.overview.format.content,
					),
				},
				{
					id: 3,
					active: false,
					title: 'Rules',
					details: this.formatTabContent(this.overview.rules.content),
				},
				{
					id: 4,
					active: false,
					title: 'Schedule',
					details: this.formatTabContent(
						this.overview.schedule.content,
					),
				},
			],
		};
	},
	methods: {
		formatTabContent(text) {
			var md = new markd();
			var htmlContext = md.render(text);

			htmlContext = htmlContext.replaceAll('&lt;br&gt;', '<br/>');

			return htmlContext;
		},
	},
};
</script>

<style lang="scss">
.c-tournament-info {
	margin: auto;
	.c-tournament-info__bullet {
		color: $red;
	}
	h3 {
		font-size: 24px;
		font-weight: 700;
		margin-bottom: 24px;
	}
	h4 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 24px;
	}
	h2 {
		&:after {
			display: none;
		}
	}
	&__tabs {
		.tabs-details {
			ul {
				list-style: none;
				li::before {
					content: '\2022';
					color: $red;
					display: inline-block;
					margin-right: 12px;
				}
			}
			a {
				color: $anchor;
				text-decoration: none;
			}
		}
	}
	&__accordions {
		.c-accordion {
			&__item {
				&.is-active {
					.c-accordion {
						&__item {
							&-trigger {
								&:after {
									width: 60px;
									opacity: 1;
								}
								&-icon {
									i {
										&:before {
											content: '\e066';
										}
									}
								}
							}
						}
					}
					&:last-of-type {
						.c-accordion {
							&__item {
								&-trigger {
									border-bottom: none;
								}
							}
						}
					}
				}
				&:last-of-type {
					.c-accordion {
						&__item {
							&-trigger {
								border-bottom: 1px solid $border-gray;
							}
							&-details {
								border-bottom: 1px solid $border-gray;
							}
						}
					}
				}
				&-trigger {
					font-size: 1.5rem;
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
					height: 84px;
					border: none;
					border-top: 1px solid $border-gray;
					background-color: transparent;
					color: $white;
					position: relative;
					padding: 0;
					h4 {
						font-size: 1.5rem;
						font-weight: 700;
						margin: 0;
					}
					&:after {
						content: '';
						width: 0;
						opacity: 0;
						height: 4px;
						background-color: $red;
						transform: skewX(-45deg);
						position: absolute;
						left: 0;
						bottom: 0;
						transition: 0.25s;
					}
				}
				&-details {
					margin: 0;
					padding: 12px 0 48px;
				}
			}
		}
	}
	&__bracket {
		a {
			height: 48px;
			background-color: $red;
			&:after {
				content: '\e121';
				margin-left: 8px;
				font-family: 'sidearm_font_v2';
				font-size: 20px;
				display: block;
				font-weight: 400 !important;
			}
			&:hover,
			&:focus {
				background-color: darken($red, 5%);
			}
		}
	}
}
</style>
